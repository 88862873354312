import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { Router } from '@angular/router';
import { DocumentRenderService } from '../document-render/document-render.service';
import {GlobalService} from 'src/app/services/global.service';

@Component({
  selector: 'app-document-related-table',
  templateUrl: './document-related-table.component.html',
  styleUrls: ['./document-related-table.component.scss']
})
export class DocumentRelatedTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() validationsExecutionsIds: number[];
  @Input() documentId: number;
  @Input() documentPages: number;

  public columns: any[];
  public loading: boolean;
  public settingTable: any;
  public rows: any;
  private pagination: PaginationModel;
  public backgroundLoading: boolean;
  public showTable: boolean = false;
  public file: File;

  constructor(
    private apiService: ApiService,
    private paginationService: PaginationService,
    private documentRenderService: DocumentRenderService,
    private link: LinkService,
    private router: Router,
    private global: GlobalService
  ) {
    this.backgroundLoading = false;
    this.getColumns();
    this.getSettingTable();
  }

  ngOnInit() {
    this.pagination =
      this.paginationService.getDefaultPagination('relateddocuments');
  }

  ngOnChanges() {
    this.showTable = false;
    this.getRelatedDocumentsFromDB();
  }

  ngOnDestroy() {
    // If the document on the render service is not the main document
    // this calls set the image from the main document
    if (this.documentId !== this.documentRenderService.getDocumentId()) {
      this.cleanRenderService();
      const url = this.router.parseUrl(this.router.url);
      const documentSlug = url.root.children.primary.segments.pop().path;
      if (this.documentId === +this.link.getIdFromSlug(documentSlug)) {
        this.documentRenderService.setDocumentId(this.documentId);
        this.documentRenderService.setPages(this.documentPages);
        this.documentRenderService.updateStartDocumentThumbnailsProvider();
        this.documentRenderService.getDocumentImagesFromDB();
      }
    }
  }

  /**
   * Get related documents from database
   */
  private getRelatedDocumentsFromDB() {
    this.loading = true;
    const params = {
      ...this.pagination,
      validationsexecutionsids: this.validationsExecutionsIds.join(','),
      page_size: 5,
      documentid: this.documentId
    };
    this.apiService.get('relateddocuments/', params, '').subscribe(
      (data: {
        total_elements: number;
        page: number;
        page_size: number;
        num_pages: number;
        order_by: string;
        desc: number;
        data: any[];
      }) => {
        this.rows = this.serializeData(data);
        this.paginationService.setPage('relateddocuments', data);
        this.pagination = this.paginationService.setPagination(
          'relateddocuments',
          {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          }
        );
        this.loading = false;
        this.showTable = this.rows.total_elements > 0;
      },
      () => {
        this.loading = false;
      }
    );
  }

  /**
   * Function to show properly the data in the table
   * In this case to allow the navigation to the documents.
   * The link service expect document.analysis.analysisid
   * @param data
   */
  public serializeData(data) {
    const elements = { ...data };
    elements.data.map(doc => {
      doc['analysis'] = {};
      doc['analysis']['analysisid'] = doc.analysisid;
      this.global.getDocumentDetails(doc.documentid).then(d => {
        doc['numpages'] = d.numpages
      });
    });
    return elements;
  }

  /**
   * Go to the document with the document information pass by params
   */
  public goToDocument(document, flag: boolean = false) {
    this.link.goToDocument(document, flag);
  }

  public goToDocumentNewTab(document) {
    this.link.goToDocument(document, true);
  }

  /**
   * Clean the render image service to show another image
   */
  public cleanRenderService() {
    this.documentRenderService.emptyImages();
    this.documentRenderService.emptyPages();
  }

  /**
   * Render the image on selected
   * document on the table. Render the image
   * of main document on cancel
   */
  public renderImage(document) {
    this.cleanRenderService();
    if (this.documentRenderService.getDocumentId() !== document.documentid) {
      this.documentRenderService.setDocumentId(document.documentid);
      this.documentRenderService.setPages(document.numpages); 
    } else {
      this.documentRenderService.setDocumentId(this.documentId);
      this.documentRenderService.setPages(this.documentPages);
    }
    this.documentRenderService.updateStartDocumentThumbnailsProvider();
    
    this.documentRenderService.setIsMainDocumentRendered(
      Boolean(this.documentRenderService.getIsMainDocumentRendered()) ? false : true)

    this.documentRenderService.getDocumentImagesFromDB();
  }

  /**
   * Return loaded columns with the information of
   * each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectDocument.documentName',
        name: 'documentdisplayname',
        type: 'link',
        clickLink: this.goToDocument.bind(this)
      },
      {
        header: 'validation.documentValidation.type',
        name: 'datamodel',
        second: 'datamodeldisplayname'
      },
      {
        header: 'projectDocument.status',
        name: 'status',
        title: 'projectDocument.status',
        type: 'one-span-status'
      },
      {
        type: 'custom',
        header: 'responsiveTable.relatedDocument',
        title: 'responsiveTable.relatedDocument',
        class: 'td-icon',
        namefunc: rowData => {
          return rowData.documentid ===
            this.documentRenderService.getDocumentId()
            ? '<i class="fa fa-times"></i>'
            : '<i class="fa fa-file-image-o"></i>';
        },
        event: rowData => {
          this.renderImage(rowData);
        }
      },
      {
        type: 'link-fa-icon',
        header: ' ',
        link: 'external',
        title: 'responsiveTable.newTab',
        class: 'td-icon',
        clickLink: this.goToDocumentNewTab.bind(this),
        namefunc: rowData => {
          return '<i class="fa fa-external-link"></i>';
        }
      }
    ];
  }

  /**
   * Return loaded SettingTable with the general settings for the
   * component responsive table
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'userid',
      getDataFromDB: this.getRelatedDocumentsFromDB.bind(this),
      responsiveTitle: {
        label: 'documentdisplayname'
      },
      paginationClass: 'relative-pag'
    };
  }

  /**
   * Handle loading data of the table from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Function that add an evidence for this document
   */
  public onAddEvidence(event: any, input: any) {
    this.file = <File>event.target.files[0];
  }
}
