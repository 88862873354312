import { ChangeDetectorRef } from '@angular/core';
import {
  Component,
  OnInit,
  Output,
  Input,
  EventEmitter,
  OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { LinkService } from 'src/app/services/link.service';
import { DocumentRenderService } from './../document-render/document-render.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnChanges {
  @Input() elements: any = [];
  @Output() timelineContent = new EventEmitter();
  @Input() overview: boolean;
  @Input() documentId: number;
  @Input() documentPages: number;
  @Input() documentDetails: any;
  @Input() isMainDocumentRendered: boolean;


  public activeChild: any = {};
  public containerClass: string = '';
  public _validationTypes: any;
  public _validationStatus: any;
  public catalogValidations: any = [];

  public items: Array<any> = [{}, {}, {}, {}];
  public itemsNames: Array<string>;

  constructor(
    private documentRenderService: DocumentRenderService,
    private global: GlobalService,
    private router: Router,
    private link: LinkService,
    private cdref: ChangeDetectorRef
  ) {
    this._validationTypes = this.global.getValidationTypesConst();
    this._validationStatus = this.global.getValidationStatusConst();

    this.itemsNames = [
      this._validationTypes.EXTRACTION,
      this._validationTypes.BUSINESS_RULES,
      this._validationTypes.PRELIMINARY,
      this._validationTypes.CATALOG
    ];
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnChanges() {
    if (this.elements) {
      this.catalogValidations = [];
      this.elements.forEach(d => {
        this.items[d.validationtypeid - 1] = d;
        if (d.label == this._validationTypes.CATALOG) {
          this.catalogValidations.push(d);
        }
      });
    }

    const index = this.activeChild.validationtypeid
      ? this.activeChild.validationtypeid - 1
      : 0;
    this.showContent(this.items[index]);
  }

  public getChecklistStatusFailure() {
    let countFailure = 0;
    this.catalogValidations.forEach(validationexecution => {
      if (validationexecution.checklistsexecution.length > 0) {
        validationexecution.checklistsexecution.forEach(checklistexecution => {
          if (
            checklistexecution.validationstatusid.validationstatusname ==
            this._validationStatus.FAILURE
          ) {
            countFailure++;
          }
        });
      }
    });
    if (countFailure > 0) {
      return true;
    } else {
      return false;
    }
  }

  public getChecklistStatusSkipped() {
    let countSkipped = 0;
    let countSuccess = 0;

    let checklistexecutions = 0;
    this.catalogValidations.forEach(validationexecution => {
      if (validationexecution.checklistsexecution.length > 0) {
        checklistexecutions =
          validationexecution.checklistsexecution.length + checklistexecutions;
        validationexecution.checklistsexecution.forEach(checklistexecution => {
          if (
            checklistexecution.validationstatusid.validationstatusname ==
            this._validationStatus.SKIPPED
          ) {
            countSkipped++;
          }
          if (
            checklistexecution.validationstatusid.validationstatusname ==
            this._validationStatus.SUCCESS
          ) {
            countSuccess++;
          }
        });
      }
    });
    if (countSuccess == checklistexecutions) {
      return true;
    } else {
      if (countSkipped > 0 && countSkipped < checklistexecutions) {
        return true;
      } else {
        return false;
      }
    }
  }

  public showContent(event) {
    if (this.overview) {
      this.activeChild = {};
    } else {
      if (
        event.validationtypeid !== this.activeChild.validationtypeid ||
        event.failureConditions < this.activeChild.failureConditions
      ) {
        this.activeChild = event;
      }

      if (event.validationexecutionid) {
        this.timelineContent.emit(event);
      }
    }

    this.containerClass = '';
    setTimeout(() => {
      if (this.activeChild.statusPoint)
        this.containerClass = `timeline__container--${this.activeChild.statusPoint}`;
      if (this.link.controlExternalAccess())
        this.containerClass = `timeline__container--pending`;
    }, 100);
    let chunks = this.documentRenderService.getChunks();
    if (Object.keys(chunks).length !== 0) {
      let documentId = this.documentRenderService.getMainDocumentId();
      let item = {
        chunks: chunks[1],
        documentId: documentId
      };

      if(this.checkIsElectronic() && !this.documentRenderService.getIsMainDocumentRendered()){
        this.documentRenderService.hideAllChunks();
        this.documentRenderService.emptyMarkedChunks();
        this.documentRenderService.emptyImages();
        this.documentRenderService.emptyPages()
        this.documentRenderService.setDocumentId(this.documentId);
        this.documentRenderService.setPages(this.documentPages);
        this.documentRenderService.updateStartDocumentThumbnailsProvider();
  
        this.documentRenderService.goToMainDoc(item, documentId);
        this.documentRenderService.setIsMainDocumentRendered(true);
      } 
      
    }
  }

  public getItemClass(el) {
    let itemClass = '';
    if (this.overview || !el.status || this.link.controlExternalAccess()) {
      itemClass += ' is-disabled';
    } else if (this.activeChild.label === el.label) {
      itemClass += ' is-active';
    }
    return itemClass;
  }

  public getPointClass(el) {
    if (el.statusPoint && !this.link.controlExternalAccess()) {
      if (el.label == this._validationTypes.CATALOG) {
        if (this.getChecklistStatusFailure()) {
          this.containerClass = `timeline__container--${this._validationStatus.FAILURE}`;
          return `timeline__point--${this._validationStatus.FAILURE}`;
        } else {
          if (
            el.statusPoint == this._validationStatus.SKIPPED &&
            this.getChecklistStatusSkipped()
          ) {
            this.containerClass = `timeline__container--${this._validationStatus.SUCCESS}`;
            return `timeline__point--${this._validationStatus.SUCCESS}`;
          } else {
            this.containerClass = `timeline__container--${el.statusPoint}`;
            return `timeline__point--${el.statusPoint}`;
          }
        }
      }
      return `timeline__point--${el.statusPoint}`;
    } else {
      return 'timeline__point--pending';
    }
  }

  /**Check if the document is electronic or if it is an electronic child. If that is the case 
   * the request to docpictures and docchunks should not be sent
  */
  public checkIsElectronic(): boolean{
    if(this.documentDetails.isparentdocument && this.documentDetails.datamodel.iselectronic){
      return false;
    }

    if(this.documentDetails.parentdocumentdatamodel !== undefined){
      if(!this.documentDetails.isparentdocument && this.documentDetails.parentdocumentdatamodel['datamodel'].iselectronic){
        return false;
      }
    }

    return true;

  }

}
