import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor() { }

  // Data estática para la tabla
  public tableData = [
    {
      documentName: 'Document 1',
      documentType: 'Invoice',
      aiAgentsProcessed: 'Extraction (XTR)',
      status: 'Processed',
      validationErrorType: 'None',
      date: new Date()
    },
    {
      documentName: 'Document 2',
      documentType: 'Mortgage',
      aiAgentsProcessed: 'Classification (CLF)',
      status: 'Pending',
      validationErrorType: 'Description mismatch',
      date: new Date()
    },
    {
      documentName: 'Document 3',
      documentType: 'Purchase Contract',
      aiAgentsProcessed: 'Validation (VAL)',
      status: 'Failed',
      validationErrorType: 'Duplicate charges',
      date: new Date()
    },
    // Agrega más objetos con datos según sea necesario
  ];

  // Datos para el gráfico de pastel
  public data = [
    { name: 'Success', total: 123 },
    { name: 'Failure', total: 21 },
    { name: 'In Progress', total: 20 }
  ];

  // Configuración para el gráfico de pastel
  public configuration = {
    height: 300,
    margin: { top: 10, right: 10, bottom: 10, left: 10 },
    key: 'name',
    value: 'total',
    colorScheme: 'schemeCategory10', // Puedes usar 'schemeSet1', un array de colores personalizados o un objeto colorKeys
    colorKeys: {
      Success: '#339900',
      Warning: '#ffcc00',
      Failure: '#cc3300'
    },
    radius: false,
    innerRadius: 0.5,
    hasLabels: true,
    labelPadding: 0.1
  };

  public data2 = [
    { total: Math.floor(Math.random() * 100), when: '2023-01' },
    { total: Math.floor(Math.random() * 100), when: '2023-02' },
    { total: Math.floor(Math.random() * 100), when: '2023-03' },
    { total: Math.floor(Math.random() * 100), when: '2023-04' },
    { total: Math.floor(Math.random() * 100), when: '2023-05' },
    { total: Math.floor(Math.random() * 100), when: '2023-06' },
    { total: Math.floor(Math.random() * 100), when: '2023-07' },
    { total: Math.floor(Math.random() * 100), when: '2023-08' },
    { total: Math.floor(Math.random() * 100), when: '2023-09' },
    { total: Math.floor(Math.random() * 100), when: '2023-10' },
    { total: Math.floor(Math.random() * 100), when: '2023-11' },
    { total: Math.floor(Math.random() * 100), when: '2023-12' }
  ];

  public configuration2 = {
    height: 400,
    margin: { top: 10, right: 30, bottom: 50, left: 40 },
    key: 'total',  // Usamos 'total' para representar la cantidad de errores
    dateKey: 'when',  // 'when' representa la fecha (mes)
    dateFormat: '%Y-%m',  // Formato de fecha en años y meses
    labelRotation: -45,  // Rotamos los nombres de los meses si se solapan
    colorScheme: ['steelblue'],  // Color de las barras
    yAxis: 'Cantidad de Errores',  // Etiqueta del eje Y
    yScaleTicks: 5,  // Número de divisiones en el eje Y
    yScaleFormat: '.0f',  // Formato del número (sin decimales)
    brushHeight: 30,  // Tamaño del brush (la zona de selección)
    brushGap: 26  // Separación entre la gráfica y el brush
  };

  ngOnInit(): void {
    // Inicialización adicional si es necesario
  }

  // Manejar el evento de clic en una rebanada del gráfico
  private onClickEvent(data): void {
    console.log('Clicked slice data:', data);

  }

}
