import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { ApiService } from '../../../services/api.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { LinkService } from '../../../services/link.service';
import { PaginationService as PS } from '../../../services/pagination.service';
import { GlobalService } from 'src/app/services/global.service';

/**
 * Project list (table) component
 */
@Component({
  selector: 'app-project-list-view',
  templateUrl: './project-list-view.component.html',
  styleUrls: ['./project-list-view.component.scss']
})
export class ProjectListViewComponent implements OnInit {
  public columns: any[];
  public settingTable: any;
  public user: any;

  // Const
  public _userPermissions: any;

  @Input() loadingList: boolean;
  @Input() backgroundLoading: boolean;
  @Input() settings;
  @Input() data;
  @Output() loadingChangeList = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private link: LinkService,
    private paginationService: PS,
    private global: GlobalService
  ) {
    this._userPermissions = this.global.getUserPermissionsConst();
  }

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    this.getColumns();
    this.getSettingTable();
  }

  /**
   * Go to the project with the project information pass by params
   */
  public goToProject(project) {
    this.link.goToProject(project);
  }

  public goToProjectNewTab(project) {
    this.link.goToProject(project, true);
  }
  /**
   * Return loaded columnsProjectList with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectList.projectName',
        name: 'projectname',
        type: 'link',
        clickLink: this.goToProject.bind(this),
        orderBy: 'projectname'
      },
      {
        header: 'projectList.date',
        name: 'createddate',
        type: 'date',
        format: 'll',
        orderBy: 'createddate'
      },
      {
        header: 'projectList.analyses',
        name: 'analyses',
        class: 'sm-column'
      },
      {
        header: 'projectList.documents',
        name: 'documents',
        class: 'sm-column'
      },
      {
        type: 'link-fa-icon',
        header: 'responsiveTable.newTab',
        link: 'external',
        title: 'responsiveTable.newTab',
        class: 'sm-column',
        clickLink: this.goToProjectNewTab.bind(this),
        namefunc: rowData => {
          return '<i class="fa fa-external-link"></i>';
        }
      }
    ];
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'projectid',
      hasSelect: 'true',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.settings['getDataFromDB'],
      clickLink: this.goToProject.bind(this),
      actionsOnSelected: [
        {
          click: this.deleteSelectedProjects.bind(this),
          class: 'fa fa-trash',
          title: 'projectList.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteProject.bind(this),
          class: 'fa fa-trash'
        }
      ],
      responsiveTitle: {
        label: 'projectname',
        value: 'createddate',
        formatDate: 'll'
      }
    };
  }

  /**
   * Delete single project
   */
  public deleteProject(project) {
    this.deleteProjectModal([project.projectid]);
  }

  /**
   * Delete multiple projects
   */
  public deleteSelectedProjects(selectedItems) {
    this.deleteProjectModal(selectedItems);
  }

  /**
   * Load modal to confirm project(s) removal
   */
  private deleteProjectModal(projectsids: number[]) {
    if (projectsids.length < 1) {
      return;
    } // Break if array is empty
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'project',
      notAllowed: projectsids.length < 1
    };
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loadingChangeList.emit(true);
          const requestbody = {
            ids: projectsids
          };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {}
    );
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.apiService
      .post('projects/delete', requestbody, 'projectList.delete')
      .subscribe(
        object => {
          this.settings['getDataFromDB']();
        },
        error => {
          this.loadingChangeList.emit(false);
        }
      );
  }

  /**
   * Check if user has edit permissions
   */
  private hasEditPermissions() {
    return (
      this.user &&
      (this.authService.hasPermission(this._userPermissions.DELETE_PROJECTS) ||
        this.user.isadmin)
    );
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loadingChangeList.emit(bool);
  }
}
