import {
  Component,
  Input,
  OnChanges,
  HostListener,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { ActivatedRoute } from '@angular/router';
import { LinkService } from 'src/app/services/link.service';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewCommentComponent } from 'src/app/components/modals/new-comment/new-comment.component';
import { ValidationExecutionModel } from 'src/app/models/validation-execution.model';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { Subscription, concat } from 'rxjs';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { ValidationService } from '../../datamodels/datamodel-validation-list/validation/new-validation/common-components/validation.service';

@Component({
  selector: 'app-document-validation',
  templateUrl: './document-validation.pages.html',
  styleUrls: ['./document-validation.pages.scss']
})
export class DocumentValidationComponent implements OnChanges, OnDestroy {
  @Input() documentDetails: any;
  @Input() validationExecution: any;
  @Output() updateTimeline = new EventEmitter();
  @Output() setTestsUpdate = new EventEmitter();

  public _userPermissions: any;

  public loadingCollapsible: boolean = true;
  public tests: any;
  public validationsStatus: ValidationStatusModel[];
  public user: any;
  public validationExecutionIds: number[] = [];

  public windowScrollTop: number = 0;
  public distanceDocumentRenderToWindowTop: number = 92;

  public allTags: string[] = ['Transferencia', 'Otros'];
  public _validationTypes: any;
  public subscriptionUtils: Subscription;
  public datamodelMap;
  public isFullScreen: boolean = false;
  public previusElements: any[] = [];
  public showComments: boolean = false;
  public showCheckList: boolean = false;
  public getFullScreenSub: Subscription;

  constructor(
    private apiService: ApiService,
    private global: GlobalService,
    private route: ActivatedRoute,
    public link: LinkService,
    private modalService: NgbModal,
    private documentRenderService: DocumentRenderService,
    private authService: AuthenticationService
  ) {
    this.user = authService.getLoggedInUser();
    this._validationTypes = this.global.getValidationTypesConst();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.showComments = this.authService.userCanViewModule(
      this.user,
      'ManualCommentsFunctionality'
    );
    this.showCheckList = this.authService.userCanViewModule(
      this.user,
      'CheckListFunctionality'
    );
    this.getFullScreenSub = this.documentRenderService
      .getFullScreen()
      .subscribe(stateObj => {
        this.isFullScreen = stateObj.state;
      });
  }

  ngOnChanges() {
    this.datamodelMap = this.global.getDataModelMap();
    this.validationsStatus = this.global.getValidationStatus();
    if (
      this.validationsStatus.length === 0 &&
      this.datamodelMap.size === 0 &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = concat(
        this.global.watchDataModels(),
        this.global.watchUtils()
      ).subscribe((data: string) => {
        this.datamodelMap = this.global.getDataModelMap();
        this.validationsStatus = this.global.getValidationStatus();
        this.initDocumentValidation();
      });
    } else if (
      this.datamodelMap.size === 0 &&
      !this.global.passedWatcherDatamodels
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.datamodelMap = this.global.getDataModelMap();
          this.initDocumentValidation();
        });
    } else if (
      this.validationsStatus.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.validationsStatus = this.global.getValidationStatus();
          this.initDocumentValidation();
        });
    } else {
      this.initDocumentValidation();
    }
  }

  public initDocumentValidation() {
    this.validationExecutionIds = [];
    this.loadingCollapsible = true;
    this.setTests();
  }

  /**
   * Set the input variable to pass the data to the child component
   */
  public setTests() {
    if (
      this.validationExecution.label === this._validationTypes.BUSINESS_RULES
    ) {
      this.global
        .getValidationExecution(
          this.validationExecution.validationexecutionid,
          true
        )
        .then(
          (execution: ValidationExecutionModel) => {
            this.tests = this.serializeConditions(
              execution,
              this.validationExecution
            );
            this.loadingCollapsible = false;
          },
          () => {
            this.loadingCollapsible = false;
          }
        );
    } else {
      this.validationExecution.validations = this.groupByValidation();
      this.tests = { ...this.validationExecution };
      this.tests.name = this.validationExecution.label;
      this.loadingCollapsible = false;
    }
  }

  /**
   * Count the number of checklist in all the validations
   */
  public countChecklistValidations() {
    let countChecklist = 0;
    if (this.tests.name == this._validationTypes.CATALOG) {
      this.validationExecution.validations.forEach(validation => {
        validation.children.forEach(validationexecution => {
          if (validationexecution.checklistsexecution.length > 0) {
            countChecklist++;
          }
        });
      });
    }

    return countChecklist > 0;
  }

  /**
   * Listen for window scroll event
   */
  @HostListener('window:scroll', ['$event'])
  public onScroll(event) {
    this.windowScrollTop = window.pageYOffset;
  }

  /**
   * Check when the document render must be changed
   * to fixed position
   */
  public isDocumentRenderFixed() {
    return this.windowScrollTop > this.distanceDocumentRenderToWindowTop;
  }

  /**
   * Returns a nested array where the main key is
   * validationid and its content it's an array of
   * validationexecutions.
   */
  public groupByValidation() {
    let validations = {};
    this.validationExecution.validations.forEach(element => {
      validations[element.validationid]
        ? validations[element.validationid].push(element)
        : (validations[element.validationid] = [element]);
      this.validationExecutionIds.push(element.validationexecutionid);
    });
    return Object.keys(validations).map(key => {
      validations[key].forEach(e => {
        e.statusName = e.validationstatusid.validationstatusdisplayname;
        e.documentsNames = e.documents
          .map(d => d.documentdisplayname.split('.')[0])
          .join(' - ');
      });
      return {
        children: validations[key],
        status: validations[key][0].validationstatusid.validationstatusname,
        statusName:
          validations[key][0].validationstatusid.validationstatusdisplayname,
        validationtypename: this.validationExecution.label,
        validationname: validations[key][0].validationname
      };
    });
  }

  /**
   * This function is to serialized the data from the server
   * to show it properly in the front. Data is the result of
   * the query and point is the selected validation type in the
   * timeline.
   *
   * DUPLICATE CODE IN  document-details.page.ts (line 110)
   * TODO: Study the implication of using that code instead of this.
   *
   * @param data
   * @param point
   */
  private serializeConditions(data, point) {
    const tests = { ...data };
    tests.name = point.label;
    tests.totalConditions = point.totalConditions;
    tests.failureConditions = point.failureConditions;
    tests.conditions = data.conditions.map(condition => {
      const parsedCondition = { ...condition };
      parsedCondition.islastversion = data['islastversion'];
      if (tests.name === this._validationTypes.BUSINESS_RULES) {
        parsedCondition.validationtypename = tests.name;
        parsedCondition.isExtractionlastVersion = this.getExtraction();
      }
      parsedCondition.documentId = this.documentDetails.documentid;
      const messageLines = parsedCondition.message.split(' #-#');
      if (messageLines[messageLines.length - 1].trim() === '') {
        messageLines.pop();
      }
      const messageLinesMapped = messageLines.map(function (messageLine) {
        let newMessageLine = null;
        const messageLineSplit = messageLine.split('~');
        if (messageLineSplit.length > 2) {
          newMessageLine = {
            message: messageLineSplit[0] + messageLineSplit[2],
            row: messageLineSplit[1]
          };
        } else {
          newMessageLine = {
            message: messageLine,
            row: null
          };
        }
        return newMessageLine !== null ? newMessageLine : messageLine;
      });
      parsedCondition.messageLines = messageLinesMapped;
      this.validationsStatus.forEach(status => {
        if (condition.validationstatusid === status.validationstatusid) {
          parsedCondition.status = status.validationstatusname;
          parsedCondition.statusName = status.validationstatusdisplayname;
        }
      });
      parsedCondition.documentId = this.documentDetails.documentid;

      // Parse preconditions
      if (parsedCondition.preconditions) {
        parsedCondition.preconditions = parsedCondition.preconditions.map(
          precondition => {
            return this.global.preconditionToString(
              this.documentDetails.datamodel.datamodelid,
              precondition
            );
          }
        );
      }

      if (tests.comments) {
        this.setCommentAndFilesAttached(parsedCondition, tests);
      }
      return parsedCondition;
    });

    // Order message lines by number of row
    tests.conditions.forEach(condition => {
      const orderedLines = condition.messageLines.sort((a, b) => (parseInt(a.row) > parseInt(b.row)) ? 1 : -1)
      condition.messageLines = orderedLines
    });
    
    return tests;
  }

  public getExtraction(): any {
    const datamodel = this.global.getDataModelById(
      this.documentDetails.datamodel.datamodelid
    );
    return datamodel.validations.extraction;
  }

  /**
   * Set the comment and attached file variables to show
   * them properly in the list
   * @param item
   */
  public setCommentAndFilesAttached(item, tests) {
    item.comment = this.getCommentConditions(item, tests);
    if (item.comment) {
      item.fileAttached = item.comment.comment_attachments[0];
    }
  }

  /**
   * Return the comment for the conditions
   * @param item
   */
  public getCommentConditions(item, point) {
    return point.comments.find(c =>
      c.conditions_comment.find(
        conditionId =>
          conditionId.validationexecutionconditionsid ===
          item.validationexecutionconditionsid
      )
    );
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
  }

  /**
   * Output function to re render the checklist
   */
  public setTestsUpdatedChecklist() {
    this.setTestsUpdate.emit();
  }

  ngOnDestroy(): void {
    if (this.subscriptionUtils) this.subscriptionUtils.unsubscribe();
    if (this.getFullScreenSub) this.getFullScreenSub.unsubscribe();
  }
}
