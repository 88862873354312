import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-corrupt-document',
  templateUrl: './corrupt-document.component.html',
  styleUrls: ['./corrupt-document.component.scss']
})

export class CorruptDocumentComponent  {
  public message: string =`The document you are trying to retrieve is corrupt. Please contact a Smart Retrieval administrator for assistance.`;
  
  constructor(
    private router: Router,
  ) {
  }

  goBack() {
    const returnTo = '/';
    this.router.navigate([returnTo]);
  }
}
