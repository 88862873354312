import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements AfterViewInit {
  @ViewChild('donutChart') donutChart!: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    const ctx = this.donutChart.nativeElement.getContext('2d');
    new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: ['Success', 'In Progress', 'Failed'],
        datasets: [{
          data: [227790, 0, 22315], // Datos de ejemplo
          backgroundColor: ['#28a745', '#007bff', '#dc3545'], // Colores de las secciones
          hoverBackgroundColor: ['#28a745', '#007bff', '#dc3545'] // Colores al pasar el mouse
        }]
      },
      options: {
        title: {
          display: true,
          text: 'Document Status',
          fontSize: 18,
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'left',
          labels: {
            generateLabels: (chart) => {
              const dataset = chart.data.datasets[0];
              return chart.data.labels.map((label, index) => {
                const value = dataset.data[index].toLocaleString(); // Formatear con separadores de miles
                return {
                  text: `${label}: ${value}`, // Mostrar el valor formateado
                  fillStyle: dataset.backgroundColor[index],
                };
              });
            },
            padding: 20
          }
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const value = dataset.data[tooltipItem.index].toLocaleString(); // Formatear con separadores de miles
              const label = data.labels[tooltipItem.index];
              return `${label}: ${value}`; // Mostrar valor formateado en el tooltip
            }
          }
        }
      }
    });
  }
}
