import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectDetailsComponent } from './project-details/project-details.pages';
import { AnalysisDetailsComponent } from './analysis-details/analysis-details.pages';
import { DocumentDetailsPages } from './document-details/document-details.pages';
import { ProjectListComponent } from './project-list/project-list.pages';
import { PagesComponent } from './pages.component';
import { NotAllowedComponent } from '../errors/not-allowed/not-allowed.component';
import { NotAvailableComponent } from '../errors/not-available/not-available.component';
import { NotFoundComponent } from '../errors/not-found/not-found.component';
import { AdminComponent } from './admin/admin.component';
import { QueryComponent } from './query/query.component';
import { NewValidationComponent } from './datamodels/datamodel-validation-list/validation/new-validation/new-validation.component';
import { DatamodelValidationListComponent } from './datamodels/datamodel-validation-list/datamodel-validation-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ValidationsReviewComponent } from './validations-review/validations-review.component';
import { OutputFilesComponent } from './output-files/output-files.component';
import { TrainableComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/trainable.component';
import { ClientManagementComponent } from '../components/client-management/client-management.component';
import { ExternalViewerComponent } from './external-viewer/external-viewer.component';
import { ModulesPermissionsGuard } from '../security/modules-permissions-guard.service';
import * as _permissionConst from '../constants/userPermissions';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
import { QueueManagementComponent } from './admin/queue-management/queue-management.component';
import { PrivacyNoteComponent } from '../components/private-note/privacy-note.component';
import { CheckListComponent } from './datamodels/datamodel-validation-list/validation/new-validation/checklist/checklist.component';
import { getGuard } from '../security/guards/guard.config';
import { CorruptDocumentComponent } from '../errors/corrupt-document/corrupt-document.component';
import { OverviewComponent } from './overview/overview.component';

export const routes: Routes = [
  { path: 'access-denied', component: NotAllowedComponent },
  { path: 'server-unavailable', component: NotAvailableComponent },
  { path: 'corrupt-document', component: CorruptDocumentComponent },
  {
    path: '',
    component: PagesComponent,
    canActivate: [getGuard()],
    children: [
      {
        path: '',
        component: ClientManagementComponent
      },
      {
        path: ':tenantname',
        data: {
          name: 'Tenant',
          path: 'tenant'
        },
        children: [
          {
            path: 'privacy-note',
            data: {
              name: 'Privacy Note',
              path: 'privacy-note',
            },
            children: [
              {
                path: '',
                component: PrivacyNoteComponent
              }
            ]
          },
          {
            path: 'access-denied',
            component: NotAllowedComponent
          },
          {
            path: 'corrupt-document',
            component: CorruptDocumentComponent
          },
          {
            path: '',
            component: ClientManagementComponent
          },
          {
            path: 'projects',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Projects',
              path: 'projects',
              permissions: [_permissionConst.SCREEN_VIEW_PROJECT]
            },
            children: [
              {
                path: '',
                component: ProjectListComponent
              },
              {
                path: ':projectidname',
                children: [
                  {
                    path: '',
                    component: ProjectDetailsComponent
                  },
                  {
                    path: 'analysis',
                    children: [
                      {
                        path: '',
                        component: ProjectDetailsComponent
                      },
                      {
                        path: ':analysisidname',
                        children: [
                          {
                            path: '',
                            component: AnalysisDetailsComponent
                          },
                          {
                            path: 'documents',
                            children: [
                              {
                                path: '',
                                component: AnalysisDetailsComponent
                              },
                              {
                                path: ':documentidname',
                                children: [
                                  {
                                    path: '',
                                    component: DocumentDetailsPages
                                  },
                                  {
                                    path: 'documents',
                                    children: [
                                      {
                                        path: '',
                                        component: DocumentDetailsPages
                                      },
                                      {
                                        path: ':documentidname',
                                        component: DocumentDetailsPages
                                      }
                                    ]
                                  }
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            path: 'admin',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Admin',
              path: 'admin',
              permissions: [_permissionConst.SCREEN_USERS_MANAGMENT]
            },
            children: [
              {
                path: '',
                component: AdminComponent
              }
            ]
          },
          {
            path: 'queues',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Queues',
              path: 'queues'
            },
            children: [
              {
                path: '',
                component: QueueManagementComponent
              }
            ]
          },
          {
            path: 'datamodels-validations',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Datamodels & Validations',
              path: 'datamodels-validations',
              permissions: [_permissionConst.VIEW_DATAMODELS]
            },
            children: [
              {
                path: '',
                component: DatamodelValidationListComponent
              },
              {
                path: 'validations',
                children: [
                  {
                    path: '',
                    component: DatamodelValidationListComponent
                  },
                  {
                    path: 'new-validation',
                    canActivate: [ModulesPermissionsGuard],
                    data: {
                      name: 'New validation',
                      path: 'new-validation',
                      permissions: [_permissionConst.CREATE_VALIDATIONS],
                      modules: ['ValidationsCoreFunctionality']
                    },
                    component: NewValidationComponent
                  },
                  {
                    path: 'next-step',
                    canActivate: [ModulesPermissionsGuard],
                    data: {
                      name: 'New validation',
                      path: 'next-step',
                      permissions: [_permissionConst.CREATE_VALIDATIONS],
                      modules: ['ValidationsCoreFunctionality']
                    },
                    component: NewValidationComponent
                  },
                  {
                    path: 'edit',
                    canActivate: [ModulesPermissionsGuard],
                    data: {
                      name: 'Edit validation',
                      path: 'edit',
                      permissions: [_permissionConst.CREATE_VALIDATIONS],
                      modules: ['ValidationsCoreFunctionality']
                    },
                    component: NewValidationComponent
                  }
                ]
              },
              {
                path: 'datamodels-details',
                canActivate: [ModulesPermissionsGuard],
                data: {
                  name: 'Datamodels Details',
                  path: 'datamodels-details',
                  permissions: [
                    _permissionConst.CREATE_DATAMODELS,
                    _permissionConst.EDIT_DATAMODELS
                  ]
                },
                component: TrainableComponent
              },
              {
                path: 'checklist/edit',
                canActivate: [ModulesPermissionsGuard],
                data: {
                  name: 'Checklist',
                  path: 'checklist',
                  permissions: [
                    _permissionConst.CREATE_VALIDATIONS,
                    _permissionConst.EDIT_VALIDATIONS
                  ]
                },
                component: CheckListComponent
              },
              {
                path: 'new-checklist',
                canActivate: [ModulesPermissionsGuard],
                data: {
                  name: 'Checklist',
                  path: 'checklist',
                  permissions: [
                    _permissionConst.CREATE_VALIDATIONS,
                    _permissionConst.EDIT_VALIDATIONS
                  ]
                },
                component: CheckListComponent
              }
            ]
          },
          {
            path: 'query',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Scan',
              path: 'query',
              permissions: [_permissionConst.SCREEN_VIEW_SCAN],
              modules: ['CognitiveSearchCoreFunctionality']
            },
            children: [
              {
                path: '',
                component: QueryComponent
              }
            ]
          },
          {
            path: 'documentsReview',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Documents review',
              path: 'documentsReview',
              permissions: [_permissionConst.SCREEN_VALIDATIONS],
              modules: ['ValidationsCoreFunctionality']
            },
            children: [
              {
                path: '',
                component: ValidationsReviewComponent
              }
            ]
          },
          {
            path: 'reporting',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Reporting',
              path: 'reporting',
              permissions: [_permissionConst.SCREEN_VIEW_OUTPUT_FILES],
              modules: ['OutputFilesFunctionality']
            },
            children: [
              {
                path: '',
                component: OutputFilesComponent
              }
            ]
          },
          {
            path: 'clientManagement',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Client Management',
              path: 'clientManagement',
              permissions: [_permissionConst.SCREEN_CLIENTS_MANAGMENT]
            },
            children: [
              {
                path: '',
                component: ClientManagementComponent
              }
            ]
          },
          {
            path: 'overview',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Overview',
              path: 'dashboard',
              permissions: [_permissionConst.SCREEN_VIEW_DASHBOARDS],
            },
            children: [
              {
                path: '',
                component: OverviewComponent
              }
            ]
          },
          {
            path: 'dashboard',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Dashboard',
              path: 'dashboard',
              permissions: [_permissionConst.SCREEN_VIEW_DASHBOARDS],
              modules: ['ChartsDashboard']
            },
            children: [
              {
                path: '',
                component: DashboardComponent
              }
            ]
          },
          {
            path: 'timeTracking',
            canActivate: [ModulesPermissionsGuard],
            data: {
              name: 'Time tracking',
              path: 'timeTracking',
              permissions: [_permissionConst.SCREEN_TIME_TRACKING],
              modules: ['TimeTrackingCoreFunctionality']
            },
            children: [
              {
                path: '',
                component: TimeTrackingComponent
              }
            ]
          },
          {
            path: 'externalViewer',
            children: [
              {
                path: '',
                canActivate: [ModulesPermissionsGuard],
                data: {
                  name: 'External viewer',
                  path: 'externalViewer',
                  permissions: [_permissionConst.SCREEN_CLIENT_DOCUMENTS],
                  modules: ['ClientDocumentsFunctionality']
                },
                component: ExternalViewerComponent
              },
              {
                path: 'documents',
                canActivate: [ModulesPermissionsGuard],
                data: {
                  name: 'External viewer',
                  path: 'externalViewer',
                  permissions: [_permissionConst.SCREEN_CLIENT_DOCUMENTS],
                  modules: ['ClientDocumentsFunctionality']
                },
                children: [
                  {
                    path: '',
                    component: ExternalViewerComponent
                  },
                  {
                    path: ':documentidname',
                    children: [
                      {
                        path: '',
                        component: DocumentDetailsPages
                      },
                      {
                        path: 'documents',
                        children: [
                          {
                            path: '',
                            component: DocumentDetailsPages
                          },
                          {
                            path: ':documentidname',
                            component: DocumentDetailsPages
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                path: ':documentidname',
                canActivate: [ModulesPermissionsGuard],
                data: {
                  name: 'Document Details',
                  path: 'documentDetails',
                  permissions: [_permissionConst.SCREEN_CLIENT_ONLY_DOCUMENTDETAIL],
                  modules: ['ClientDocumentDetail']
                },
                children: [
                  {
                    path: '',
                    component: DocumentDetailsPages
                  }
                ]
              }
            ]
          },
          { path: '**', component: NotFoundComponent }
        ]
      },
      { path: '**', component: NotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRouting {}

export const routing: ModuleWithProviders<any> = RouterModule.forChild(routes);
