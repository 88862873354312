import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  FormArray
} from '@angular/forms';
import {
  TrainableService
} from '../../trainable.service';
import {
  TranslatePipe
} from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-multiple',
  templateUrl: './trainable-multiple.component.html',
  styleUrls: ['./trainable-multiple.component.scss']
})
export class TrainableMultipleComponent implements OnInit {
  @Input() public multiple: FormArray;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  @Input('datamodelProcessType') public datamodelProcessType: String;
  @Input('datamodelLang') public datamodelLang: String;

  constructor(
    private translate: TranslatePipe,
    public trainableService: TrainableService
  ) {}

  ngOnInit() {}

  public addField() {
    this.trainableService.addField(this.multiple, this.trainableService.GOFTYPESIDS.MULTIPLE);
  }

  public addKey(form, validator ? ) {
    this.trainableService.addNewInputNameControl(form, validator);
  }

  public removeKey(form, index) {
    this.trainableService.removeInputControl(form, index);
  }

  public getFieldName(value) {
    return value ?
      value :
      this.translate.transform('trainable.individual.newField');
  }
}
