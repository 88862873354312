import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  FormArray,
  FormControl,  
  FormGroup
} from '@angular/forms';
import {
  TrainableService
} from '../trainable.service'; 
import {
  TrainableTableComponent
} from './trainable-table/trainable-table.component';
import {
  TrainableIndividualComponent
} from './trainable-individual/trainable-individual.component';
import {
  GlobalService
} from 'src/app/services/global.service';
import {
  TranslatePipe
} from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-group-of-fields',
  templateUrl: './group-of-fields.component.html',
  styleUrls: ['./group-of-fields.component.scss']
})
export class GroupOfFieldsComponent implements OnInit {
  @Input('groupOfField') public groupOfField: FormArray;
  @Input('isCreating') public isCreating: boolean;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('gofCtrl') public gofCtrl: FormControl;
  @Input('index') public index: number;
  @Input('datamodelProcessType') public datamodelProcessType: String;
  @Input('datamodelLang') public datamodelLang: String;

  public isOpen: boolean = false;

  public groupOfFieldControl: FormGroup;

  public groupOfFieldsTypes: any[] = [];

  public isCollapsed: boolean = true;

  public question: String = '';

  constructor(public trainableService: TrainableService, public global: GlobalService, private translate: TranslatePipe, ) {}

  ngOnInit() {
    this.groupOfFieldControl = this.groupOfField.controls[this.index] as FormGroup;
    this.global.getGroupOfFieldsTypes().then(result => (this.groupOfFieldsTypes = result));
    
    if (!Boolean(this.groupOfFieldControl.get('groupoffieldsquestion').value)) {
      this.groupOfFieldControl.get('groupoffieldsquestion').setValue(this.getQuestion())
    }
  }

  public changeType() {
    const value = this.groupOfFieldControl.get('groupoffieldstypeid').value;
    switch (value) {
      // Individual case
      case this.trainableService.GOFTYPESIDS.INDIVIDUAL:
        this.trainableService.addControlIndividual(this.groupOfFieldControl);
        break;
        // Table case
      case this.trainableService.GOFTYPESIDS.TABLE:
        this.trainableService.addControlKeyTable(this.groupOfFieldControl);
        break;
        // Multiple case
      case this.trainableService.GOFTYPESIDS.MULTIPLE:
        this.trainableService.addControlMultiple(this.groupOfFieldControl);
        break;        
        // Default: Individual case
      default:
        this.trainableService.addControlIndividual(this.groupOfFieldControl);
        break;
    }
  }

  /**
   * Toggle gof expanded status
   * @param index gof index number
   */
  public toggleExpanded(index: number) {
    this.isOpen = !this.isOpen;
  }

  /**
   * Get GOF name
   * @param gofName
   */
  public getGOFName(gofName: string) {
    return gofName ?
      gofName :
      this.translate.transform('trainable.groupOfFields.newGOF');
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  public getQuestion() {
    switch (this.datamodelLang) {
      case 'French':
        return 'Quest-ce que la ' + this.getGOFName(this.gofCtrl.get('groupoffieldsname').value) + ' tableau des documents?'    
      case 'Spanish':
        return'¿Cual es el ' + this.getGOFName(this.gofCtrl.get('groupoffieldsname').value) + ' tabla de el documento?'    
      case 'German':
        return 'Was ist die ' + this.getGOFName(this.gofCtrl.get('groupoffieldsname').value) + ' Dokumententabelle?'       
      default:
        return 'What is the ' + this.getGOFName(this.gofCtrl.get('groupoffieldsname').value) + ' table of the document?'   
    }
  }
}
