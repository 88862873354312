import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import {
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TrainableService
} from '../../trainable.service';
import {
  TranslatePipe
} from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-table-body',
  templateUrl: './trainable-table-body.component.html',
  styleUrls: ['./trainable-table-body.component.scss']
})
export class TrainableTableBodyComponent implements OnInit {

  @Input() public table: FormArray;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  @Input('field') public field: FormControl;
  @Input('index') public index: number;

  public isOpen: boolean = false

  public isCollapsed: boolean = true;

  constructor(
    private translate: TranslatePipe,
    public trainableService: TrainableService
  ) {}

  ngOnInit() {}

  public getFieldName(value) {
    return value ?
      value :
      this.translate.transform('trainable.individual.newField');
  }

  /**
   * Toggle field expanded
   * @param index field index number
   */
  public toggleExpandedField(index: number) {
    this.isOpen = !this.isOpen;
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
