import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentModel } from 'src/app/models/document.model';
import { PaginationModel } from 'src/app/models/pagination';
import { ApiService } from 'src/app/services/api.service';
import { GlobalService } from 'src/app/services/global.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { LinkService } from 'src/app/services/link.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { D3BarChartBrushedComponent } from 'src/app/components/graphs/barchart-brushed/barchart-brushed.component';
import * as moment from 'moment';
import { concat, Subject, Subscription } from 'rxjs';
import { StyleManagerService } from 'src/app/services/stylemanager.service';

export const DATE_INPUT_FORMAT = {
  display: {
    dateInput: 'll',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

/**
 * Client overview dashboard
 */
@Component({
  selector: 'app-dashboard-charts',
  templateUrl: './dashboard-charts.component.html',
  styleUrls: ['./dashboard-charts.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_INPUT_FORMAT }
  ]
})
export class DashboardChartsComponent implements OnInit, OnDestroy {
  @ViewChild('datesbarchart') datesbarchart: D3BarChartBrushedComponent;

  public start_date: Date = new Date();
  public end_date: Date = new Date();
  public projectid: number | string = '';
  public datamodelid: number | string = '';
  public first_date: Date = new Date();
  public last_date: Date = new Date();
  public weekday: number;
  public hour: number;
  public processtime: number;
  public status: number;

  public kpiProjects: number;
  public kpiDatamodels: number;
  public kpiDocuments: number;
  public kpiDocumentsClassified: number;

  public dateData: Array<any> = [];
  public dateDataConfig;
  public projectData: Array<any> = [];
  public projectDataConfig;
  public datamodelData: Array<any> = [];
  public datamodelDataConfig;
  public weekData: Array<any> = [];
  public weekDataConfig;
  public hourData: Array<any> = [];
  public hourDataConfig;
  public processData: Array<any> = [];
  public processDataConfig;
  public statusData: Array<any> = [];
  public statusDataConfig;
  public flowData;
  public flowDataConfig;

  public documents: Array<DocumentModel> = [];
  public selectedItems: Array<number> = [];
  private pagination: PaginationModel;

  public hasDocuments: boolean = true;
  public dateLoading: boolean;
  public kpisLoading: boolean;
  public projectLoading: boolean;
  public datamodelLoading: boolean;
  public weekLoading: boolean;
  public hourLoading: boolean;
  public processLoading: boolean;
  public statusLoading: boolean;
  public flowLoading: boolean;
  public documentsLoading: boolean = true;

  public _documentStatus: any;
  public totalDocuments = 0;
  public statusList = [];
  public datamodelList;
  public subscriptionReviewers: Subscription;

  public showFilters = false;
  public filters = {};
  public labelFilters = [];

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  public settingTable: any;
  public columns: any[];
  public rows: any = {};

  constructor(
    private apiService: ApiService,
    private globalService: GlobalService,
    private paginationService: PaginationService,
    private translate: TranslatePipe,
    private styleManager: StyleManagerService
  ) {
    // Set default start date to 10 years before to fetch all documents
    this.start_date.setFullYear(this.start_date.getFullYear() - 10);
    this._documentStatus = this.globalService.getDocumentStatusConst();
    this.getColumns();
    this.getSettingTable();
  }

  ngOnInit() {
    const style = this.styleManager.styleSettings;
    const chartsStyle = style.charts;

    this.pagination = this.paginationService.getDefaultPagination('documents');
    this.dateDataConfig = {
      key: 'count',
      dateKey: 'date',
      dateFormat: '%Y-%m-%d',
      height: 180,
      yAxis: 'Documents'
    };
    this.projectDataConfig = {
      key: 'count',
      label: 'projectname',
      height: 210,
      colorScheme: chartsStyle.projectColorScheme,
      yAxis: 'Documents',
      labelRotation: 20,
      margin: { bottom: 70 }
    };
    this.datamodelDataConfig = {
      key: 'count',
      label: 'datamodelname',
      height: 210,
      colorScheme: chartsStyle.datamodelColorScheme,
      yAxis: 'Documents',
      labelRotation: 20,
      margin: { bottom: 70 }
    };
    this.weekDataConfig = {
      key: 'count',
      label: 'weekday',
      height: 210,
      colorScheme: chartsStyle.weekColorScheme,
      yAxis: 'Documents'
    };
    this.hourDataConfig = {
      key: 'count',
      label: 'hour',
      height: 210,
      colorScheme: chartsStyle.hourColorScheme,
      yAxis: 'Documents'
    };
    this.processDataConfig = {
      key: 'processtime',
      height: 180,
      colorScheme: chartsStyle.processColorScheme,
      yAxis: 'Documents',
      bins: 40
    };
    this.statusDataConfig = {
      margin: { top: 50, right: 20, bottom: 20, left: 20 },
      height: 240,
      key: 'statusname',
      value: 'count',
      colorKeys: {
        Pending: chartsStyle.status.colorKeys.pending,
        Failure: chartsStyle.status.colorKeys.failure,
        Success: chartsStyle.status.colorKeys.success,
        'In Progress': chartsStyle.status.colorKeys.inProgress,
        Warning: chartsStyle.status.colorKeys.warning,
        'Partial Success': chartsStyle.status.colorKeys.partialSuccess
      },
      innerRadius: 0.65
      //hasLabels: false,
    };
    this.flowDataConfig = {
      colorScheme: chartsStyle.flowColorScheme,
      colorKeys: {
        Pending: chartsStyle.status.colorKeys.pending,
        Failure: chartsStyle.status.colorKeys.failure,
        Success: chartsStyle.status.colorKeys.success,
        'In Progress': chartsStyle.status.colorKeys.inProgress,
        Warning: chartsStyle.status.colorKeys.warning,
        'Partial Success': chartsStyle.status.colorKeys.partialSuccess
      }
    };

    this.getClientKPIs();
    this.getDocumentsByDate();
    this.getDocumentsByProject();
    this.getDocumentsByDatamodel();
    this.getWeekDayData();
    this.getHourData();
    this.getProcessTimeData();
    this.getStatusData();
    this.statusList = this.globalService.getGlobalStatus();
    this.datamodelList = Array.from(
      this.globalService.getDatamodels().values()
    );

    if (!this.statusList && this.datamodelList.length === 0) {
      this.subscriptionReviewers = concat(
        this.globalService.watchDataModels(),
        this.globalService.watchUtils()
      ).subscribe(() => {
        this.datamodelList = Array.from(
          this.globalService.getDatamodels().values()
        );
        this.statusList = this.globalService.getGlobalStatus();
        this.getDocuments();
      });
    } else if (this.datamodelList.length === 0) {
      this.subscriptionReviewers = this.globalService
        .watchDataModels()
        .subscribe(() => {
          this.datamodelList = Array.from(
            this.globalService.getDatamodels().values()
          );
          this.getDocuments();
        });
    } else if (!this.statusList) {
      this.subscriptionReviewers = this.globalService
        .watchUtils()
        .subscribe(() => {
          this.statusList = this.globalService.getGlobalStatus();
          this.getDocuments();
        });
    } else {
      this.getDocuments();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
  }

  private setRequestParams() {
    // start and end date are required
    if (!this.start_date || !this.end_date) return null;
    // end date must be later than start date
    if (this.start_date > this.end_date) return null;

    let params = {
      start_date: this.formatDate(this.start_date),
      end_date: this.formatDate(this.end_date),
      tzoffset: -1 * new Date().getTimezoneOffset()
    };

    if (this.projectid) {
      params['analysisid__projectid'] = this.projectid;
    }
    if (this.datamodelid) {
      params['datamodelid'] = this.datamodelid;
    }
    if (typeof this.weekday === 'number') {
      params['createddate__week_day'] = this.weekday;
    }
    if (typeof this.hour === 'number') {
      params['createddate__hour'] = this.hour;
    }
    if (this.processtime) {
      params['processtime__gte'] = this.processtime[0];
      params['processtime__lte'] = this.processtime[1];
    }
    if (typeof this.status === 'number') {
      params['statusid'] = this.status;
    }

    return params;
  }

  /**
   * Request documents grouped by date
   */
  private getDocumentsByDate() {
    this.dateLoading = true;
    this.apiService.get('clientoverview/dates/', {}, '').subscribe(data => {
      if (data.length > 0) {
        this.first_date = new Date(data[0].date);
        this.last_date = new Date(data[data.length - 1].date);
        this.start_date = this.first_date;
        this.end_date = this.last_date;
        this.dateData = this.parseDatesData(data);
        this.hasDocuments = true;
        this.dateLoading = false;
      } else {
        this.hasDocuments = false;
      }
    });
  }

  /**
   * Request client general KPIs
   */
  private getClientKPIs() {
    this.kpisLoading = true;
    let params = this.setRequestParams();
    if (!params) return;

    this.apiService.get('clientoverview/kpis/', params, '').subscribe(data => {
      this.kpiProjects = data.projects;
      this.kpiDatamodels = data.datamodels;
      this.kpiDocuments = data.documents;
      this.kpiDocumentsClassified = data.documents_classified;
      this.kpisLoading = false;
    });
  }

  /**
   * Request documents grouped by project
   */
  private getDocumentsByProject() {
    let params = this.setRequestParams();
    if (!params) return;

    this.projectLoading = true;
    this.apiService
      .get('clientoverview/projects/', params, '')
      .subscribe(data => {
        this.projectData = data;
        this.projectLoading = false;
      });
  }

  /**
   * Request documents grouped by datamodel
   */
  private getDocumentsByDatamodel() {
    let params = this.setRequestParams();
    if (!params) return;

    this.datamodelLoading = true;
    this.apiService
      .get('clientoverview/datamodels/', params, '')
      .subscribe(data => {
        this.datamodelData = data;
        this.datamodelLoading = false;
      });
  }

  /**
   * Request documents grouped by day of the week
   */
  private getWeekDayData() {
    let params = this.setRequestParams();
    if (!params) return;

    this.weekLoading = true;
    this.apiService
      .get('clientoverview/weekdays/', params, '')
      .subscribe(data => {
        this.weekData = this.parseWeekData(data);
        this.weekLoading = false;
      });
  }

  /**
   * Request documents grouped by hour of the day
   */
  private getHourData() {
    let params = this.setRequestParams();
    if (!params) return;

    this.hourLoading = true;
    this.apiService.get('clientoverview/hours/', params, '').subscribe(data => {
      this.hourData = this.parseHourData(data);
      this.hourLoading = false;
    });
  }

  /**
   * Request documents grouped by process time (histogram)
   */
  private getProcessTimeData() {
    let params = this.setRequestParams();
    if (!params) return;

    this.processLoading = true;
    this.apiService
      .get('clientoverview/processtime/', params, '')
      .subscribe(data => {
        this.processData = this.parseProcessData(data);
        this.processLoading = false;
      });
  }

  /**
   * Request documents grouped by successfulness status (piechart)
   */
  private getStatusData() {
    let params = this.setRequestParams();
    if (!params) return;

    this.statusLoading = true;
    this.apiService
      .get('clientoverview/status/', params, '')
      .subscribe(data => {
        this.statusData = data.filter(d => d.count && d.count > 0);
        this.statusLoading = false;
      });
  }

  /**
   * Request documents grouped by status and required fields (sankey)
   */
  private getFlowData() {
    let params = this.setRequestParams();
    if (!params) return;

    this.flowLoading = true;
    if (this.datamodelid) {
      this.apiService
        .get('clientoverview/flows/', params, '')
        .subscribe(data => {
          this.flowData = data;
          this.flowLoading = false;
        });
    }
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  /**
   * Load modal to assign a user for review the selected validations
   * @param filters
   */
  public onSearch(filters) {
    this.pagination = this.paginationService.getDefaultPagination('documents');
    this.filters = {
      documentdisplayname:
        filters['documentdisplayname'] != undefined
          ? filters['documentdisplayname']
          : '',
      documentdisplayname_logic:
        filters['documentdisplayname_logic'] != undefined
          ? filters['documentdisplayname_logic']
          : '',
      datamodelid:
        filters['datamodelId'] != undefined ? filters['datamodelId'] : '',
      statusid: filters['statusId'] != undefined ? filters['statusId'] : ''
    };
    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'datamodelid':
            return { name: 'datamodelname', value: filters['datamodelname'] };
          case 'statusid':
            return { name: 'status_name', value: filters['status_name'] };
          case 'documentdisplayname_logic':
            return { name: 'documentdisplayname_logic', value: filters['documentdisplayname_logic'] };
          default:
            return { name: f, value: this.filters[f] };
        }
      });

    this.getDocuments();
  }

  /**
   * Function that returns the inner logic text value
   */
   public filterTextValue(logic: string) {
    switch (logic) {
      case 'or':
        return this.translate.transform('documentFilter.or');
      case 'and':
        return this.translate.transform('documentFilter.and');
      case 'phrase':
        return this.translate.transform('documentFilter.phrase');
      case 'starts_with':
        return this.translate.transform('documentFilter.startsWith');
      case 'ends_with':
        return this.translate.transform('documentFilter.endsWith');
      default:
        return logic;
    }
  }

  /**
   * Request documents (table)
   */
  private getDocuments() {
    let params = this.setRequestParams();
    if (!params) {
      this.documentsLoading = false;
      return;
    }

    this.documentsLoading = true;
    this.apiService
      .get(
        'clientoverview/documents/',
        { ...params, ...this.filters, page: this.pagination.page },
        ''
      )
      .subscribe(data => {
        
        this.documents = data.data;
        this.selectedItems = [];
        let dataObject = {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          data: data.data
        };
        this.rows = dataObject;

        this.pagination = this.paginationService.setPagination('documents', {
          total_elements: data.total_elements,
          page: data.page,
          page_size: data.page_size,
          num_pages: data.num_pages,
          order_by: data.order_by,
          desc: data.desc,
          query: this.pagination.query
        });
        this.totalDocuments = data.total_elements;
        this.documentsLoading = false;
      });
  }

  /**
   * Format Date object to yyyy-mm-dd
   */
  public formatUTCDate(date) {
    date = moment.utc(date).toDate();
    return moment(date).local().format('YYYY-MM-DD');
  }

  /**
   * Format Date object to yyyy-mm-dd
   */
  public formatDate(date) {
    return moment(new Date(date)).format('YYYY-MM-DD');
  }

  /**
   * Format date data to add empty days
   * (request returns only dates with values greater than 0)
   */
  public parseDatesData(data) {
    const awareData = data.map(d => ({
      ...d,
      date: this.formatUTCDate(new Date(d.date))
    }));
    const dateObj = awareData.reduce(
      (acc, b) => (
        (acc[b.date] = acc[b.date] ? acc[b.date] + b.count : b.count), acc
      ),
      {}
    );
    const dateData = [];
    for (
      let dt = new Date(awareData[0].date);
      dt <= new Date(awareData[awareData.length - 1].date);
      dt.setDate(dt.getDate() + 1)
    ) {
      const fd = this.formatDate(dt);
      const dateExists = Object.prototype.hasOwnProperty.call(dateObj, fd);
      if (dateExists) {
        dateData.push({ date: fd, count: dateObj[fd] });
      } else {
        dateData.push({ date: fd, count: 0 });
      }
    }
    return dateData;
  }

  /**
   * Format weekday data to add empty days and day names
   * (request returns days of the week with values greater than 0)
   */
  public parseWeekData(data) {
    const dayNames = {
      1: 'Sunday',
      2: 'Monday',
      3: 'Tuesday',
      4: 'Wednesday',
      5: 'Thursday',
      6: 'Friday',
      7: 'Saturday'
    };
    let weekDays = [];
    for (let i = 1; i < 8; i++) {
      const day = data.filter(d => d.weekday == i);
      if (!day.length) {
        weekDays.push({
          weekday: dayNames[i],
          weekdaynum: i,
          count: 0
        });
      } else {
        weekDays.push({
          weekday: dayNames[i],
          weekdaynum: i,
          count: day[0].count
        });
      }
    }
    return weekDays;
  }

  /**
   * Format hours data to add empty hours filling empty
   * hours and configuring local timezone
   * (request returns only hours with values greater than 0,
   * and the returned hours are UTC+0)
   */
  public parseHourData(data) {
    const hourData = [];
    for (let i = 0; i < 24; i++) {
      const hour = data.filter(d => d.hour === i);
      if (!hour.length) {
        hourData.push({ hour: i, count: 0 });
      } else {
        hourData.push(hour[0]);
      }
    }
    return hourData;
  }

  /**
   * Format processing time data
   * Ungroup data and remove null items
   */
  public parseProcessData(data) {
    let processData = [];
    data
      .filter(d => d.processtime)
      .forEach(d => {
        for (let i = 0; i < d.count; i++) {
          processData.push(d);
        }
      });
    return processData;
  }

  /**
   * Input date filter handler
   * Called when general datepicker changed
   */
  public onInputDatesSelect() {
    // Transform Moment.js date to regular date object
    this.start_date = new Date(this.start_date);
    this.end_date = new Date(this.end_date);

    // Update chart and trigger update pipe
    this.datesbarchart.updateDates(this.start_date, this.end_date);
  }

  /**
   * Date filter handler
   * Called when selection is applied on dates chart (brushed barchart)
   */
  public onDatesSelect(dates) {
    if (!dates) {
      // Reset date filters
      this.start_date = this.first_date;
      this.end_date = this.last_date;
    } else {
      // Set date filters
      this.start_date = dates[0];
      this.end_date = dates[1];
    }

    // Reset filters
    this.projectid = undefined;
    this.datamodelid = undefined;
    this.weekday = undefined;
    this.hour = undefined;
    this.processtime = null;
    this.status = undefined;

    // Request data
    this.getClientKPIs();
    this.getDocumentsByDatamodel();
    this.getDocumentsByProject();
    this.getWeekDayData();
    this.getHourData();
    this.getProcessTimeData();
    this.getStatusData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Project filter handler
   * Called when click on project chart (barchart)
   */
  public onProjectSelect(projectObj) {
    // Set project filter
    this.projectid =
      this.projectid != projectObj.projectid ? projectObj.projectid : '';

    // Reset filters
    this.datamodelid = undefined;
    this.weekday = undefined;
    this.hour = undefined;
    this.processtime = null;
    this.status = undefined;

    // Request data
    this.getDocumentsByDatamodel();
    this.getWeekDayData();
    this.getHourData();
    this.getProcessTimeData();
    this.getStatusData();
    this.getFlowData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Datamodel filter handler
   * Called when click on datamodel chart (barchart)
   */
  public onDatamodelSelect(datamodelObj) {
    // Set datamodel filter
    this.datamodelid =
      this.datamodelid != datamodelObj.datamodelid
        ? datamodelObj.datamodelid
        : '';

    // Reset filters
    this.weekday = undefined;
    this.hour = undefined;
    this.processtime = null;
    this.status = undefined;

    // Request data
    this.getWeekDayData();
    this.getHourData();
    this.getProcessTimeData();
    this.getStatusData();
    this.getFlowData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Weekday filter handler
   * Called when click on weekday chart (barchart)
   */
  public onWeekDaySelect(data) {
    this.weekday =
      this.weekday != data.weekdaynum ? data.weekdaynum : undefined;

    // Reset filters
    this.hour = undefined;
    this.processtime = null;
    this.status = undefined;

    // Request data
    this.getHourData();
    this.getProcessTimeData();
    this.getStatusData();
    this.getFlowData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Hour filter handler
   * Called when click on hour chart (barchart)
   */
  public onHourSelect(data) {
    this.hour = this.hour != data.hour ? data.hour : undefined;

    // Reset filters
    this.processtime = null;
    this.status = undefined;

    // Request data
    this.getProcessTimeData();
    this.getStatusData();
    this.getFlowData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Process time filter handler
   * Called when selection is applied on process time chart (histogram)
   */
  public onProcessTimeSelect(data) {
    this.processtime = data ? data : null;

    // Reset filters
    this.status = undefined;

    // Request data
    this.getStatusData();
    this.getFlowData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Status filter handler
   * Called when slice is clicked on status chart (piechart)
   */
  public onStatusSelect(data) {
    this.status = this.status != data.statusid ? data.statusid : null;

    // Request data
    this.getFlowData();
    this.resetPagination();
    this.getDocuments();
  }

  /**
   * Toggle expanded row in table (only showed on mobile sizes)
   */
  private showTableElement(el) {
    el.respontableIsShowed = !el.respontableIsShowed;
  }

  /**
   * Reset pagination
   */
  public resetPagination() {
    if (this.pagination) {
      this.pagination.page = 1;
    }
  }

  /**
   * Handle navigation page change
   */
  public onPageChange(page) {
    this.documentsLoading = true;
    this.pagination = this.paginationService.changePagination(
      'documents',
      'page',
      page
    );
    this.getDocuments();
  }

  /**
   * Return CSS Class for Status Colors
   */
  public getStatusClass(classname) {
    const tooltip = 'has-tooltip ';
    if (classname.toLowerCase().includes('failure')) {
      return tooltip + 'text-status--failure';
    } else if (classname.toLowerCase().includes('warning')) {
      return tooltip + 'text-status--warning';
    } else if (classname.toLowerCase().includes('failure')) {
      return tooltip + 'text-status--failure';
    } else if (classname.toLowerCase().includes('success')) {
      return 'text-status--success';
    } else if (classname.toLowerCase().includes('in-progress')) {
      return 'text-status--in-progress';
    } else if (classname.toLowerCase().includes('pending')) {
      return 'text-status--pending';
    } else if (classname.toLowerCase().includes('partial-success')) {
      return 'text-status--partial-success';
    }
  }

  public translateSankeyNodes(d) {
    return d.depth === 2
      ? this.translate.transform(`errors.${d.name}`)
      : d.name;
  }

  /*
   * This function gets the icon to be displayed for a document status
   */
  public getIcon(status: string) {
    const replacedStatus = status.replace(/\s+/g, '-').toLowerCase();
    if (
      [
        this._documentStatus.MANUAL_SUCCESS,
        this._documentStatus.SUCCESS,
        this._documentStatus.PARTIAL
      ].includes(replacedStatus)
    ) {
      return 'check';
    } else if (
      [
        this._documentStatus.FAILURE,
        this._documentStatus.EXTRACTION_FAILURE,
        this._documentStatus.BR_FAILURE,
        this._documentStatus.PRELIMINARY_FAILURE,
        this._documentStatus.CATALOG_FAILURE
      ].includes(replacedStatus)
    ) {
      return 'clear';
    } else if (replacedStatus === this._documentStatus.WARNING) {
      return 'warning';
    } else if (
      [
        this._documentStatus.IN_PROGRESS,
        this._documentStatus.EXTRACTION_IN_PROGRESS,
        this._documentStatus.BR_IN_PROGRESS,
        this._documentStatus.PRELIMINARY_IN_PROGRESS,
        this._documentStatus.CATALOG_IN_PROGRESS
      ].includes(replacedStatus)
    ) {
      return 'sync';
    } else if (
      [
        this._documentStatus.SKIPPED,
        this._documentStatus.PENDING,
        this._documentStatus.EXTRACTION_PENDING,
        this._documentStatus.BR_PENDING,
        this._documentStatus.PRELIMINARY_PENDING,
        this._documentStatus.CATALOG_PENDING
      ].includes(replacedStatus)
    ) {
      return 'adjust';
    }
  }

  public cleanIndividualFilter(filter: string){
    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    
    if(filter === 'datamodelname'){
      filter = 'datamodelid'
    }

    if(filter === 'status_name'){
      filter = 'statusid'
    }
    
    this.filterEvent.next(filter)
    this.filters[filter] = '';

    if(filter === 'documentdisplayname'){
      this.filters['documentdisplayname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'documentdisplayname_logic');
    }
    
    this.documentsLoading = true;
    this.getDocuments();
  }

    /**
   * Return loaded columns with the information of each column of
   * the table we want to show
   */
    public getColumns() {
      this.columns = [
        {
          header: 'dashboard.documentdisplayname',
          name: 'documentdisplayname',
          type: 'default',
          title: 'documentdisplayname',
        },
        {
          header: 'dashboard.datamodel',
          type: 'default',
          name: 'datamodel',
          second: 'datamodeldisplayname',
          title: 'datamodel',
        },
        {
          header: 'dashboard.date',
          name: 'createddate',
          type: 'date',
          format: 'lll',
          tittle: 'date'
        },
        {
          header: 'dashboard.status',
          name: 'status',
          type: 'one-span-status',
          tittle: 'status'
        }
      ];
    }

  /**
   * Handle loading of the table from the child
   */
  public onLoadingChange(bool) {
    this.documentsLoading = bool;
  }

  public getSettingTable() {
    this.settingTable =
    {
      'dataId': 'documentid',
      'hasSelect': 'false',
      'getDataFromDB': this.getDocuments.bind(this),
      'responsiveTitle': {
        'label': 'documentdisplayname',
        'value': 'documentdisplayname',
      },
      'paginationClass': 'relative-pag'
    }
  }
}
