import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Reason } from '../../../models/close-reason.model';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _rolesConst from '../../../constants/userRoles';
import { GlobalService } from '../../../services/global.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { EditRoleModalComponent } from '../edit-view-rol/edit-view-role.component';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  @Input() options;
  public reason: Reason;
  public body: string;
  public option: string;
  public action: string;
  public roleDescription: string;
  public userForm: FormGroup;
  public reviewers: any[] = this.globalService.getReviewers();
  public roleList: any[] = this.globalService.getRoles();
  public _userPermissions: any;
  public user: any;
  public showValidationsLink: boolean = false;

  public isEyAdmin: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private translate: TranslatePipe,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef
  ) {
    this._userPermissions = this.globalService.getUserPermissionsConst();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  /**
   * Get reviewers
   */
  public getReviewers() {
    return this.reviewers;
  }

  _setPermissionVal(editValue, deleteValue) {
    if (editValue && deleteValue) {
      return '2';
    }
    if (editValue) {
      return '3';
    }
    if (deleteValue) {
      return '4';
    }
    return '1';
  }

  ngOnInit() {
    this.user = this.authService.getLoggedInUser();
    this.isEyAdmin = this.user.iseyadmin;
    this.showValidationsLink = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this.userForm = new FormGroup({
      userid: new FormControl(this.options.user.userid),
      username: new FormControl(this.options.user.username, [
        Validators.required,
        Validators.maxLength(100)
      ]),
      email: new FormControl(this.options.user.email, [
        Validators.required,
        Validators.maxLength(100),
        //Validators.email
      ]),
      roleid: new FormControl(
        this.options.user.role.roleid,
        Validators.required
      ),
      isadmin: new FormControl(
        this.options.user.role.rolename === _rolesConst.ADMINISTRATOR
      ),
      userreviewer: new FormArray([])
    });

    this.reviewers.forEach((o, i) => {
      let state =
        this.userForm.get('roleid').value === _rolesConst.ID_ADMINISTRATOR;
      if (!state && this.options.user.userreviewer) {
        state =
          this.options.user.userreviewer.filter(
            x => x['groupid'] === o['groupid']
          ).length > 0;
      }
      const control = new FormControl(state);
      (this.userForm.controls.userreviewer as FormArray).push(control);
    });

    this.setDisableControls(
      this.userForm.get('roleid').value === _rolesConst.ID_ADMINISTRATOR
    );
    this.option = 'components.modal.user.' + this.options.type;
    this.action = 'components.modal.user.' + this.options.action;
  }

  onsubmit() {
    const user = this.userForm.getRawValue();
    for (const key in user) {
      if (user[key] === true || user[key] === 'true') {
        user[key] = 1;
      } else if (user[key] === false || user[key] === 'false') {
        user[key] = 0;
      }
    }
    if (user['roleid'] === _rolesConst.ID_ADMINISTRATOR) {
      user['userreviewer'] = [];
    } else {
      const final = [];
      this.reviewers.forEach((o, i) => {
        if (user['userreviewer'][i]) {
          final.push(o['groupid']);
        }
      });
      user['userreviewer'] = final;
    }
    user['privacyNoteAccepted'] = this.options.user.privacyNoteAccepted;
    this.activeModal.close({
      user,
      action: this.action
    });
  }

  change_isadmin() {
    this._setFormValues(
      this.userForm.get('roleid').value === _rolesConst.ID_ADMINISTRATOR
    );
  }

  private _set_reviewers(val) {
    const vals = [];
    for (let i = 0; i < this.reviewers.length; i++) {
      vals.push(val);
    }
    this.userForm.controls.userreviewer.setValue(vals);
  }

  private _setFormValues(val) {
    this.userForm.get('isadmin').setValue(val);
    this.userForm
      .get('roleid')
      .setValue(val ? 1 : this.userForm.get('roleid').value);
    this.setDisableControls(val);
    if (val) {
      this.userForm.get('isadmin').setValue(val);
      this.userForm
        .get('roleid')
        .setValue(val ? 1 : this.userForm.get('roleid').value);
      this._set_reviewers(val);
    }
  }

  private setDisableControls(val) {
    if (val) {
      this.userForm.controls.userreviewer.disable();
    } else {
      this.userForm.controls.userreviewer.enable();
    }
  }

  public getRoleTranslation(roleid) {
    let rolename = '';
    this.roleList.forEach(role => {
      if (role.roleid == roleid) {
        rolename = this.translate.transform(
          `components.modal.user.roleList.${role.rolename}`
        );
      }
    });
    return rolename;
  }

  public getRolenameDB(roleid) {
    let rolename = '';
    this.roleList.forEach(role => {
      if (role.roleid == roleid) {
        rolename = role.rolename;
      }
    });
    return rolename;
  }

  public getPermissionsRoles(rolename) {
    const roleList = this.globalService.getRoles();
    const permissionsList = this.globalService.getPermissions();

    roleList.forEach(role => {
      if (role.rolename == rolename) {
        if (role.rolename == _rolesConst.ADMINISTRATOR) {
          permissionsList.forEach(permissionAll => {
            permissionAll['hasPermission'] = true;
          });
        } else {
          permissionsList.forEach(permissionAll => {
            permissionAll['hasPermission'] = false;
            role.permissions.forEach(permission => {
              if (permission['permissionid'] == permissionAll.permissionid) {
                permissionAll['hasPermission'] = true;
              }
            });
          });
        }
      }
    });
    return permissionsList;
  }

  /**
   * Load modal to show permissions
   */
  public onViewPermissions(rolename) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options: any = {};
    options.type = 'permissions';
    options.action = 'edit';
    options.data = {
      permissions: this.getPermissionsRoles(rolename),
      rolename: rolename
    };

    const modalWindowRef = this.modalService.open(
      EditRoleModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(role => {});
  }
}
