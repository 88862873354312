import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  FormGroupName
} from '@angular/forms';
import { ValidationService } from '../common-components/validation.service';
import { ExtractionService } from '../extraction-components/extraction.service';
import { BusinessService } from '../business-components/business.service';
import { PreliminaryService } from '../preliminary-components/preliminary.service';
import { CatalogService } from '../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-case-body',
  templateUrl: './case-body.component.html',
  styleUrls: ['./case-body.component.scss']
})
export class CaseBodyComponent implements OnInit {
  @Input('case') public case: FormGroup;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  @Input('datamodels') datamodels: Array<any>;
  @Input('casesIndex') public casesIndex: number;
  @Input('conditionListIndex') public conditionListIndex: number;
  @Input('casesCtrl') public casesCtrl: FormGroup;

  public expandedConditions = [];

  public isCollapsed: boolean = true;

  constructor(
    public validationService: ValidationService,
    public extractionService: ExtractionService,
    public businessService: BusinessService,
    public preliminaryService: PreliminaryService,
    public catalogService: CatalogService,
    public globalService: GlobalService,
    private translate: TranslatePipe
  ) {}

  getConditionListArray(): FormArray {
    return this.case.get('condition_list') as FormArray;
  }
  
  getConditionAt(index: number): FormGroup {
    const conditionListArray = this.getConditionListArray();
    return conditionListArray.at(index) as FormGroup;
  }

  ngOnInit() {
    const condition_list = this.case['controls']['condition_list'] as FormArray;

    setTimeout(() => {
      for (let i = 0; i < condition_list.length; i++) {
        const control = this.case['controls']['condition_list']['controls'][i];
        this.toggleExpandedCondition(i);
        this.onChangeSingleMultiple(i);
        this.touchRequiredFields(control);
        control.get('conditions').valueChanges.subscribe((changedObj: any) => {
          this.changeSingleMultipleDisabledStatus(i);
        });
        this.changeSingleMultipleDisabledStatus(i);
      }
    }, 100);
  }

  /**
   * Description: Add condition to condition list
   * @param i condition list index to add condition
   */
  public addCondition(i: number) {
    const condition_list_array = this.case.get('condition_list') as FormArray;
    const condition_list = condition_list_array.controls[i] as FormGroup;
    const conditions = condition_list.get('conditions') as FormArray;
    const isMultiple = condition_list.get('simple_multiple').value;
    let new_condition: FormGroup;
    new_condition = this.getCondition(isMultiple);
    this.touchRequiredFields(new_condition);
    conditions.push(new_condition);
    this.changeSingleMultipleDisabledStatus(i);
  }

  /**
   * Description: Add condition to condition list
   * @param i condition list index to add condition
   */
  public addConditionListPreCondition(i: number) {
    const condition_list = this.case.get('condition_list') as FormArray;
    const preconditions_list = condition_list.controls[i] as FormGroup;
    const conditions = preconditions_list.get('preconditions') as FormArray;
    let new_precondition: FormGroup;
    new_precondition = this.getPreCondition();
    conditions.push(new_precondition);
  }

  /**
   * Add or remove control error base on type
   * @param index formgroup index to add new control
   */
  public onChangeSingleMultiple(index: number) {
    const control = this.case.get('condition_list')['controls'][index];
    const simple_multiple = control.get('simple_multiple').value;
    const isMultiple =
      simple_multiple === true || simple_multiple === 'multiple';

    if (this.validationService.isType(['Business Rules', 'Catalog'])) {
      if (!isMultiple) {
        if (!control.get('error')) {
          control.addControl('error', new FormControl('', Validators.required));
          this.validationService.touchControl(control, 'error');
        }
      } else if (control.get('error')) {
        control.removeControl('error', null);
      }
    }
  }

  /**
   * Touch required fields, to show them as invalid if are empty
   * @param control parent control
   */
  public touchRequiredFields(control) {
    this.validationService.touchControl(control, 'condition_type');
    this.validationService.touchControl(control, 'operationScope');
    this.validationService.touchControl(control, 'rounding');
  }

  /**
   * Add control error if type is single
   * @param condition condition value
   */
  public getConditionName(condition) {
    return condition
      ? this.translate.transform(`validation.${condition}Condition`)
      : this.translate.transform('validation.newConditionList');
  }

  /**
   * Toggle condition visibility
   * @param index condition index
   */
  public toggleExpandedCondition(index: number) {
    const index_element = this.expandedConditions.indexOf(index);
    if (index_element >= 0) {
      this.expandedConditions.splice(index_element, 1);
    } else {
      this.expandedConditions.push(index);
    }
  }

  /**
   * Check if condition should be visible
   * @param index condition index
   */
  public isExpandedCondition(index: number) {
    return this.expandedConditions.indexOf(index) !== -1;
  }

  /**
   * Check if simple-multiple switch must be disabled
   * @param caseCtrl parent FormGroup
   */
  private changeSingleMultipleDisabledStatus(i: number) {
    if (
      this.validationService.isType(['Preliminary', 'Extraction']) ||
      this.case.get('condition_list')['controls'][i].get('conditions')[
        'controls'
      ].length > 0
    ) {
      this.case
        .get('condition_list')
        ['controls'][i].get('simple_multiple')
        .disable();
    } else {
      this.case
        .get('condition_list')
        ['controls'][i].get('simple_multiple')
        .enable();
    }
  }

  /**
   * Description: gets fields of interest of a datamodel
   * @param i condition list index to add condition
   */
  getFieldsOfInterestOrderDocument(i: number) {
    const condition_list_array = this.case.controls.condition_list as FormArray;
    const condition_list = condition_list_array.controls[i] as FormGroup;
    const datamodelid = condition_list.get('datamodel_order_document').value;
    if (datamodelid) {
      const dataDataModel = this.globalService.getDataModelById(+datamodelid);
      return dataDataModel ? dataDataModel.groupoffields : [];
    } else {
      return [];
    }
  }

  /**
   * Check is error form control must be active
   * @param control parent FormGroup
   */
  public isErrorControlActive(control) {
    // TODO: Remove string comparison once all
    // stored validations has simple_multiple field as boolean
    return (
      this.validationService.isType(['Business Rules', 'Catalog']) &&
      control.get('error') &&
      (control.get('simple_multiple').value === false ||
        control.get('simple_multiple').value === 'single')
    );
  }

  /**
   * Get errors for condition list
   * @param control parent FormGroup
   */
  public getConditionListsErrors(control) {
    if (this.validationService.controlHasError(control, 'condition_type')) {
      return this.validationService.getControlError('condition_type');
    }
    if (control.get('conditions')['controls'].length < 1) {
      return this.validationService.getControlError('atLeastOneCondition');
    }
    if (this.validationService.controlHasError(control, 'conditions')) {
      return this.validationService.getControlError('someConditionIsInvalid');
    }
    if (this.validationService.controlHasError(control, 'error')) {
      return this.validationService.getControlError('error');
    }
    return false;
  }

  /**
   * Clean ammendment-related form controls
   * @param control parent FormGroup
   */
  private cleanAmmendment(control) {
    control.get('datamodel_order_document').setValue('');
    control.get('field_order_document').setValue('');
    control.get('order_document').setValue('');
  }

  private getCondition(isMultiple: boolean) {
    if (!isMultiple) {
      switch (this.validationService.getValidationTypeName()) {
        case 'Business Rules':
          return this.businessService.getCaseCondition();
        case 'Preliminary':
          return this.preliminaryService.getCaseCondition();
        case 'Catalog':
          return this.catalogService.getCaseCondition();
        case 'Extraction':
          return this.extractionService.getCaseCondition();
        default:
          break;
      }
    } else {
      return this.validationService.getMultipleCondition();
    }
  }

  public getPreCondition() {
    if (this.validationService.getValidationTypeName() === 'Business Rules') {
      return this.businessService.getPreCondition();
    } else {
      return this.catalogService.getPreCondition();
    }
  }

  public changePreConditionCheckbox(conditionListIndex: number, event: Event) {
    let el = event.target as HTMLInputElement;
    let flag = el.checked;
    flag
      ? this.addConditionListPreCondition(conditionListIndex)
      : this.removeConditionListPreConditions(conditionListIndex);
  }

  public removeConditionListPreConditions(i: number) {
    const condition_list = this.case.controls['condition_list'] as FormArray;
    const preconditions_list = condition_list.controls[i] as FormGroup;
    const preconditions = preconditions_list.controls
      .preconditions as FormArray;
    if (preconditions !== undefined) {
      preconditions.clear();
    }
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
