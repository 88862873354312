import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { TrainableService } from '../../trainable.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-table',
  templateUrl: './trainable-table.component.html',
  styleUrls: ['./trainable-table.component.scss']
})
export class TrainableTableComponent implements OnInit {
  @Input() public table: FormArray;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  private isOpen: boolean = false

  constructor(
    private translate: TranslatePipe,
    public trainableService: TrainableService
  ) {}

  ngOnInit() {
  }

  public addField() {
    this.trainableService.addField(this.table, this.trainableService.GOFTYPESIDS.TABLE);
  }

  public addHeader(form, validator?) {
    this.trainableService.addNewInputNameControl(form, validator);
  }

  public removeHeader(form, index) {
    this.trainableService.removeInputControl(form, index);
  }

  public getFieldName(value) {
    return value ? value : this.translate.transform('trainable.table.newField');
  }
}
