import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Reason } from '../../../models/close-reason.model';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteModalComponent implements OnInit {
  @Input() options;
  public reason: Reason;
  public body: string;
  public option: string;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.option = 'components.modal.delete.' + this.options.type;
    this.body =
      'components.modal.delete.' +
      (this.options.notAllowed ? 'notAllowed' : 'body');
  }

  onConfirmDelete(reason: Reason) {
    this.activeModal.close(reason);
  }
}
