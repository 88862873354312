import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TrainableService } from '../../trainable.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-entity',
  templateUrl: './trainable-entity.component.html',
  styleUrls: ['./trainable-entity.component.scss']
})
export class TrainableEntityComponent implements OnInit {
  @Input() public entity: FormArray;
  @Input('formSubmitted') public formSubmitted: boolean;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  private isOpen: boolean = false

  public expandedField = [];

  constructor(
    private translate: TranslatePipe,
    private trainableService: TrainableService
  ) {}

  ngOnInit(): void {
    this.toggleExpandedField(0);
  }

  public addField() {
    this.trainableService.addField(this.entity, 4);
  }

  public addKey(form, validator?) {
    this.trainableService.addNewInputNameControl(form, validator);
  }

  public removeKey(form, index) {
    this.trainableService.removeInputControl(form, index);
  }

  /**
   * Toggle field expanded
   * @param index field index number
   */
  private toggleExpandedField(index: number) {
    this.isOpen = !this.isOpen;
    const index_element = this.expandedField.indexOf(index);
    if (index_element >= 0) {
      this.expandedField.splice(index_element, 1);
    } else {
      this.expandedField.push(index);
    }
  }

  /**
   * Check if field is expanded
   * @param index field index number
   */
  private isExpandedField(index: number) {
    return this.expandedField.indexOf(index) !== -1;
  }

  public getFieldName(value) {
    return value
      ? value
      : this.translate.transform('trainable.entity.newField');
  }
}
