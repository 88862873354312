import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  HostListener
} from '@angular/core';
import {
  ApiService
} from 'src/app/services/api.service';
import {
  GlobalService
} from 'src/app/services/global.service';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {
  AuthenticationService
} from 'src/app/security/authentication.service';
import {
  FormGroup
} from '@angular/forms';
import {
  TranslatePipe
} from 'src/app/pipes/translate.pipe';
import {
  Subscription
} from 'rxjs';
import {
  TrainableService
} from './trainable.service';

@Component({
  selector: 'app-trainable',
  templateUrl: './trainable.component.html',
  styleUrls: ['./trainable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainableComponent implements OnInit, OnDestroy {
  formTrainableDM: FormGroup;
  public tenantName: any;
  public isNew: boolean = true;
  public loadingSave: boolean = false;
  public loaded: boolean = false;
  public expandedGOF = [];
  public subscriptionUtils: Subscription;
  public routeSub: Subscription;
  public datamodel: any;
  public languageList;
  public windowScrollTop: number = 0;
  public distanceDocumentRenderToWindowTop: number = 92;
  public _userPermissions: any;
  public showReviewFlow: boolean = false;
  private subscriptionLanguages: Subscription;
  private user: any = {};
  public formSubmitted: boolean = false;
  public isCreating: boolean = true;
  public genericDatamodel: boolean = false;

  public splitterNodeParserTypes = ['SemanticSplitterNodeParser', 'MarkdownNodeParser']
  public bufferSizeElements = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  public breakpointElements = [60, 65, 70, 75, 80, 85, 90, 95]
  public similarityElements = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  public langNameSelected = 'ENG'

  constructor(
    private apiService: ApiService,
    public global: GlobalService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private trainableService: TrainableService,
    private translate: TranslatePipe,
    private globalService: GlobalService
  ) {
    this.tenantName = this.authService.getTenantName();
    this._userPermissions = this.globalService.getUserPermissionsConst();
    this.user = authService.getLoggedInUser();
    this.showReviewFlow = this.authService.userCanViewModule(
      this.user,
      'DueTimeReviewFlowFunctionality'
    );
  }

  ngOnInit() {
    const datamodelMap = this.global.getTrainableDatamodelsMap();
    this.languageList = Array.from(this.global.getLanguages().values());
    if (
      this.languageList.length === 0 &&
      datamodelMap.size === 0 &&
      !this.global.passedWatcherDatamodels &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionUtils = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.languageList = Array.from(this.global.getLanguages().values());
          this.initTrainable();
        });
    } else if (
      this.languageList.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionLanguages = this.global
        .watchUtils()
        .subscribe((data: string) => {
          this.languageList = Array.from(this.global.getLanguages().values());
          this.initTrainable();
        });
    } else {
      this.initTrainable();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }

    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }

    if (this.subscriptionLanguages) {
      this.subscriptionLanguages.unsubscribe();
    }
  }

  private initTrainable() {
    this.routeSub = this.route.queryParamMap.subscribe(params => {
      const datamodelidParam = params.get('datamodelid');
      if (datamodelidParam) {
        this.isCreating = false;
        this.isNew = false;
        this.datamodel = this.global.getDataModelById(+datamodelidParam);
        this.genericDatamodel = Boolean(this.datamodel.datamodeldescription) ? true : false;
        const languages =
          this.datamodel.languages.length != 0 ?
          this.datamodel.languages[0].language.langid :
          null;
        this.changeLang(this.datamodel.languages[0].language.langid)
        this.formTrainableDM = this.trainableService.getFormTrainable(
          this.datamodel.datamodelcontent,
          this.isNew,
          this.datamodel.datamodeldisplayname,
          languages,
          this.datamodel.maxreviewdays,
          this.datamodel.datamodeldescription,
          this.datamodel.processtype,
          this.datamodel.splitternodeparser,
          this.datamodel.buffersize,
          this.datamodel.breakpointpercentilethreshold,
          this.datamodel.similaritytopk
        );
      } else {
        this.formTrainableDM = this.trainableService.getFormTrainable({},
          this.isNew
        );
      }
      this.loaded = true;
    });
  }

  public addGroupOfField() {
    this.trainableService.addGroupOfField(this.formTrainableDM);
  }

  /**
   * Save current trainable datamodel
   */
  private saveDatamodel() {
    this.loaded = false;
    this.formSubmitted = true;
    const postMessage = this.isNew ?
      this.translate.transform('trainable.created') :
      this.translate.transform('trainable.updated');
    if (this.formTrainableDM.valid) {
      this.loadingSave = true;

      if (this.isNew) {
        this.apiService
          .post(
            'datamodels',
            this.formTrainableDM.getRawValue(),
            postMessage,
            true, {},
            true
          )
          .subscribe(
            newData => {
              this.global.addDatamodel(newData, this.isNew);
              this.loadingSave = false;
              this.router.navigate([`${this.tenantName}/datamodels-validations`]);
            },
            () => {
              this.loadingSave = false;
            }
          );
      } else {
        this.apiService
          .put(
            'datamodels/',
            this.datamodel.datamodelid,
            this.formTrainableDM.getRawValue(),
            postMessage,
            true, {},
            true
          )
          .subscribe(
            newDatamodel => {
              this.global.addDatamodel(newDatamodel, this.isNew);
              this.loadingSave = false;
              this.router.navigate([`${this.tenantName}/datamodels-validations`]);
            },
            () => {
              this.loadingSave = false;
            }
          );
      }

    } else {
      this.formTrainableDM.markAllAsTouched()
    } 
  }

  public isDocumentRenderFixed() {
    return this.windowScrollTop > this.distanceDocumentRenderToWindowTop;
  }

  /**
   * Listen for window scroll event
   */
  @HostListener('window:scroll', ['$event'])
  public onScroll(event) {
    this.windowScrollTop = window.pageYOffset;
  }

  public changeLang(langId) {
    this.langNameSelected = this.languageList.filter(lang => lang.langid == langId)[0].langname 
  }
}
