import { Component, OnInit, Input } from '@angular/core';
import { ValidationService } from '../validation.service';
import { FormArray, FormControl, Validators, FormGroup } from '@angular/forms';
import { PreliminaryService } from '../../preliminary-components/preliminary.service';

@Component({
  selector: 'app-datamodel-list',
  templateUrl: './datamodel-list.component.html',
  styleUrls: ['./datamodel-list.component.scss']
})
export class DatamodelListComponent implements OnInit {
  @Input('formTest') formTest;
  @Input('datamodels') datamodels;

  public datamodelsList: Array<any> = [];
  public radioValue: number;

  constructor(
    public validationService: ValidationService,
    public preliminaryService: PreliminaryService
  ) {}

  ngOnInit() {
    // destructure datamodels
    this.datamodelsList = this.datamodels.map(d => ({
      id: d.datamodelid,
      name: d.datamodeldisplayname
    }));

    // this.formTest.get('cases').valueChanges.subscribe((changedObj: any) => {
    //   this.configureDatamodelInputsActiveStatus();
    // })
    this.configureDatamodelInputsActiveStatus();

    const datamodel_list = this.formTest.get('datamodel_list') as FormArray;
    datamodel_list['controls'].forEach((control, i) => {
      control.get('id').valueChanges.subscribe(value => {
        this.addDatamodelToService(value, i);
        if (control.value['id'] && value && control.value['id'] !== value) {
          this.preliminaryService.deletePreliminaryDatamodelById(
            +control.value['id']
          );
          this.cleanDatamodelFromFormAfterChanged(control.value['id']);
        }
      });
    });

    for (
      let i = 0;
      i < this.formTest.get('datamodel_list')['controls'].length;
      i++
    ) {
      if (
        this.formTest.get('datamodel_list')['controls'][i].value
          .datamodeltrigger
      ) {
        this.radioValue = i;
        break;
      }
    }
    this.radioValue = this.radioValue === undefined ? 0 : this.radioValue;
  }

  private configureDatamodelInputsActiveStatus() {
    const datamodel_list = this.formTest.get('datamodel_list') as FormArray;
    datamodel_list['controls'].forEach((control, i) => {
      if (this.validationService.isType(['Preliminary'])) {
        if (this.preliminaryService.canEditDatamodel(i, this.formTest)) {
          control.get('id').enable();
        } else {
          control.get('id').disable();
        }
      } else {
        control.get('datamodeltrigger').disable();
        control.get('id').disable();
      }
    });
  }

  /**
   * Adds datamodel to available datamodels for preliminary validation
   * unique key
   */
  public addToDatamodelList() {
    const datamodel_list = this.formTest.get('datamodel_list') as FormArray;
    datamodel_list.push(
      new FormGroup({
        id: new FormControl('', Validators.required),
        datamodeltrigger: new FormControl(false)
      })
    );
    this.preliminaryService.addEmptyPreliminaryDatamodel();
    // this.configureDatamodelInputsActiveStatus();
  }

  /**
   * Check if datamodel is currently selected in other datamodel select
   * @param datamodelId datamodel ID to check
   * @returns boolean whether is selected or not
   */
  private alreadyExistOnDatamodelList(datamodelId: number) {
    const datamodel_list = this.formTest.get('datamodel_list') as FormArray;
    return datamodel_list['controls']
      .map(ctrl => ctrl['controls']['id']['value'])
      .some(id => id === datamodelId);
  }

  /**
   * Delete datamodel id from the list of available datmodels for
   * preliminary validation
   * @param index form control index
   */
  private deleteFromDatamodelList(index: number, datamodelId: number) {
    if (
      this.formTest
        .get('datamodel_list')
        ['controls'][index].get('datamodeltrigger').value
    ) {
      this.removeDatamodelPreConditions();
    }
    this.preliminaryService.deletePreliminaryDatamodelById(datamodelId);
    this.formTest.get('datamodel_list').removeAt(index);
    if (datamodelId > 0) {
      this.cleanDatamodelFromFormAfterChanged(datamodelId);
    }
  }

  /**
   * Adds new datamodel to list of datamodels
   * @param newDatamodelId datamodel id to add
   * @param indexDatamodel index of old datamodel in the array
   */
  private addDatamodelToService(newDatamodelId, indexDatamodel) {
    const datamodelObj = this.datamodels.find(
      datamodel => datamodel.datamodelid === +newDatamodelId
    );
    this.preliminaryService.modifyPreliminaryDatamodel(
      datamodelObj,
      indexDatamodel
    );
  }

  /**
   * Check if datamodel input can be deleted
   * @param index datamodel input index
   */
  public canDeleteDatamodel(index: number) {
    return (
      index > 1 &&
      this.formTest.get('datamodel_list')['controls'][index].get('id').enabled
    );
  }

  /**
   * Check if control exists and has the given value
   * @param parentControl parent form group
   * @param controlName control name
   * @param value value to compare
   */
  public controlHasValue(parentControl, controlName, value) {
    return (
      parentControl.get(controlName) &&
      +parentControl.get(controlName).value === value
    );
  }

  /**
   * Unset form inputs (including conditions, etc) that are using
   * the given datamodel. Ex: The datamodel is removed, so the dependant
   * fieldofinterest controls must be setted to empty
   * @param datamodelid datamodelid that must be removed
   */
  public cleanDatamodelFromFormAfterChanged(datamodelid) {
    const cases = this.formTest.get('cases') as FormArray;
    for (let i = 0; i < cases.length; i++) {
      const conditionLists = cases['controls'][i].get(
        'condition_list'
      ) as FormArray;
      for (let j = 0; j < conditionLists.length; j++) {
        // Empty ammendment values if are using the datamodelid
        if (
          this.controlHasValue(
            conditionLists['controls'][i],
            'datamodel_order_document',
            datamodelid
          )
        ) {
          conditionLists['controls'][i]
            .get('datamodel_order_document')
            .setValue('');
          conditionLists['controls'][i]
            .get('field_order_document')
            .setValue('');
          conditionLists['controls'][i].get('order_document').setValue('');
        }

        const conditions = conditionLists['controls'][i].get(
          'conditions'
        ) as FormArray;
        if (conditions) {
          for (let k = 0; k < conditions.length; k++) {
            // Empty condition values if are using the datamodelid
            const operations = conditions['controls'][i].get('operations');

            if (+operations.get('0').get('datamodelid').value === datamodelid) {
              operations.get('0').get('fieldofinterestid').setValue('');
              operations.get('0').get('fieldofinterestid').markAsTouched();
              operations.get('0').get('groupoffieldsid').setValue('');
              operations.get('0').get('datamodelid').setValue('');
              operations.get('0').get('datamodelid').markAsTouched();
            }
            if (+operations.get('2').get('datamodelid').value === datamodelid) {
              operations.get('2').get('fieldofinterestid').setValue('');
              operations.get('2').get('fieldofinterestid').markAsTouched();
              operations.get('2').get('groupoffieldsid').setValue('');
              operations.get('2').get('datamodelid').setValue('');
              operations.get('2').get('datamodelid').markAsTouched();
            }
          }
        }
      }
    }
  }

  /**
   * Check if datamodels have been changed (only on preliminary)
   * If any of the old stored datamodels isn't included, catalog will
   * be removed. If there are new datamodels or the triggers have changed,
   * the catalog will be modified (duplicate the last one with the current
   * datamodels values)
   */
  public datamodelsHaveChanged() {
    if (
      !this.validationService.isType(['Preliminary']) ||
      !this.formTest.get('is_edition') ||
      !this.formTest.get('is_edition').value
    ) {
      return false;
    }
    const current = this.formTest.get('datamodel_list').value;
    const currentIds = current.map(d => d.id);
    const original = this.formTest.get('datamodel_list_original').value;

    // Check if all original datamodels are included in current datamodels
    if (!original.every(d => currentIds.indexOf(d.id) > -1)) {
      return 'destroy';
    }

    // Check if are new datamodels or triggers are the same
    if (
      original.length !== current.length ||
      !original.every(
        d =>
          current[currentIds.indexOf(d.id)].datamodeltrigger ===
          d.datamodeltrigger
      )
    ) {
      return 'renew';
    }
    return false;
  }

  public changeDatamodelRadio(flag: boolean) {
    this.formTest.get('datamodel_list')['controls'].forEach((datamodel, i) => {
      datamodel.get('datamodeltrigger').setValue(i === flag);
    });
    if (this.formTest.get('precondition').value) {
      this.formTest.get('precondition').setValue(false);
    }
  }

  public changeDatamodelId(newId) {
    if (this.radioValue !== undefined) {
      const previousDatamodel = this.formTest
        .get('datamodel_list')
        .value.filter(datamodel => datamodel.datamodeltrigger === true)[0];
      if (previousDatamodel && previousDatamodel.id === newId) {
        this.removeDatamodelPreConditions();
      }
      for (
        let index = 0;
        index < this.formTest.get('datamodel_list')['controls'].length;
        index++
      ) {
        this.formTest
          .get('datamodel_list')
          ['controls'][index].get('datamodeltrigger')
          .setValue(index === this.radioValue);
        this.preliminaryService.setPreliminaryDatamodels(
          this.datamodelsArray(this.formTest.get('datamodel_list').value)
        );
      }
    }
  }

  private datamodelsArray(datamodels) {
    let newDatamodels = [];
    for (let index = 0; index < datamodels.length; index++) {
      newDatamodels.push({
        datamodelid: datamodels[index]['id'],
        datamodeldisplayname: this.getDatamodelName(datamodels[index]['id'])
      });
    }
    return newDatamodels;
  }

  public getDatamodelName(id: number) {
    return this.datamodelsList.filter(datamodel => datamodel.id === id)[0].name;
  }

  public showPreliminaryPreconditions() {
    const filter = [];
    this.formTest.get('datamodel_list')['controls'].filter(datamodel => {
      if (datamodel.value.datamodeltrigger && datamodel.value.id !== '') {
        filter.push(datamodel);
      }
    });
    return filter.length > 0;
  }

  public changeDatamodelPreConditionCheckbox(event: Event) {
    let el = event.target as HTMLInputElement;
    let flag = el.checked;
    flag
      ? this.addDatamodelPreConditionPreCondition()
      : this.removeDatamodelPreConditions();
  }

  /**
   * Description: Adds new Pre Condition to Case i
   * @param caseIndex index of Case
   * @returns void
   */
  public addDatamodelPreConditionPreCondition() {
    const preconditions = this.formTest.get('preconditions') as FormArray;
    preconditions.push(this.preliminaryService.getPreCondition());
  }

  public removeDatamodelPreConditions() {
    const preconditions = this.formTest.get('preconditions') as FormArray;
    preconditions.clear();
  }
}
