import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ValidationService } from '../../common-components/validation.service';
import { BusinessService } from './../../business-components/business.service';

@Component({
  selector: 'app-condition',
  templateUrl: './condition.component.html',
  styleUrls: ['./condition.component.scss']
})
export class ConditionComponent implements OnInit {
  @Input('conditionlist') public conditionlist: FormGroup;
  @Input('conditionListIndex') public conditionListIndex: number;
  @Input('casesIndex') public casesIndex: number;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  public expandedConditions: Array<number> = [];

  constructor(
    public validationService: ValidationService,
    public businessService: BusinessService
  ) {}

  ngOnInit() {

  }

}
