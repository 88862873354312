import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { DocumentRenderService } from 'src/app/components/document-render/document-render.service';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ValidationExecutionModel } from 'src/app/models/validation-execution.model';
import { concat, Subscription } from 'rxjs';
import { ValidationStatusModel } from 'src/app/models/validation-status.model';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BulkEditDocumentFieldsComponent } from './bulk-edit-fields/bulk-edit-fields.component';
import { DeleteModalComponent } from 'src/app/components/modals/delete/delete.component';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';
import { locations } from 'src/app/constants/locationConstants';
import * as moment from 'moment';

@Component({
  selector: 'app-document-fields-list',
  templateUrl: './document-fields-list.component.html',
  styleUrls: ['./document-fields-list.component.scss'],
  providers: []
})
export class DocumentFieldsListComponent implements OnInit, OnDestroy {
  @Input() documentDetails: any = {};
  @Input() validation: any;
  @Input() isExternal: boolean;
  @Output() updateTimeline = new EventEmitter();

  public user: any = {};
  public showValidations: boolean = false;
  public validationExecution: ValidationExecutionModel;
  public validationsStatus: ValidationStatusModel[];
  public _validationStatus: any;
  public _validationTypes: any;
  public _userPermissions: any;
  private subscriptionDataModels: Subscription;
  public loadingDatamodels: boolean;
  public commentPerm: boolean = true;

  // Counting failures
  public totalFailures: number = 0;
  public totalExecutions: number = 0;
  public groupFailures: Array<any> = [];
  public groupTotal: Array<any> = [];

  public expandedConditions: Array<any> = [];
  public rowsToAdd: number = 1;
  public chunks: Array<any> = [];
  public extracteddatalist: Array<any> = [];
  public collapsedGroups: Array<any> = [];
  public expandedExecutions: Array<any> = [];
  public editingField: any = {}; // Single field editing
  public editingFields: Array<any> = []; // Multiple fields editing
  public selectingFieldChunk: any = {};
  public editingFieldsGroup: any = {}; // Row editing
  public showValidatedValues: boolean = false; //Controls whether to show validated values
  public disabledValidatedValues: boolean = false; //Disables validated values when editing

  public chunkSubscription: Subscription;
  public selectedChunksSubscription: Subscription;
  public chunkLoadingSubscription: Subscription;
  public isLoadingChunks: boolean = true; // First chunks loading
  public isRefreshingChunks: boolean = false; // Dynamic chunks loading
  public isSelectingChunk: boolean = false;
  public isUpdatingTable: boolean = false;
  public isUpdatingChunk: boolean = false;
  public subscriptionUtils: Subscription;
  public approval: any = {};

  private currentPage: number = 1;
  private currentPageSubscription: Subscription;
  public datamodelList: any[];
  private reviewersGroupsTypes: any[];
  private subscriptionReviewers: Subscription;
  private readonly skippedValidation: string = '#skipped#';
  public locations = locations;

  constructor(
    public documentRenderService: DocumentRenderService,
    private apiService: ApiService,
    private authService: AuthenticationService,
    private router: Router,
    private global: GlobalService,
    private modalService: NgbModal,
    private translate: TranslatePipe
  ) {
    // Check user permission to use this component
    this.user = authService.getLoggedInUser();
    this.showValidations = this.authService.userCanViewModule(
      this.user,
      'ValidationsCoreFunctionality'
    );
    this._userPermissions = this.global.getUserPermissionsConst();
    this._validationStatus = this.global.getValidationStatusConst();
    this._validationTypes = this.global.getValidationTypesConst();

    // Listen for chunks loading state
    this.chunkLoadingSubscription = this.documentRenderService
      .getIsLoadingChunks()
      .subscribe(obj => {
        this.isRefreshingChunks = obj.state;
        if (!this.isRefreshingChunks) {
          const chunks = this.documentRenderService.getChunks();
          this.mapChunksToExtractedFields(chunks);
        }
      });

    // Listen for single chunks being selecting (when updating field chunk)
    this.selectedChunksSubscription = this.documentRenderService
      .getSelectedChunk()
      .subscribe(obj => {
        this.saveSelectedChunk(obj.chunk);
      });

    this.currentPageSubscription = this.documentRenderService
      .getCurrentPage()
      .subscribe(page => {
        this.currentPage = page.pagenum;
      });
  }

  ngOnDestroy() {
    if (this.chunkSubscription) {
      this.chunkSubscription.unsubscribe();
    }
    if (this.selectedChunksSubscription) {
      this.selectedChunksSubscription.unsubscribe();
    }
    if (this.chunkLoadingSubscription) {
      this.chunkLoadingSubscription.unsubscribe();
    }
    if (this.subscriptionUtils) {
      this.subscriptionUtils.unsubscribe();
    }
    if (this.subscriptionReviewers) {
      this.subscriptionReviewers.unsubscribe();
    }
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.getDatamodels();
    // If the client has the validation module and there is at least one validation execution
    if (this.showValidations && this.validation.validationexecutionid) {
      this.validationsStatus = this.global.getValidationStatus();
      if (
        this.validationsStatus.length === 0 &&
        !this.global.passedWatcherUtils
      ) {
        this.subscriptionUtils = this.global
          .watchUtils()
          .subscribe((data: string) => {
            this.validationsStatus = this.global.getValidationStatus();
            this.global
              .getValidationExecution(this.validation.validationexecutionid)
              .then((execution: ValidationExecutionModel) => {
                this.validationExecution = execution;
                this.getExtractedDataList();
              });
          });
      } else {
        this.global
          .getValidationExecution(this.validation.validationexecutionid)
          .then((execution: ValidationExecutionModel) => {
            this.validationExecution = execution;
            this.getExtractedDataList();
          });
      }
    } else {
      this.getExtractedDataList();
    }
    this.getReviewersTypes();
  }

  /**
   * Change permission for override (only allow to override one validation at the same time)
   *
   */
  public onChangePermComment() {
    this.commentPerm = !this.commentPerm;
  }

  private getExtractedDataList() {
    this.extracteddatalist = this.serializeData(
      this.documentDetails.extracteddatalist,
      this.showValidations &&
        this.validation.validationexecutionid &&
        !this.isExternal
    );
    this.loadChunks();
    this.setDefaultCollapsedGroups(this.extracteddatalist);
  }

  private loadChunks() {
    let chunks = this.documentRenderService.getChunks();
    if (
      Object.keys(chunks).length === 0 &&
      !this.documentRenderService.getChunksCheck()
    ) {
      // Listen for chunks from database
      this.chunkSubscription = this.documentRenderService
        .chunksWatcher()
        .subscribe(obj => {
          chunks = this.documentRenderService.getChunks();
          this.mapChunksToExtractedFields(chunks);
          this.isLoadingChunks = false;
        });
    } else {
      this.mapChunksToExtractedFields(chunks);
      this.isLoadingChunks = false;
    }
  }

  /**
   * Gets the reviewers group types from the global service and sets the
   * approval according to them.
   */
  private getReviewersTypes() {
    this.reviewersGroupsTypes = this.global.getReviewers();
    if (
      this.reviewersGroupsTypes.length === 0 &&
      !this.global.passedWatcherUtils
    ) {
      this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
        this.reviewersGroupsTypes = this.global.getReviewers();
        this.setApproval();
      });
    } else {
      this.setApproval();
    }
  }

  /**
   * Set approval and check if user is the assigned reviewer.
   */
  private setApproval() {
    this.approval = this.documentDetails.approvals.find(
      a => a.currentapproval === true
    );
  }

  /**
   * Check if user is the assigned reviewer
   */
  private approvalIsAssignedToUser() {
    return (
      this.approval &&
      this.approval.assigned &&
      this.approval.assigned.userid === this.user.userid
    );
  }

  /**
   * Checks if user is the assigned reviewer and the validation modules exists.
   * When showing the validated values all interaction is removed
   */
  public userCanInteract(): boolean {
    if (this.showValidatedValues) {
      return false;
    }
    return !this.showValidations
      ? true
      : this.approvalIsAssignedToUser() && !this.approval.recommended;
  }

  /** Gets the title of template elements depending on the user role.
   * When showing validated values, all interaction is disabled and a generic
   * messaged is returned
   * @param text Id of the sentence to be translated.
   * @returns Title of the template element.
   */
  public getTitle(text: string): string {
    if (this.showValidatedValues) {
      return this.translate.transform(
        'documentDetails.cannotEditValidatedValues'
      );
    }
    if (text === '') {
      return this.translate.transform('documentDetails.onlyAssigned');
    } else {
      const originalText = this.translate.transform(text).toLowerCase();
      return this.userCanInteract()
        ? originalText
        : this.translate.transform('documentDetails.onlyAssigned');
    }
  }

  /**
   * Filter extracted values with null or empty
   * extracted values list
   * Used in serializeData method
   * @param d field object in fieldslist
   */
  public filterExtractedValues(d: any) {
    return d.extractedvalueslist && d.extractedvalueslist.length > 0;
  }

  /**
   * Flat nested objects array by key returning
   * Used in serializeData method
   */
  public flatFieldsList(array: Array<any>, key: string, conditions?: boolean) {
    const newlist = [];
    array.forEach(d => {
      if (!d[key]) d[key] = [];
      d[key].forEach(j => {
        const item = { ...j };
        item.fieldid = d.fieldid;
        item.fieldname = d.fieldname;
        this.serializeExecutionData(item, conditions);
        newlist.push(item);
      });
    });
    return newlist;
  }

  /**
   * Set the execution variables on the field to show the results
   * of the validation execution
   * @param item extracted field item
   * @param hasValidation if the validation module is set
   */
  public serializeExecutionData(item, hasValidation) {
    if (hasValidation) {
      const conditions = this.validationExecution.conditions;
      const fields = conditions.filter(
        d => d.extractedfieldid === item.extractedfieldid
      );
      item.overridable = true;
      item.children = fields.map(f => {
        const extractedField = { ...f };
        // In case that the condition message is separated in DB with the pattern #-#
        extractedField.messageLines = extractedField.message.split(' #-#');
        if (
          extractedField.messageLines[
            extractedField.messageLines.length - 1
          ].trim() === ''
        ) {
          extractedField.messageLines.pop();
        }
        this.validationsStatus.forEach(status => {
          if (extractedField.validationstatusid === status.validationstatusid) {
            extractedField.validationstatusname = status.validationstatusname;
          }
          if (
            extractedField.extractedfieldstatusid === status.validationstatusid
          ) {
            item.status = status.validationstatusname;
          }
        });
        // By dafault set it true
        if (extractedField.overridable === false) {
          item.overridable = false;
        }
        return extractedField;
      });
      if (item.children && item.children.length > 0) {
        item.documentId = this.documentDetails.documentid;
        item.conditiontype = item.children[0].conditiontype;
        item.status = this.updateStatusConditionType(item);
        item.islastversion = this.validationExecution.islastversion;
        item.validationtypename = this._validationTypes.EXTRACTION;
        item.validationmodifieddate =
          this.validationExecution.modifieddate instanceof Date
            ? this.validationExecution.modifieddate
            : new Date(this.validationExecution.modifieddate);
        if (this.validationExecution.comments) {
          this.setCommentAndFilesAttached(item);
        }
        if (item.modifieddate) {
          item.modifieddate = new Date(item.modifieddate);
        }
        if (item.status === this._validationStatus.FAILURE) {
          this.groupFailures[item.group]
            ? this.groupFailures[item.group]++
            : (this.groupFailures[item.group] = 1);
          this.totalFailures++;
        }
        this.groupTotal[item.group]
          ? this.groupTotal[item.group]++
          : (this.groupTotal[item.group] = 1);
        this.totalExecutions++;
      }
    }
  }

  /**
   * Return the comment for the conditions
   * @param item
   */
  public getCommentConditions(item) {
    return this.validationExecution.comments.find(c =>
      c.conditions_comment.find(
        conditionId =>
          conditionId.validationexecutionconditionsid ===
          item.children[0].validationexecutionconditionsid
      )
    );
  }

  /**
   * Set the comment and attached file variables to show
   * them properly in the list
   * @param item
   */
  public setCommentAndFilesAttached(item) {
    item.comment = this.getCommentConditions(item);
    if (item.comment) {
      item.fileAttached = item.comment.comment_attachments[0];
    }
  }

  /**
   * On base of the condition type of the item this function
   * returns the correct status. For example, if condition type
   * is 'must', all the validation conditions MUST be success,
   * otherwise the general status has to be failure.
   * @param item
   */
  public updateStatusConditionType(item) {
    let status = item.status;
    switch (item.conditiontype) {
      case 'must':
        if (
          item.children.some(
            ef => ef.validationstatusname === this._validationStatus.FAILURE
          )
        ) {
          status = this._validationStatus.FAILURE;
        }
        break;
      case 'should':
        if (
          item.children.some(
            ef => ef.validationstatusname === this._validationStatus.SUCCESS
          )
        ) {
          status = this._validationStatus.SUCCESS;
        }
        break;
      case 'mustNot':
        if (
          item.children.some(
            ef => ef.validationstatusname === this._validationStatus.SUCCESS
          )
        ) {
          status = this._validationStatus.FAILURE;
        }
        break;
    }

    return status;
  }

  /**
   * Group objects array by key
   * Used in serializeData method
   */
  public groupObjectsArrayByKey(array: Array<any>, key: string) {
    return array.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  /**
   * Serialize extracted groups
   */
  public serializeData(extracteddatalist: Array<any>, validations?: boolean) {
    if ([undefined, null].includes(extracteddatalist)) {
      return [];
    } else {
      return extracteddatalist.map(d => {
        const datalist = { ...d };
        // Clean counters
        this.groupFailures = [];
        this.groupTotal = [];
        // Remove empty fields list
        datalist.fieldslist = d.fieldslist.filter(this.filterExtractedValues);
        // Flat field list by 'extractedvalueslist' child array
        const flatValues = this.flatFieldsList(
          d.fieldslist,
          'extractedvalueslist',
          validations
        );
        // Group fields by 'group' key
        let groupedVals = this.groupObjectsArrayByKey(flatValues, 'group');
        // Transform grouped object to arrays and sort them
        datalist.fieldslist = Object.values(groupedVals).map(j => {
          return Array.isArray(j) ? j : [];
        });

        if (validations) {
          const total = (accumulator, currentValue) =>
            accumulator + currentValue;
          datalist.rowFailures = this.groupFailures;
          datalist.rowTotal = this.groupTotal;
          datalist.groupFailures = this.groupFailures.reduce(total, 0);
          datalist.groupTotal = this.groupTotal.reduce(total, 0);
        }
        return datalist;
      });
    }
  }

  /**
   * Add chunks to each extracted field
   * Triggered when chunks are loaded, it adds each chunk to
   * its respective field
   * @param chunks object with chunks grouped by page
   */
  public mapChunksToExtractedFields(chunks: any) {
    this.extracteddatalist.forEach(d => {
      d.fieldslist.forEach(f => {
        f.forEach(g => {
          if (chunks[g.page]) {
            // If chunks from field page are loaded, search them
            const chunkid = g.chunkidmodified ? g.chunkidmodified : g.chunkid;
            const matched = chunks[g.page].filter(c => c.chunkid === chunkid);
            g.chunk = matched.length ? matched[0] : null;
          }
        });
      });
    });
  }

  /**
   * Get extracted field confidence
   * If field value is modified, confidence is 100
   * @param extractedval extracted value object
   */
  public getFieldConfidence(extractedval) {
    return extractedval.extractedvaluemodified != null
      ? 100
      : extractedval.confidencelevel;
  }

  /**
   * Set default collapsed groups
   * Sets all groups and subgroups as collapsed except the first ones
   */
  public setDefaultCollapsedGroups(extracteddatalist: Array<any>) {
    extracteddatalist.forEach((d, i) => {
      if (i > 0) {
        this.toggleCollapse(i);
      }
      d.fieldslist.forEach((f, j) => {
        if (j > 0) {
          this.toggleCollapse(i, j);
        }
      });
    });
  }

  /**
   * Expand group
   */
  public expandGroup(groupid: number, subgroupid: number = -1) {
    const id = `${groupid}_${subgroupid}`;
    const idx = this.collapsedGroups.indexOf(id);
    if (idx > -1) {
      this.collapsedGroups.splice(idx, 1);
    }
  }

  /**
   * Expand group and subgroup
   */
  public expandGroupAndSubgroups(groupid: number) {
    const id = `${groupid}_`;
    this.collapsedGroups = this.collapsedGroups.filter(
      gid => !gid.startsWith(id)
    );
  }

  /**
   * Toggle group and subgroup collapsed status
   */
  public toggleCollapse(groupid: number, subgroupid: number = -1) {
    const id = `${groupid}_${subgroupid}`;
    const idx = this.collapsedGroups.indexOf(id);
    idx > -1
      ? this.collapsedGroups.splice(idx, 1)
      : this.collapsedGroups.push(id);
  }

  /**
   * Check if group or subgroup is currently collapsed
   */
  public isCollapsed(groupid: number, subgroupid: number = -1) {
    const id = `${groupid}_${subgroupid}`;
    return this.collapsedGroups.indexOf(id) > -1;
  }

  /**
   * Check if fields subgroup is table, multiple, entity or paragraph type
   */
  public groupCanHaveMultiplePartOfGroup(group) {
    return (
      ['table', 'multiple', 'paragraph'].includes(group.groupoffieldstype) ||
      (group.groupoffieldstypeid &&
        ['2', '3', '4', '5'].includes(group.groupoffieldstypeid.toString()))
    );
  }

  /**
   * Check if subhead (subgroup head) must be hidden
   * - Hide subheader if there's only one group and the groupoffieldstypeid is individual
   */
  public isHiddenSubhead(group) {
    if (group.groupoffieldstype) {
      return (
        group.fieldslist.length <= 1 && group.groupoffieldstype === 'individual'
      );
    } else {
      return (
        group.fieldslist.length <= 1 &&
        group.groupoffieldstypeid &&
        group.groupoffieldstypeid.toString() === '1'
      );
    }
  }

  /**
   * Check if subgroup must be collapsed
   * - No collapse if there's more than one row or the group header is showed
   */
  public isCollapsedSubbody(
    groupid: number,
    subgroupid: number = -1,
    group: any = {}
  ) {
    return (
      this.isCollapsed(groupid, subgroupid) && !this.isHiddenSubhead(group)
    );
  }

  /**
   * Toggle group and subgroup collapsed status
   * @param extractedval extracted value object
   */
  public toggleExpandedExecution(extractedval) {
    const id = extractedval.extractedfieldid;
    const idx = this.expandedExecutions.indexOf(id);
    if (idx > -1) {
      this.expandedExecutions.splice(idx, 1);
    } else {
      if (this.showValidations && this.validation && extractedval.status) {
        this.expandedExecutions.push(id);
      }
    }
  }

  /**
   * Check if group or subgroup is currently collapsed
   * @param extractedval extracted value object
   */
  public isExpandedExecution(extractedval) {
    const id = extractedval.extractedfieldid;
    return this.expandedExecutions.indexOf(id) > -1;
  }

  /**
   * Show chunk selection on document render component
   * Triggered when row is hovered
   * @param extractedval extracted value object
   */
  public showFieldChunk(extractedval) {
    if (
      extractedval.chunk &&
      !this.isSelectingChunk &&
      extractedval.chunk.pagenumber === this.currentPage
    ) {
      //extractedval.chunk.fromExtraction = true;
      
      this.documentRenderService.updateDocumentChunks([extractedval.chunk]);
      this.documentRenderService.setScrollWithRatio(
        extractedval.chunk.xposabs,
        extractedval.chunk.pagenumber
      );
      this.documentRenderService.showSingleChunk(extractedval.chunk.chunkid);
    }
  }

  /**
   * Hide chunk selection on document render component
   * Triggered when row isn't hovered anymore
   * @param extractedval extracted value object
   */
  public hideFieldChunk(extractedval) {
    if (
      extractedval.chunk &&
      !this.isSelectingChunk &&
      extractedval.chunk.pagenumber === this.currentPage
    ) {
      this.documentRenderService.hideSingleChunk(extractedval.chunk);
    }
  }

  /**
   * Enable editing field value and store it current
   * data to a temporally variable
   * @param extractedval extracted value object
   */
  public editField(extractedval, proposedValue = undefined) {
    this.disabledValidatedValues = true;
    this.editingField = { ...extractedval };
    this.editingField.newvalue = proposedValue
      ? proposedValue
      : extractedval.extractedvaluemodified !== null &&
        extractedval.extractedvaluemodified !== undefined
      ? extractedval.extractedvaluemodified
      : extractedval.extractedvalue;
  }

  /**
   * Save field modified value on database
   */
  public saveEditingField() {
    this.isUpdatingTable = true;
    const newExtractedField = {
      extractedvaluemodified: this.editingField.newvalue
    };
    this.apiService
      .patch(
        'extractedfields/',
        this.editingField.extractedfieldid,
        newExtractedField,
        'documentDetails.fieldupdated'
      )
      .subscribe(
        response => {
          this.global.emptyDocumentDetails();
          this.updateEditingFieldOnTable({
            extractedfieldid: response.extractedfieldid,
            newvalue: response.extractedvaluemodified,
            modifieddate: response.modifieddate
          });
          this.editingField = {};
          this.isUpdatingTable = false;
          this.disabledValidatedValues = false;
        },
        error => {
          // TODO: Handle save field value error
          this.editingField = {};
          this.isUpdatingTable = false;
          this.disabledValidatedValues = false;
        }
      );
  }

  /**
   * Update field value on fields table
   * Triggered once field is updated on db
   */
  public updateEditingFieldOnTable(editedField) {
    this.extracteddatalist.forEach(d => {
      d.fieldslist.forEach(j => {
        j.forEach(k => {
          if (k.extractedfieldid === editedField.extractedfieldid) {
            k.extractedvaluemodified = editedField.newvalue;
            if (editedField.modifieddate) {
              k.modifieddate = new Date(editedField.modifieddate);
            }
          }
        });
      });
    });
  }

  /**
   * Check if field if being edited (field newvalue equals
   * to field extractedvalue or extractedvaluemodified)
   * -> Returns true if current field value isn't already saved
   * @param field field being checked
   */
  public editingFieldIsBeingEdited(field) {
    return field.extractedvaluemodified !== null
      ? field.extractedvaluemodified !== field.newvalue
      : field.extractedvalue !== field.newvalue;
  }

  /**
   * Check if field has been modified (it has extractedvaluemodified)
   * -> Returns true if field extracted value has been modified
   * @param field field being checked
   */
  public editingFieldHasBeenModified(field) {
    return field.extractedvaluemodified !== null;
  }

  /**
   * get the css class to use when editing a field
   * It returns:
   *   'is-current-modified' -> value is currently being modified
   *   'is-modified' -> value has changed previusly (field's extractedvaluemodified exists)
   *   '' -> value hasn't changed (is the original extractedvalue)
   * @param field field being checked
   */
  public getFieldModifyClass(field) {
    if (this.editingFieldIsBeingEdited(field)) {
      return 'is-current-modified';
    }
    if (this.editingFieldHasBeenModified(field)) {
      return 'is-modified';
    }
    return '';
  }

  /**
   * Cancel field value edition
   */
  public cancelEditingField() {
    this.editingField = {};
    this.disabledValidatedValues = false;
  }

  /**
   * Check if edit field value button must be showed
   */
  public showEditFieldButton() {
    return (
      !this.isUpdatingTable && // No editing field value
      !this.isSelectingChunk
    ); // No selecting chunk
  }

  /**
   * Check if current field value is being editing
   * @param field clicked field
   */
  public isEditingField(field) {
    return this.isSingleEditingField(field) || this.isBatchEditingField(field);
  }

  /**
   * Check if current field value is being editing (single mode)
   * @param field clicked field
   */
  public isSingleEditingField(field) {
    return this.editingField.extractedfieldid === field.extractedfieldid;
  }

  /**
   * Check if current field value is being editing (batch mode)
   * @param field clicked field
   */
  public isBatchEditingField(field) {
    return (
      this.editingFields
        .map(f => f.extractedfieldid)
        .indexOf(field.extractedfieldid) > -1
    );
  }

  /**
   * Check if current group is being editing on batch mode
   * @param group group to check if is being edited
   */
  public isBatchEditingGroup(group) {
    if (group.fieldslist.length === 0) return false;
    const firstfieldid = group.fieldslist[0][0].extractedfieldid;
    return this.editingFields.some(f => firstfieldid === f.extractedfieldid);
  }

  /**
   * Check if current there is any batch editing active
   * @param field clicked field
   */
  public isBatchEditing() {
    return this.editingFields.length > 0;
  }

  /**
   * Trigger chunk selecting mode on document render component
   * and store current data in a temporally variable
   * @param extractedval extracted value object
   */
  public selectChunk(extractedval) {
    this.isSelectingChunk = true;
    this.disabledValidatedValues = true;
    this.selectingFieldChunk = { ...extractedval };
    this.documentRenderService.enableSelectingChunkMode();
  }

  /**
   * Cancel chunk selection
   */
  public cancelSelectChunk() {
    this.isSelectingChunk = false;
    this.disabledValidatedValues = false;
    this.selectingFieldChunk = {};
    this.documentRenderService.disableSelectingChunkMode();
  }

  /**
   * Check if edit chunk button must be showed
   * @param extractedval extracted value object
   */
  public showSelectChunkButton(extractedval) {
    return (
      !this.isUpdatingTable && // No editing field value
      !this.isLoadingChunks && // No loading chunk
      !this.isSelectingChunk
    ); // No selecting chunk
  }

  /**
   * Check if cancel edit chunk button must be showed
   * @param extractedval extracted value object
   */
  public showCancelSelectChunkButton(extractedval) {
    return (
      !this.isUpdatingTable && // No editing field value
      this.isSelectingChunk && // Is selecting chunk
      this.selectingFieldChunk.extractedfieldid ===
        extractedval.extractedfieldid
    ); // Current field chunk is being selected
  }

  /**
   * Check if current field chunk is being selected
   * @param extractedval extracted value object
   */
  public isSelectingFieldChunk(extractedval) {
    return (
      this.selectingFieldChunk.extractedfieldid ===
      extractedval.extractedfieldid
    );
  }

  /**
   * Save selected field chunk on database
   * @param chunk modified chunk object
   */
  public saveSelectedChunk(chunk) {
    this.isUpdatingChunk = true;
    this.isSelectingChunk = false;
    this.selectingFieldChunk.chunk = chunk;
    this.selectingFieldChunk.extractedvaluemodified =
      chunk.chunktext[0].textcontent;
    const newextractedfield = {
      chunkidmodified: chunk.chunkid
    };
    this.apiService
      .patch(
        'extractedfields/',
        this.selectingFieldChunk.extractedfieldid,
        newextractedfield,
        'documentDetails.fieldupdated'
      )
      .subscribe(
        () => {
          this.disabledValidatedValues = false;
          this.global.emptyDocumentDetails();
          this.updateSelectedChunkOnTable(this.selectingFieldChunk);
          this.selectingFieldChunk = {};
          this.isUpdatingChunk = false;
        },
        error => {
          // TODO: Handle save selected chunk error
          this.selectingFieldChunk = {};
          this.isUpdatingChunk = false;
        }
      );
  }

  /**
   * Update field chunk on fields table
   * Triggered once field chunk is updated on db
   */
  public updateSelectedChunkOnTable(editedField) {
    this.extracteddatalist.forEach(d => {
      d.fieldslist.forEach(j => {
        j.forEach(k => {
          if (k.extractedfieldid === editedField.extractedfieldid) {
            // Set field chunks and page
            k.chunkidmodified = editedField.chunkid;
            k.chunk = editedField.chunk;
            k.page = editedField.chunk.pagenumber;
            // Open edit field form with chunk text as proposed value
            this.editField(k, editedField.extractedvaluemodified);
          }
        });
      });
    });
  }

  /**
   * Switch between open/close condition
   * @param item Extracted field condition item
   */
  public toggleCollapsedCondition(item) {
    const id = item.validationexecutionconditionsid;
    const idx = this.expandedConditions.indexOf(id);
    idx > -1
      ? this.expandedConditions.splice(idx, 1)
      : this.expandedConditions.push(id);
  }

  /**
   * Check if a condition is currently collapsed
   * @param extractedval extracted value object
   */
  public isExpandedCondition(extractedval) {
    const id = extractedval.validationexecutionconditionsid;
    return this.showValidations
      ? this.expandedConditions.indexOf(id) > -1
      : () => {};
  }

  /**
   * Get field extracted value and slice it if it's too long
   * @param extractedval extracted value object
   * @param slice if true, the returned string will be sliced at the maxCharsLength
   */
  public getFieldValueFound(extractedval: any, slice: boolean = true): string {
    const maxCharsLength = 100;
    let value: string = '';
    if (this.showValidatedValues) {
      if (extractedval.validatedvalue == null) {
        extractedval.validatedvalue =
          this.getValidatedValueFromExtractedVal(extractedval);
      }
      value = extractedval.validatedvalue;
    } else {
      value =
        extractedval.extractedvaluemodified != null
          ? extractedval.extractedvaluemodified
          : extractedval.extractedvalue;
    }
    if (slice && value.length > maxCharsLength) {
      value = `${value.slice(0, maxCharsLength)}...`;
    }
    return value;
  }

  /**
   * Function for the override component
   * to discount the number of failures
   */
  public discountFailures() {
    this.totalFailures--;
  }

  /**
   * Output function to re render the timeline
   * from the children components
   */
  public onUpdateTimeline() {
    this.updateTimeline.emit();
    this.global
      .getValidationExecution(this.validation.validationexecutionid, true)
      .then((execution: ValidationExecutionModel) => {
        this.global.setValidationExecution(execution);
        this.validationExecution = execution;
        this.getExtractedDataList();
      });
  }

  /*
   * Check if the group is being edited (used to show edit form)
   * @param group fields group
   */
  public isAddingRowsToGroup(group) {
    return (
      this.editingFieldsGroup &&
      this.editingFieldsGroup.groupoffieldsid === group.groupoffieldsid
    );
  }

  /**
   * Cancel fields group edition
   */
  public endFieldsGroupEdition() {
    this.editingFieldsGroup = {};
    this.disabledValidatedValues = false;
  }

  /**
   * Edit fields group (enable add rows form)
   * @param event click event (to prevent propagation)
   * @param group fields group
   */
  public editFieldsGroup(event, group) {
    event.stopPropagation();
    this.editingFieldsGroup = group;
    this.disabledValidatedValues = true;
  }

  /**
   * Add new rows to fields group
   * @param group fields group
   * @param rowsToAdd number of rows to add
   */
  public addNewRows(group, rowsToAdd) {
    event.stopPropagation();
    this.isUpdatingTable = true;
    this.apiService
      .post(
        'extractedfields/addrows',
        {
          documentid: this.documentDetails.documentid,
          groupoffieldsid: group.groupoffieldsid,
          rows: rowsToAdd,
          extracteddatalist: true
        },
        'documentDetails.rowscreated'
      )
      .subscribe(
        response => {
          this.global.emptyDocumentDetails();
          this.extracteddatalist = this.serializeData(
            response,
            this.showValidations && this.validation.validationexecutionid
          );
          this.documentRenderService.triggerChunks();
          this.isUpdatingTable = false;
          this.endFieldsGroupEdition();
        },
        error => {
          this.isUpdatingTable = false;
          this.endFieldsGroupEdition();
          // TODO: Handle add row error
          // console.log('error', error);
        }
      );
  }

  /**
   * Remove rows from document fields table
   * @param group fields group
   * @param groupidx fields group index (to remove in table)
   * @param fieldGroup partofgroup from extractedfields
   */
  public removeRow(group, groupidx, fieldGroup) {
    event.stopPropagation();
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = {
      type: 'row'
    };
    modalWindowRef.result.then(result => {
      if (result === 1) {
        // Deletion confirmed
        this.isUpdatingTable = true;
        this.apiService
          .post(
            'extractedfields/deleterow',
            {
              documentid: this.documentDetails.documentid,
              groupoffieldsid: group.groupoffieldsid,
              group: fieldGroup
            },
            'documentDetails.rowremoved'
          )
          .subscribe(
            response => {
              const delidx = this.extracteddatalist[groupidx].fieldslist
                .map(d => d[0].group)
                .indexOf(fieldGroup);
              this.extracteddatalist[groupidx].fieldslist.splice(delidx, 1);
              if (group.groupFailures) {
                group.groupFailures--;
                group.groupTotal--;
              }
              this.isUpdatingTable = false;
            },
            error => {
              this.isUpdatingTable = false;
              // TODO: Handle remove row error
              // console.log('error', error);
            }
          );
      }
    });
  }

  /**
   * Edit all group rows fields at the same time
   * It adds all fields from all rows from the given group to editingFields
   * and set the default value to original or modified value
   * @param group group to enable edition
   */
  public batchEditGroup(group) {
    this.disabledValidatedValues = true;
    event.stopPropagation();
    this.editingFields = [];
    group.fieldslist.forEach(fieldlist => {
      fieldlist.forEach(field => {
        this.editingFields.push({
          ...field,
          newvalue:
            field.extractedvaluemodified != null
              ? field.extractedvaluemodified
              : field.extractedvalue
        });
      });
    });
  }

  public cancelBatchEditRow() {
    event.stopPropagation();
    this.editingFields = [];
    this.disabledValidatedValues = false;
  }

  public saveBatchEditRow() {
    event.stopPropagation();
    const fieldsToUpdate = this.editingFields
      .filter(f => this.editingFieldIsBeingEdited(f)) // Send only modified fields
      .map(f => ({
        extractedfieldid: f.extractedfieldid,
        extractedvaluemodified: f.newvalue
      }));
    if (fieldsToUpdate.length === 0) return;
    this.isUpdatingTable = true;
    this.apiService
      .post(
        'extractedfields/batchedit',
        { extractedfields: fieldsToUpdate },
        'documentDetails.fieldsupdated'
      )
      .subscribe(
        response => {
          this.global.emptyDocumentDetails();
          response.modified.forEach(field => {
            this.updateEditingFieldOnTable({
              extractedfieldid: field.extractedfieldid,
              newvalue: field.extractedvaluemodified,
              modifieddate: field.modifieddate
            });
          });
          this.editingFields = [];
          this.isUpdatingTable = false;
          this.disabledValidatedValues = false;
        },
        error => {
          // TODO: Handle save field value error
          this.isUpdatingTable = false;
          this.disabledValidatedValues = false;
        }
      );
  }

  /*
   * Bulk edit fields group (launchs modal)
   * @param event click event (to prevent propagation)
   * @param fieldsGroup fields group to edit
   */
  public bulkEditFieldsGroup(event, fieldsGroup) {
    event.stopPropagation();
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const modalRef = this.modalService.open(
      BulkEditDocumentFieldsComponent,
      modalOptions
    );
    modalRef.componentInstance.options = { fieldsGroup };
    modalRef.componentInstance.savedEvent.subscribe(
      modalResponse => {
        this.disabledValidatedValues = true;
        this.saveBulkEditFields({ ...modalResponse });
      },
      error => {
        // TODO implement error message
      }
    );
  }

  /**
   * Save fields modified value on database
   * @param fieldsToEditData fields data to bulk edit
   */
  public saveBulkEditFields(fieldsToEditData) {
    this.isUpdatingTable = true;
    this.apiService
      .post(
        'extractedfields/bulkedit',
        fieldsToEditData,
        'documentDetails.fieldsupdated'
      )
      .subscribe(
        response => {
          this.global.emptyDocumentDetails();
          response.modified.forEach((field: any) => {
            this.updateEditingFieldOnTable({
              extractedfieldid: field.extractedfieldid,
              newvalue: fieldsToEditData.extractedvaluemodified,
              modifieddate: field.modifieddate
            });
          });
          this.isUpdatingTable = false;
          this.disabledValidatedValues = false;
        },
        error => {
          // TODO: Handle save field value error
          this.isUpdatingTable = false;
          this.disabledValidatedValues = false;
        }
      );
  }

  /**
   * Handle page navigation
   * @param pageToNavigate page number to go
   */
  public changePagination(pageToNavigate) {
    this.documentRenderService.goToPage(pageToNavigate);
  }

  private getDatamodels() {
    this.loadingDatamodels = true;
    const datamodelMap = this.global.getDataModelMap();
    if (!this.global.passedWatcherDatamodels) {
      this.subscriptionDataModels = this.global
        .watchDataModels()
        .subscribe((data: string) => {
          this.datamodelList = Array.from(datamodelMap.values());
          this.loadingDatamodels = false;
        });
    } else {
      this.datamodelList = Array.from(datamodelMap.values());
      this.loadingDatamodels = false;
    }
  }

  /**
   * FieldList is mandatory
   *
   */
  public isMandatory(fieldname, fieldid) {
    let mandatoryValue = null;

    this.datamodelList.forEach(datamodel => {
      if (datamodel.datamodelid == this.documentDetails.datamodel.datamodelid) {
        if (datamodel.validations.extraction) {
          if (datamodel.validations.extraction.mandatory_fields) {
            Object.keys(datamodel.validations.extraction.mandatory_fields).map(
              key => {
                if (fieldid == key) {
                  mandatoryValue =
                    datamodel.validations.extraction.mandatory_fields[key];
                }
              }
            );
          } else {
            mandatoryValue = false;
          }
        } else {
          mandatoryValue = false;
        }
      }
    });
    return mandatoryValue;
  }

  /**
   * Checks if a extractedvalue has validation conditions by checking the children property.
   * @param extractedval
   */
  public checkExtractedValueValidations(extractedval: any): boolean {
    if (extractedval?.children) {
      return extractedval?.children.length > 0;
    }
    return false;
  }

  /**
   * Checks if the modifieddate of the field is greater than the latest validation date.
   * @param extractedval
   * @returns True if the modified date is greater than the latest validation date
   */
  public compareValidationModifiedDates(extractedval: any): boolean {
    if (!extractedval.modifieddate) {
      return false;
    }
    return extractedval.modifieddate > extractedval.validationmodifieddate;
  }

  /**
   * Gets the title for the warning status
   * @param extractedval
   * @returns The translated title
   */
  public getStatusWarningTitle(extractedval: any): string {
    return this.showValidatedValues &&
      this.compareValidationModifiedDates(extractedval)
      ? this.translate.transform('documentDetails.validationFieldOutdated')
      : this.translate.transform('documentDetails.noValidationField');
  }

  /**
   * Gets validated value from extracted value
   * @param extractedval
   */
  private getValidatedValueFromExtractedVal(extractedval: any): string {
    if (!this.checkExtractedValueValidations(extractedval)) {
      return '';
    }

    const child = extractedval.children.find(
      (x: any) => x.testvalue && x.testvalue !== this.skippedValidation
    );
    if (!child) {
      return '';
    }

    if (child.validationvaluedatatype === 'timestamp') {
      return moment.unix(child.testvalue).utc().format('MMMM Do YYYY');
    }
    return child.testvalue;
  }

  /*
   * This function gets the icon to be displayed for a document status
   */
  public getIcon(status: string) {
    if (status === null) {
      return 'remove';
    }
    const replacedStatus = status.replace(/\s+/g, '-');
    if (
      [
        this._validationStatus.MANUAL_SUCCESS,
        this._validationStatus.SUCCESS
      ].includes(replacedStatus)
    ) {
      return 'check';
    } else if (
      [
        this._validationStatus.FAILURE,
        this._validationStatus.MANUAL_FAILURE,
        this._validationStatus.FAILURE_NOT_APPLICABLE
      ].includes(replacedStatus)
    ) {
      return 'clear';
    } else if (replacedStatus === this._validationStatus.WARNING) {
      return 'warning';
    } else if (replacedStatus === this._validationStatus.IN_PROGRESS) {
      return 'sync';
    } else if (replacedStatus === this._validationStatus.SKIPPED) {
      return 'fiber_manual_record';
    } else if (replacedStatus === this._validationStatus.PENDING) {
      return 'adjust';
    }
  }

  /**
   * Retrieve document detail status message
   * translate will be done if has translation
   */
  public getDocumentsDetailStatusMessage() {
    if (!this.documentDetails) {
      return '';
    }
    if (
      this.documentDetails.statusmessage.split('.').length > 1 &&
      this.translate.transform(
        `errors.${this.documentDetails.statusmessage}`
      ) === this.documentDetails.statusmessage.split('.')[0]
    ) {
      return this.documentDetails.statusmessage;
    }
    return this.translate.transform(
      `errors.${this.documentDetails.statusmessage}`
    );
  }

  public showLocationDropdown(attributeName: string, datamodelName: string) {
    return (
      (attributeName === 'Location' || attributeName === 'Other Location') &&
      datamodelName in this.locations
    );
  }

  public hideField(attributeName: string, datamodelName: string) {
    return (
      (attributeName === 'Asset type' ||
        attributeName === 'Target Location' ||
        attributeName === 'Target Rate Location' ||
        attributeName === 'Target Net Amount Location' ||
        attributeName === 'Target Vendor' ||
        attributeName === 'Target Rate Vendor' ||
        attributeName === 'Target Net Amount Vendor') &&
      datamodelName === 'Inspectorate invoices'
    );
  }
}
