import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe } from './filter.pipe';
import { DateformatterPipe } from './dateformatter.pipe';
import { MomentPipe } from './moment.pipe';
import { HighlightTextPipe } from './highlight-text.pipe';
import { TrimDecimalPipe } from './decimal.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FilterPipe,
    DateformatterPipe,
    MomentPipe,
    HighlightTextPipe,
    TrimDecimalPipe
  ],
  exports: [
    FilterPipe,
    DateformatterPipe,
    MomentPipe,
    HighlightTextPipe,
    TrimDecimalPipe
  ]
})
export class PipeModule {}
