import { Component, OnInit, Input } from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  FormGroupName
} from '@angular/forms';
import { ValidationService } from '../common-components/validation.service';
import { ExtractionService } from '../extraction-components/extraction.service';
import { BusinessService } from '../business-components/business.service';
import { PreliminaryService } from '../preliminary-components/preliminary.service';
import { CatalogService } from '../catalog-components/catalog.service';
import { GlobalService } from 'src/app/services/global.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-case',
  templateUrl: './case.component.html',
  styleUrls: ['./case.component.scss']
})
export class CaseComponent implements OnInit {
  @Input('case') public case: FormGroup;
  @Input('datamodel_list') datamodel_list: Array<any> = [];
  @Input('datamodels') datamodels: Array<any>;
  @Input('casesIndex') public casesIndex: number;

  public expandedConditions = [];

  constructor(
    public validationService: ValidationService,
    public extractionService: ExtractionService,
    public businessService: BusinessService,
    public preliminaryService: PreliminaryService,
    public catalogService: CatalogService,
    public globalService: GlobalService,
    private translate: TranslatePipe
  ) {}

  ngOnInit() {
  }
}
