import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertService } from './alert.service';
import { AuthenticationService } from '../security/authentication.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { MockService } from './mock.service';
import { throwError, of, Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IfStmt } from '@angular/compiler';

const INTERNAL_SERVER_ERROR_MSG = 'Internal server error, please try again later';
const BAD_REQUEST_ERROR_MSG = "The request made is incorrect, please verify the data entered";
const DATAMODEL_NAME_EXISTS_ERROR_MSG = "Datamodel name already exists";
const PREFIX_FIELD_NOT_NULL_ERROR_MSG = "The field '";
const SUFIX_FIELD_NOT_NULL_ERROR_MSG = "' is required";

const PREFIX_FIELD_DATA_TYPE_ERROR_MSG = "The field '";
const SUFIX_FIELD_DATA_TYPE_ERROR_MSG = "' is invalid";

const UNKNOWN_ERROR = "Unknown error";

@Injectable()
export class ApiService {
  private url: string = environment.apiUrl;
  private withCredentials = false;

  public INTERNAL_SERVER_ERROR_HTTP_STATUS = 500;
  public OK_HTTP_STATUS = 200;
  public CREATED_HTTP_STATUS = 201;
  public BAD_REQUEST_HTTP_STATUS = 400;
  public UNAUTHORIZED_HTTP_STATUS = 401;
  public FORBIDDEN_HTTP_STATUS = 403;
  public NOT_FOUND_HTTP_STATUS = 404;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private router: Router,
    private mockService: MockService
  ) {}

  private getCookie(name) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    return parts.length === 2 ? parts.pop().split(';').shift() : '';
  }

  private createheaders(contentType: boolean | string = true, loginHeader?: string) {
    const headersObj = {
      'X-CSRFToken': this.getCookie('csrftoken'),
      [environment.tokenHeader]: this.authService.getToken()
    };

    if (environment.env === 'develop') {
      headersObj['x-postman'] = 'postman';
    }
    if (contentType) {
      headersObj['Content-Type'] =
        typeof contentType === 'string' ? contentType : 'application/json';
    }
    if (this.authService.getIdTenantHeader() !== undefined) {
      headersObj['x-id-tenant'] = this.authService.getIdTenantHeader();
    } else {
      headersObj['x-name-tenant'] = loginHeader;
    }
    return new HttpHeaders(headersObj);
  }

  private getQueryString(params) {
    if (typeof params === 'undefined' || typeof params !== 'object') {
      params = {};
      return params;
    }

    let query = '?';
    let index = 0;

    for (const i in params) {
      index++;
      const param = i;
      const value = params[i];
      query += index === 1 ? param + '=' + value : '&' + param + '=' + value;
    }
    return query;
  }

  public get(
    slug,
    paramsServer,
    message,
    contentType: boolean | string = true,
    requestParams = {},
    loginHeader = '',
    showError: boolean | boolean = true
  ) {
    if (environment.mock) {
      return this.mockService.get(slug, paramsServer);
    } else {
      let finalUrl = this.url + slug;
      if (paramsServer !== '') {
        finalUrl = finalUrl + this.getQueryString(paramsServer);
      }
      if (slug !== 'login/') {
        this.authService.isValidToken();
      }
      return this.http
        .get(finalUrl, {
          headers: this.createheaders(contentType, loginHeader),
          withCredentials: this.withCredentials,
          ...requestParams
        }).pipe(
        map(res => {
          if (message) {
            this.alertService.success(message);
          }

          return res;
        }),
        catchError(error => {
          if (showError) {
            const errorMessage = this.getErrorMessage(error);
            this.checkIfLogged(error);
            return this.handleError(error, errorMessage);
          } else {
            return throwError(error);
          }
        }),);
    }
  }

  public post(
    slug,
    data,
    message,
    contentType: boolean | string = true,
    requestParams = {},
    keepAlertOnNavigate: boolean = false
  ) {
    if (environment.mock) {
      return this.mockService.post(message);
    } else {
      const finalUrl = this.url + slug + '/';
      if (slug !== 'login') {
        this.authService.isValidToken();
      }
      return this.http
        .post(finalUrl, data, {
          headers: this.createheaders(contentType),
          withCredentials: false,
          ...requestParams
        }).pipe(
        map(res => {
          const obj = this.parseData(res);
          if (message) {
            this.alertService.success(message, keepAlertOnNavigate);
          }
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        },));
    }
  }

  private checkIfLogged(error) {
    if (error.status === 401) {
      this.alertService.error(
        'Your session has expired, please log in again',
        false
      );
    } 
  }

  public put(
    slug,
    id,
    data,
    message,
    contentType: boolean | string = true,
    requestParams = {},
    keepAlertOnNavigate: boolean = false
  ) {
    if (environment.mock) {
      this.mockService.put(message);
    } else {
      const finalUrl = this.url + slug + id + '/';
      if (slug !== 'login') {
        this.authService.isValidToken();
      }

      return this.http
        .put(finalUrl, data, {
          headers: this.createheaders(contentType),
          withCredentials: false,
          ...requestParams
        }).pipe(
        map(res => {
          const obj = this.parseData(res);
          this.alertService.success(message, keepAlertOnNavigate);
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        }),);
    }
  }

  public patch(
    slug,
    id,
    data,
    message,
    contentType: boolean | string = true,
    requestParams = {}
  ) {
    if (environment.mock) {
      this.mockService.put(message);
    } else {
      const finalUrl = this.url + slug + id + '/';
      if (slug !== 'login') {
        this.authService.isValidToken();
      }

      return this.http
        .patch(finalUrl, data, {
          headers: this.createheaders(contentType),
          withCredentials: false,
          ...requestParams
        }).pipe(
        map(res => {
          const obj = this.parseData(res);
          this.alertService.success(message, false);
          return obj;
        }),
        catchError(error => {
          const errorMessage = this.getErrorMessage(error);
          this.checkIfLogged(error);
          return this.handleError(error, errorMessage);
        }),);
    }
  }

  public delete(
    slug,
    id,
    message,
    contentType: boolean | string = true,
    requestParams = {}
  ) {
    const finalUrl = this.url + slug + id + '/';
    if (slug !== 'login') {
      this.authService.isValidToken();
    }

    return this.http
      .delete(finalUrl, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
      map(res => {
        const obj = this.parseData(res);

        if (message) {
          this.alertService.success(message, false);
        }

        return obj;
      }),
      catchError(error => {
        const errorMessage = this.getErrorMessage(error);
        this.checkIfLogged(error);
        return this.handleError(error, errorMessage);
      }));
  }

  public uploadFile(
    slug,
    data,
    contentType: boolean | string = true,
    requestParams = {}
  ) {
    const finalUrl = this.url + slug;
    if (slug !== 'login') {
      this.authService.isValidToken();
    }

    return this.http
      .post(finalUrl, data, {
        headers: this.createheaders(contentType),
        withCredentials: false,
        ...requestParams
      }).pipe(
      map(res => {
        const obj = this.parseData(res);
        return obj;
      }),
      catchError(error => {
        const errorMessage = this.getErrorMessage(error);
        this.checkIfLogged(error);
        return this.handleError(error, errorMessage);
      }));
  }

  public forkJoin(
    httpArray: Array<Observable<any>>,
    successMsg: string,
    failureMsg: string,
    callback?: any
  ) {
    return forkJoin(
      httpArray.map(httpRequest =>
        httpRequest.pipe(catchError(() => of(undefined)))
      )
    ).subscribe(responses => {
      const failures = responses.filter(response => response === undefined);
      if (failures.length > 0) {
        this.alertService.error(failureMsg, false);
      } else {
        this.alertService.success(successMsg, false);
      }
    });
  }

  private parseData(res) {
    return res;
  }

  private getErrorMessage(error: any) {
    const msg = Boolean(error.error.message) ? error.error.message: '';
    
    // Validation JSON Error field required 
    const regex = /^JSON Error: '(\w+)' is a required property\./;
    const match = msg.split(/ Path:/)[0].match(regex);

    // Validation JSON Error field data type
    const regexDataTypeError = /not of type (?:'\w+'(?:, )?)+\. Path: [\w\.]+\.(\w+)$/;
    const matchDataTypeError = msg.includes(' is ') ? msg.split(/\bis\b/)[1].match(regexDataTypeError) : undefined ;

    if (Boolean(error.error.detail) && error.error.detail != UNKNOWN_ERROR) {
      let m = '';
      for (const key in error.error) {
        m = m + error.error[key] + '.';
      }
      if (m !== '') {
        return m;
      }
    } else if (Boolean(match)) {
      return PREFIX_FIELD_NOT_NULL_ERROR_MSG + match[1] + SUFIX_FIELD_NOT_NULL_ERROR_MSG;
    } else if (Boolean(matchDataTypeError)) {
      return PREFIX_FIELD_DATA_TYPE_ERROR_MSG + matchDataTypeError[1] + SUFIX_FIELD_DATA_TYPE_ERROR_MSG;
    } else if (error.status == 400) {
      if (error.error.message === DATAMODEL_NAME_EXISTS_ERROR_MSG) {
        return error.error.message;
      }
      return BAD_REQUEST_ERROR_MSG;
    } else {
      return INTERNAL_SERVER_ERROR_MSG;
    }
  }

  private handleError(error, errorMessage) {
    this.alertService.error(errorMessage, false);
    return throwError(error);
  }
}
