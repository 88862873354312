import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import {
  FormGroup,
  Validators
} from '@angular/forms';
import {
  GlobalService
} from 'src/app/services/global.service';

@Component({
  selector: 'app-type-field',
  templateUrl: './type-field.component.html',
  styleUrls: ['./type-field.component.scss']
})
export class TypeFieldComponent implements OnInit {
  @Input('parentForm') parentForm: FormGroup;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  @Input('order') public order: number;

  public fieldtypes: any[] = [];
  public entities: any[] = [];
  public fieldTypeExample: string = '';

  constructor(public global: GlobalService) {}

  ngOnInit() {
    this.global.getFieldTypes().then(result => (this.fieldtypes = result));
    this.getEntities();
    this.parentForm.get('fieldorder').setValue(Boolean(this.parentForm.get('fieldorder').value) ? this.parentForm.get('fieldorder').value : this.order + 1);
  }

  public showEntityList() {
    let show = false;
    const value = this.parentForm.get('fieldtypeid').value;
    if (value && this.fieldtypes.length > 0) {
      const type = this.fieldtypes.find(f => f.fieldtypeid === value);
      show = this.parentForm.get('entity') && type.fieldtypename === 'Text';
    }
    return show;
  }

  /** 
   * Change field type
   */
  public changeFieldType() {
    this.fieldTypeExample = this.fieldtypes.filter(type => type.fieldtypeid == this.parentForm.get('fieldtypeid').value)[0]['fieldtypeexample'];

    this.parentForm.get('fieldofinterestexample').setValue(this.fieldTypeExample);
  }

  //TODO: Temporal while we get the entities from the db
  private getEntities(): void {
    this.entities = [
      'number',
      'phone-number',
      'ordinal',
      'amount',
      'date',
      'email',
      'url',
      'org',
      'person',
      'gpe',
      'product',
      'norp',
      'language',
      'loc',
      'misc',
      'fac',
      'percent',
      'per'
    ];
  }
}
