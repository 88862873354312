import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesRouting } from './pages.routing';
import { ProjectDetailsComponent } from './project-details/project-details.pages';
import { DocumentDetailsPages } from './document-details/document-details.pages';
import { PipeModule } from '../pipes/pipe.module';
import { ThemeComponentModule } from '../theme/theme-components/ThemeComponentModule';
import { ComponentWsModule } from '../components/component-ws.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProjectListComponent } from './project-list/project-list.pages';
import { ProjectGridViewComponent } from './project-list/project-grid-view/project-grid-view.component';
import { ProjectListViewComponent } from './project-list/project-list-view/project-list-view.component';
import { ProjectAnalysisViewComponent } from './project-details/project-analysis-view/project-analysis-view.component';
import { ProjectOverviewViewComponent } from './project-details/project-overview-view/project-overview-view.component';
import { ProjectUsersViewComponent } from './project-details/project-users-view/project-users-view.component';
import { TranslateModule } from '../translate/translate.module';
import { AnalysisDetailsComponent } from './analysis-details/analysis-details.pages';
import { AnalysisOverviewViewComponent } from './analysis-details/analysis-overview-view/analysis-overview-view.component';
import { AnalysisDocumentsViewComponent } from './analysis-details/analysis-documents-view/analysis-documents-view.component';
import { DocumentViewPages } from './document-details/document-view/document-view.pages';
import { QueryComponent } from './query/query.component';
import { NewProjectComponent } from './project-list/new-project/new-project.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesComponent } from './pages.component';
import { NotAllowedComponent } from '../errors/not-allowed/not-allowed.component';
import { NotAvailableComponent } from '../errors/not-available/not-available.component';
import { AdminComponent } from './admin/admin.component';
import { MatChipsModule } from '@angular/material/chips';
import { DatamodelValidationListComponent } from './datamodels/datamodel-validation-list/datamodel-validation-list.component';
import { NewValidationComponent } from './datamodels/datamodel-validation-list/validation/new-validation/new-validation.component';
import { CaseComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/case.component';
import { DocumentValidationComponent } from './document-details/document-validation/document-validation.pages';
import { DocumentOverviewComponent } from './document-details/document-overview/document-overview.pages';
import { DocumentInfoComponent } from './document-details/document-overview/document-info/document-info.component';
import { ConditionComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/condition.component';
import { SingleConditionComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/single-condition/single-condition.component';
import { MultipleConditionComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/multiple-condition/multiple-condition.component';
import { PreConditionComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/pre-condition/pre-condition.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardChartsComponent } from './dashboard/dashboard-charts/dashboard-charts.component';
import { ValidationsReviewComponent } from './validations-review/validations-review.component';
import { ValidationsDocumentListComponent } from './validations-review/validations-document-list/validations-document-list.component';
import { ExtractionTypeComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/single-condition/extraction-type/extraction-type.component';
import { BusinessRuleTypeComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/single-condition/business-rule-type/business-rule-type.component';
import { OperationFieldComponent } from './datamodels/datamodel-validation-list/validation/new-validation/common-components/operation-field/operation-field.component';
import { PreliminaryTypeComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/single-condition/preliminary-type/preliminary-type.component';
import { CatalogTypeComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/single-condition/catalog-type/catalog-type.component';
import { DatamodelidComponent } from './datamodels/datamodel-validation-list/validation/new-validation/common-components/datamodelid/datamodelid.component';
import { GeneralConfidenceComponent } from './datamodels/datamodel-validation-list/validation/new-validation/extraction-components/general-confidence/general-confidence.component';
import { DatamodelListComponent } from './datamodels/datamodel-validation-list/validation/new-validation/common-components/datamodel-list/datamodel-list.component';
import { TrackstatusComponent } from './datamodels/datamodel-validation-list/validation/new-validation/preliminary-components/trackstatus/trackstatus.component';
import { FieldOfInterestConditionComponent } from './datamodels/datamodel-validation-list/validation/new-validation/extraction-components/field-of-interest-condition/field-of-interest-condition.component';
import { UniqueDocComponent } from './datamodels/datamodel-validation-list/validation/new-validation/business-components/unique-doc/unique-doc.component';
import { DocumentParentComponent } from './document-details/document-view/document-parent/document-parent.component';
import { DocumentFieldsListComponent } from './document-details/document-view/document-fields-list/document-fields-list.component';
import { ValidationCollapsibleListComponent } from './document-details/document-validation/validation-collapsible-list/validation-collapsible-list.component';
import { ValidationCollapsibleListItemComponent } from './document-details/document-validation/validation-collapsible-list/validation-collapsible-list-item/validation-collapsible-list-item.component';
import { ConsolidatedDocumentsComponent } from './output-files/consolidated-documents/consolidated-documents.component';
import { ExtractionDocnameComponent } from './datamodels/datamodel-validation-list/validation/new-validation/extraction-components/extraction-docname/extraction-docname.component';
import { OutputFilesComponent } from './output-files/output-files.component';
import { ExternalViewerComponent } from './external-viewer/external-viewer.component';
import { DirectivesModule } from '../directives/directive.module';
import { BulkEditDocumentFieldsComponent } from './document-details/document-view/document-fields-list/bulk-edit-fields/bulk-edit-fields.component';
import { TrainableComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/trainable.component';
import { GroupOfFieldsComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/group-of-fields.component';
import { TrainableTableComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-table/trainable-table.component';
import { TrainableTableBodyComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-table/trainable-table-body.component';
import { TrainableParagraphComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-paragraph/trainable-paragraph.component';
import { TrainableEntityComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-entity/trainable-entity.component';
import { TrainableIndividualComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-individual/trainable-individual.component';
import { TrainableIndividualBodyComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-individual/trainable-individual-body.component';
import { TrainableMultipleComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-multiple/trainable-multiple.component';
import { TrainableMultipleBodyComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/trainable-multiple/trainable-multiple-body.component';
import { TypeFieldComponent } from './datamodels/datamodel-validation-list/trainable-datamodels/trainable/group-of-fields/type-field/type-field.component';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { PowerbiChartsComponent } from './dashboard/powerbi-charts/powerbi-charts.component';
import { BasicFilterComponent } from './query/filters/basic-filter/basic-filter.component';
import { OriginalTextFilterComponent } from './query/filters/original-text-filter/original-text-filter.component';
import { ExtractedFilterComponent } from './query/filters/extracted-filter/extracted-filter.component';
import { ValidatedFilterComponent } from './query/filters/validated-filter/validated-filter.component';
import { TextFilterComponent } from './query/filters/common-filter/text-filter/text-filter.component';
import { NumericFilterComponent } from './query/filters/common-filter/numeric-filter/numeric-filter.component';
import { ConditionalFilterComponent } from './query/filters/common-filter/conditional-filter/conditional-filter.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { DateFilterComponent } from './query/filters/common-filter/date-filter/date-filter.component';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
import { TimeTrackingFiltersComponent } from './time-tracking/time-tracking-filters/time-tracking-filters.component';
import { DocumentReviewerComponent } from './document-details/document-overview/document-reviewer/document-reviewer.component';
import { CategoryFilterComponent } from './query/filters/basic-filter/category-filter/category-filter.component';
import { QueueManagementComponent } from './admin/queue-management/queue-management.component';
import { QueueListComponent } from './admin/queue-management/queue-list/queue-list.component';
import { CacheListComponent } from './admin/queue-management/cache-list/cache-list.component';
import { CheckListComponent } from './datamodels/datamodel-validation-list/validation/new-validation/checklist/checklist.component';
import { PrivacyNoteComponent } from '../components/private-note/privacy-note.component';
import { DocumentChecklistTableComponent } from '../components/document-checklist-table/document-checklist-table.component';
import { DocumentChecklistTableItemComponent } from '../components/document-checklist-table/document-checklist-table-item/document-checklist-table-item.component';
import { MatSelectModule } from '@angular/material/select';
import { DocumentChildrenListComponent } from './document-details/document-overview/document-children-list/document-children-list.component';
import { UpdateChildrenPages } from '../components/modals/update-children/update-children.pages';
import { DocumentSummaryComponent } from './document-details/document-overview/document-summary/document-summary.component';
import { CorruptDocumentComponent } from '../errors/corrupt-document/corrupt-document.component';
import { CaseBodyComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/case-body.component';
import { ConditionBodyComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/condition/condition-body.component';
import { CaseRootComponent } from './datamodels/datamodel-validation-list/validation/new-validation/case/case-root.component';
import { OverviewComponent } from './overview/overview.component';
import { CurveChartComponent } from '../components/charts/curve-chart/curve-chart.component';
import { DonutChartComponent } from '../components/charts/donut-chart/donut-chart.component';
@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    PagesRouting,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    PipeModule,
    ThemeComponentModule,
    ComponentWsModule,
    NgxPaginationModule,
    TranslateModule,
    NgxPowerBiModule,
    MatChipsModule,
    DirectivesModule,
    MatAutocompleteModule,
    MatSelectModule,
  ],
  declarations: [
    ProjectListComponent,
    ProjectDetailsComponent,
    DocumentDetailsPages,
    ProjectGridViewComponent,
    ProjectListViewComponent,
    ProjectAnalysisViewComponent,
    ProjectOverviewViewComponent,
    ProjectUsersViewComponent,
    AnalysisDetailsComponent,
    AnalysisOverviewViewComponent,
    AnalysisDocumentsViewComponent,
    AnalysisOverviewViewComponent,
    DocumentViewPages,
    QueryComponent,
    NewProjectComponent,
    BulkEditDocumentFieldsComponent,
    PagesComponent,
    NotAllowedComponent,
    NotAvailableComponent,
    PrivacyNoteComponent,
    AdminComponent,
    DatamodelValidationListComponent,
    NewValidationComponent,
    CaseComponent,
    CheckListComponent,
    ConditionComponent,
    SingleConditionComponent,
    MultipleConditionComponent,
    PreConditionComponent,
    DocumentValidationComponent,
    DocumentOverviewComponent,
    DocumentInfoComponent,
    DocumentChildrenListComponent,
    UpdateChildrenPages,
    DashboardComponent,
    DashboardChartsComponent,
    ExtractionTypeComponent,
    BusinessRuleTypeComponent,
    OperationFieldComponent,
    PreliminaryTypeComponent,
    CatalogTypeComponent,
    DatamodelidComponent,
    GeneralConfidenceComponent,
    DatamodelListComponent,
    TrackstatusComponent,
    FieldOfInterestConditionComponent,
    UniqueDocComponent,
    DocumentParentComponent,
    DocumentFieldsListComponent,
    ValidationCollapsibleListComponent,
    DocumentChecklistTableComponent,
    ValidationCollapsibleListItemComponent,
    DocumentChecklistTableItemComponent,
    ValidationsReviewComponent,
    ValidationsDocumentListComponent,
    ConsolidatedDocumentsComponent,
    ExtractionDocnameComponent,
    OutputFilesComponent,
    PowerbiChartsComponent,
    TrainableComponent,
    GroupOfFieldsComponent,
    TrainableTableComponent,
    TrainableParagraphComponent,
    TrainableEntityComponent,
    TrainableIndividualComponent,
    TypeFieldComponent,
    ExternalViewerComponent,
    BasicFilterComponent,
    OriginalTextFilterComponent,
    ExtractedFilterComponent,
    ValidatedFilterComponent,
    TextFilterComponent,
    NumericFilterComponent,
    ConditionalFilterComponent,
    DateFilterComponent,
    TimeTrackingComponent,
    TimeTrackingFiltersComponent,
    DocumentReviewerComponent,
    CategoryFilterComponent,
    QueueManagementComponent,
    QueueListComponent,
    CacheListComponent,
    DocumentSummaryComponent,
    CorruptDocumentComponent,
    TrainableMultipleComponent,
    TrainableIndividualBodyComponent,
    TrainableMultipleBodyComponent,
    TrainableTableBodyComponent,
    CaseBodyComponent,
    ConditionBodyComponent,
    CaseRootComponent,
    OverviewComponent,
    CurveChartComponent,
    DonutChartComponent,
  ],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  providers: [TranslateModule, ThemeComponentModule],
  entryComponents: [NewProjectComponent, BulkEditDocumentFieldsComponent]
})
export class PagesModule {}
