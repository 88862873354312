import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: ['./not-available.component.scss']
})

export class NotAvailableComponent  {
  public service_name: string = 'Smart Retrieval App';
  public message: string =` is currently unavailable. We apologize for any inconvenience this may cause.`;
  public loading: boolean = false;

  constructor(
    private router: Router,
  ) {
  }

  onRetryLogin() {
    this.loading = true;
    const returnTo = '/';
    this.router.navigate([returnTo]).then(
      _ => {
        this.loading = false;
      },
      _ => {
        this.loading = false;
      }
    );
  }
}
