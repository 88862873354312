import {
  Injectable
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  Validators
} from '@angular/forms';
import {
  DeleteModalComponent
} from 'src/app/components/modals/delete/delete.component';
import {
  NgbModal,
  NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class TrainableService {

  public GOFTYPESIDS: any = {
    INDIVIDUAL: 1,
    TABLE: 2,
    MULTIPLE: 5
  }

  constructor(private modalService: NgbModal) {}

  public getFormTrainable(
    form: object,
    newMode: boolean,
    datamodelname ? : string,
    lang ? : number,
    maxReviewDays ? : number,
    datamodeldescription ? : string,
    processType ? : string,
    semanticParam ? : string,
    bufferParam ? : number,
    breakpointParam ? : number,
    similarityParam ? : number,

  ): FormGroup {
    const detecttable = true;
    const multipletable = false;
    const multiplelineheaders = false;
    const multiplelinecells = false;
    let datamodeldisplayname = '';
    let datamodelDescription = '';
    let langid = null;
    let localMaxReviewDays = null;
    const hlineseparator = null;
    const groupOfFields = new FormArray([]);
    let semantic = 'SemanticSplitterNodeParser';
    let buffer = 5;
    let breakpoint = 95;
    let similarity = 3;

    if (!newMode) {
      datamodeldisplayname = datamodelname;
      langid = lang;
      localMaxReviewDays = maxReviewDays;
      datamodelDescription = datamodeldescription;

      semantic = semanticParam;
      buffer = bufferParam;
      breakpoint = breakpointParam;
      similarity = similarityParam;

      this.getGroupOfFields(form, groupOfFields);
    } else {
      this.addNewGroupOfField(groupOfFields);
    }

    let processTypeFormControl = Boolean(processType) ? new FormControl(processType, Validators.required) : new FormControl('');

    const fromTrainable = new FormGroup({
      datamodeldisplayname: new FormControl(
        datamodeldisplayname,
        Validators.required
      ),
      datamodeldescription: new FormControl(datamodelDescription, Validators.required),
      processtype: Boolean(newMode) ? new FormControl('', Validators.required) : processTypeFormControl,
      humanloop: new FormControl(true),
      detecttable: new FormControl(detecttable),
      multipletable: new FormControl(multipletable),
      multiplelineheaders: new FormControl(multiplelineheaders),
      multiplelinecells: new FormControl(multiplelinecells),
      hlineseparator: new FormControl(hlineseparator),
      groupoffields: groupOfFields,
      langid: new FormControl(langid),
      maxreviewdays: new FormControl(localMaxReviewDays, Validators.min(1)),
      splitternodeparser: new FormControl(semantic),
      buffersize: new FormControl(buffer),
      breakpointpercentilethreshold: new FormControl(breakpoint),
      similaritytopk: new FormControl(similarity)
    });
    return fromTrainable;
  }

  public addNewGroupOfField(arr_gof) {
    arr_gof.push(
      new FormGroup({
        groupoffieldsid: new FormControl(''),
        groupoffieldsname: new FormControl('', Validators.required),
        groupoffieldstypeid: new FormControl(null, Validators.required),
        groupoffieldsdescription: new FormControl(null, Validators.required),
        groupoffieldsquestion: new FormControl()
      })
    );
  }

  public getGroupOfFields(form, listForm) {
    const arr_gof = form['information_extractor']['group_of_fields'];
    if (arr_gof) {
      for (const gof of arr_gof) {
        listForm.push(
          new FormGroup({
            groupoffieldsid: new FormControl(gof['groupoffieldsid']),
            groupoffieldsname: new FormControl(gof['groupoffieldsname']),
            groupoffieldstypeid: new FormControl(gof['groupoffieldstypeid']),
            groupoffieldsdescription: new FormControl(gof['groupoffieldsdescription']),
            createdbyuser: new FormControl(gof['createdbyuser']),
            groupoffieldsquestion: new FormControl(gof['groupoffieldsquestion']),
            fieldsofinterest: this.getFieldOfInterest(gof)
          })
        );
      }
    }
  }

  public getStringsArray(form, key) {
    const formArray = form[key];
    const newArray = new FormArray([]);
    Object.keys(formArray).forEach(key => {
      for (const str of formArray[key]) {
        newArray.push(new FormControl(str));
      }
    });
    return newArray;
  }

  public getStringsArrayHeader(form, key) {
    const formArray = form[key];
    const newArray = new FormArray([]);
    for (const str of formArray) {
      newArray.push(new FormControl(str));
    }
    return newArray;
  }

  public removeInputControl(form, i) {
    if (form.length > 1) {
      form.removeAt(i);
    }
  }

  public deleteFromList(form, i, type) {
    if (form.length > 1) {
      this.deleteModal(form, i, type);
    }
  }

  public deleteFromCheckList(form, i, type) {
    this.deleteModal(form, i, type);
  }

  /**
   * Delete Modal for delete case, condition list or conditions
   * @param parent  where we gonna delete a case or a condition list
   * @param index index to delete
   * @param message variable that what we are gonna delete
   * @returns Form without case, condition list or condition
   */
  private deleteModal(parent, index, message) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: message,
      notAllowed: false
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          parent.removeAt(index);
        }
      },
      reason => {}
    );
  }

  /**
   * Add new empty case to Form
   */
  public addGroupOfField(form) {
    const arr_gof = form.get('groupoffields') as FormArray;
    this.addNewGroupOfField(arr_gof);
  }

  public addField(form, type) {
    // TODO: Pending to uses constants inside the app.
    switch (type) {
      // Individual case
      case this.GOFTYPESIDS.INDIVIDUAL:
        this.addNewFieldIndividual(form);
        break;
        // Table case
      case this.GOFTYPESIDS.TABLE:
        this.addNewFieldTable(form);
        break;
        // Default: Individual case
      default:
        this.addNewFieldIndividual(form);
        break;
    }
  }

  public getFieldOfInterest(gof) {
    const arr_fields = gof['fieldsofinterest'];
    const formArray = new FormArray([]);
    let fof: any;
    for (const field of arr_fields) {
      //TODO: Use switch options from constants for different group of fields type
      switch (gof.groupoffieldstypeid) {
        // Individual case
        case this.GOFTYPESIDS.INDIVIDUAL:
          fof = this.getFieldOfInterestIndividual(field);
          break;
          // Table case
        case this.GOFTYPESIDS.TABLE:
          fof = this.getFieldOfInterestTable(field);
          break;
          // Multiple case
        case this.GOFTYPESIDS.MULTIPLE:
            fof = this.getFieldOfInterestIndividual(field);
          break;
          // Default: Individual case
        default:
          fof = this.getFieldOfInterestIndividual(field);
          break;
      }
      formArray.push(fof);
    }
    return formArray;
  }

  public getFieldOfInterestTable(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      istrainable: new FormControl(field['istrainable']),
      headers: this.getStringsArrayHeader(field, 'headers'),
      headers_algorithm: this.getStringsArrayHeader(field, 'headers_algorithm'),
      not_headers: this.getStringsArrayHeader(field, 'not_headers'),
      createdbyuser: new FormControl(field['createdbyuser']),
      fieldofinterestdescription: new FormControl(field['fieldofinterestdescription']),
      fieldofinterestexample: new FormControl(field['fieldofinterestexample']),
    });
  }

  public getFieldOfInterestParagraph(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      istrainable: new FormControl(field['istrainable']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      entity: new FormControl(field['entity']),
      key: this.getStringsArray(field, 'key'),
      createdbyuser: new FormControl(field['createdbyuser']),
      fieldofinterestdescription: new FormControl(field['fieldofinterestdescription']),
      fieldofinterestexample: new FormControl(field['fieldofinterestexample']),
    });
  }

  public getFieldOfInterestEntity(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      istrainable: new FormControl(field['istrainable']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      entity: new FormControl(field['entity']),
      key: this.getStringsArray(field, 'key'),
      createdbyuser: new FormControl(field['createdbyuser']),
      fieldofinterestdescription: new FormControl(field['fieldofinterestdescription']),
      fieldofinterestexample: new FormControl(field['fieldofinterestexample']),
    });
  }

  public getFieldOfInterestIndividual(field) {
    return new FormGroup({
      fieldofinterestid: new FormControl(field['fieldofinterestid']),
      fieldofinterestname: new FormControl(field['fieldofinterestname']),
      istrainable: new FormControl(field['istrainable']),
      fieldorder: new FormControl(field['fieldorder']),
      fieldtypeid: new FormControl(field['fieldtypeid']),
      entity: new FormControl(field['entity']),
      key: this.getStringsArray(field, 'key'),
      createdbyuser: new FormControl(field['createdbyuser']),
      fieldofinterestdescription: new FormControl(field['fieldofinterestdescription']),
      fieldofinterestexample: new FormControl(field['fieldofinterestexample']),
      fieldofinterestquestion: new FormControl(field['fieldofinterestquestion']),

    });
  }

  public addNewFieldTable(arr) {
    const headers = new FormArray([]);
    const notHeaders = new FormArray([]);
    const headerAlgorithm = new FormArray([]);
    this.addNewInputNameControl(headers);
    this.addNewInputNameControl(notHeaders);
    this.addNewInputNameControl(headerAlgorithm);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl(true, Validators.required),
        headers: headers,
        not_headers: notHeaders,
        headers_algorithm: headerAlgorithm,
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', Validators.required),
        fieldofinterestexample: new FormControl('', Validators.required),
        fieldofinterestdescription: new FormControl('', Validators.required),
        fieldofinterestquestion: new FormControl()
      }),
      Validators.required
    );
  }

  public addNewFieldParagraph(arr) {
    const keys = new FormArray([]);
    this.addNewInputNameControl(keys);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl(true, Validators.required),
        key: keys,
        entity: new FormControl(''),
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', [
          Validators.required,
          Validators.min(0)
        ]),
        fieldofinterestexample: new FormControl('', Validators.required),
        fieldofinterestdescription: new FormControl('', Validators.required)
      })
    );
  }

  public addNewFieldEntity(arr) {
    const keys = new FormArray([]);
    this.addNewInputNameControl(keys);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl(true, Validators.required),
        key: keys,
        entity: new FormControl(''),
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', [
          Validators.required,
          Validators.min(0)
        ]),
        fieldofinterestexample: new FormControl('', Validators.required),
        fieldofinterestdescription: new FormControl('', Validators.required)
      })
    );
  }

  public addNewFieldIndividual(arr) {
    const keys = new FormArray([]);
    this.addNewInputNameControl(keys);
    arr.push(
      new FormGroup({
        fieldofinterestid: new FormControl(''),
        fieldofinterestname: new FormControl('', Validators.required),
        istrainable: new FormControl(true, Validators.required),
        key: keys,
        entity: new FormControl(''),
        fieldtypeid: new FormControl('', Validators.required),
        fieldorder: new FormControl('', [
          Validators.required,
          Validators.min(0)
        ]),
        fieldofinterestexample: new FormControl('', Validators.required),
        fieldofinterestdescription: new FormControl('', Validators.required),
        fieldofinterestquestion: new FormControl()
      })
    );
  }

  public addControlKeyTable(form) {
    const fields = new FormArray([]);
    this.addNewFieldTable(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addNewInputNameControl(arr, validator ? ) {
    if (validator) {
      const control = new FormControl('', Validators.required);
      control.markAsTouched();
      arr.push(control);
    } else {
      arr.push(new FormControl(''));
    }
  }

  public addControlIndividual(form) {
    const fields = new FormArray([]);
    this.addNewFieldIndividual(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addControlMultiple(form) {
    const fields = new FormArray([]);
    this.addNewFieldIndividual(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addControlParagraph(form) {
    const fields = new FormArray([]);
    this.addNewFieldParagraph(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public addControlEntity(form) {
    const fields = new FormArray([]);
    this.addNewFieldEntity(fields);
    form.addControl('fieldsofinterest', fields);
  }

  public deleteControlKey(form, value) {
    if (form.get(value)) {
      form.removeControl(value);
    }
  }
}
