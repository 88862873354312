import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { DocumentThumbnailsProvider } from '../document-thumbnails/document-thumbnails-provider';
import { Thumbnail } from '../document-thumbnails/document-thumbnails.component';
import { PagesSelection } from '../modals/update-children/pages-selection';
import { DocumentRenderService } from './document-render.service';
import { OpenaiInfoComponent } from '../modals/openai-info/openai-info.component';
import * as wjcCore from '@grapecity/wijmo';
import * as wjcXlsx from '@grapecity/wijmo.xlsx';
import { createReadStream } from 'fs';
import * as classificationConst from '../../constants/ChunkClassificationConstants';
@Component({
  selector: 'app-document-render',
  templateUrl: './document-render.component.html',
  styleUrls: ['./document-render.component.scss'],
  host: {
    '(window:resize)': 'getImageContainerSize($event)'
  }
})
export class DocumentRenderComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() pagesSelection: PagesSelection;
  @Input() documentDetails: any;

  @Output() thumbnailsError = new EventEmitter<void>();

  public thumbnailsProvider: DocumentThumbnailsProvider;
  public thumbnails: Thumbnail[] = [];
  public disableThumbnails: boolean;
  public images: any = {};
  public pages: number = 1;
  public chunks: any = {};
  public activeChunks: Array<any> = [];
  public markedChunks: Array<any> = [];
  public markedChunksDocs: Array<any> = [];
  public currentPage: number = 1;
  public scrollFromTop: number = 0;
  public isLoadingChunks: boolean = true;
  public isRefreshingChunks: boolean = false; // Dynamic chunks loading
  public isSelectingChunk: boolean = false;
  public isSearching: boolean = false;
  public isLoadingImages: boolean = false;
  public isLoadingExcel: boolean = false;
  public isTryingToLoadImages: boolean = true;
  public emptyAllChunks: boolean = false;
  public searchString: string = '';
  public isFullScreenActive: boolean = false;
  public enableOpenAI: boolean = false;
  public openaiAnswer: String = '';
  public showOpenaiAnswerBox: boolean = false;
  public showOpenaiToggle: boolean = false;
  public openaiWarnings: Array<number> = [];
  public isAmbiguous: boolean = false;

  public getCurrentPageSub: Subscription;
  public getScrollSub: Subscription;
  public getThumbnailsProviderSub: Subscription;
  public getThumbnailsSub: Subscription;
  public getImagesSub: Subscription;
  public getPagesSub: Subscription;
  public chunksWatcherSub: Subscription;
  public getMarkedChunksSub: Subscription;
  public getMarkedChunksDocsSub: Subscription;
  public getIsSelectingChunkSub: Subscription;
  public getIsLoadingChunksSub: Subscription;
  public getIsSearchingChunksSub: Subscription;
  public getIsLoadingImagesSub: Subscription;
  public getIsTryingToLoadImagesSub: Subscription;
  public getActiveChunks: Subscription;
  public getBoldLineSub: Subscription;
  public getAnswerSub: Subscription;
  public getOpenaiWarningsSub: Subscription;

  workbook: wjcXlsx.Workbook;
  sheetIndex: number;

  @ViewChild('imageContainer') imageContainer: ElementRef;
  public isChatBotVisible: boolean = false;

  constructor(public documentRenderService: DocumentRenderService) {
    this.getCurrentPageSub = this.documentRenderService
      .getCurrentPage()
      .subscribe(obj => {
        this.currentPage = obj.pagenum;
      });

    this.getAnswerSub = this.documentRenderService
      .getOpenaiAnswer()
      .subscribe(obj => {
        this.openaiAnswer = obj.answer;
      });

    this.getOpenaiWarningsSub = this.documentRenderService
      .getOpenaiWarnings()
      .subscribe(obj => {
        this.openaiWarnings = obj.confidence;
        this.checkOpenaiWarnings();
      });

    this.getScrollSub = this.documentRenderService
      .getScroll()
      .subscribe(obj => {
        this.scrollImagesContainer(obj.height);
      });

    this.getImagesSub = this.documentRenderService
      .getImages()
      .subscribe(obj => {
        this.images = obj.images;
        if (
          this.documentDetails.datamodel.viewerformat == 'excel' &&
          Object.keys(this.images).length != 0
        ) {
          this.isLoadingExcel = true;
          this._loadWorkbook();
        }
      });

    this.getThumbnailsProviderSub = this.documentRenderService
      .getThumbnailsProvider()
      .subscribe((thumbnailsProvider: DocumentThumbnailsProvider) => {
        this.thumbnailsProvider = thumbnailsProvider;

        this.getThumbnailsSub?.unsubscribe();

        if (this.thumbnailsProvider) {
          this.getThumbnailsSub = this.thumbnailsProvider
            .getThumbnails()
            .subscribe(obj => {
              this.thumbnails = obj.thumbnails;
              this.checkThumbnailErrors();
            });

          this.thumbnailsProvider.triggerThumbnails();
        } else {
          this.thumbnails = [];
        }
      });

    this.getPagesSub = this.documentRenderService.getPages().subscribe(obj => {
      this.pages = obj.pages;
    });

    this.chunks = this.documentRenderService.getChunks();

    this.documentRenderService.chunksWatcher().subscribe(obj => {
      if (
        this.chunks['1'][0].chunkclassid ===
        classificationConst.ID_CLASSIFICATION_EMBEDDING
      ) {
        this.showOpenaiToggle = true;
      }
    });

    if (
      Object.keys(this.chunks).length === 0 &&
      !this.documentRenderService.getChunksCheck()
    ) {
      this.chunksWatcherSub = this.documentRenderService
        .chunksWatcher()
        .subscribe(obj => {
          this.chunks = this.documentRenderService.getChunks();
          this.isLoadingChunks = false;
        });
    } else {
      this.isLoadingChunks = false;
    }

    this.getMarkedChunksSub = this.documentRenderService
      .getMarkedChunks()
      .subscribe(obj => {
        this.markedChunks = this.documentRenderService.getUniqueChunks(
          obj.chunks
        );
      });

    this.getMarkedChunksDocsSub = this.documentRenderService
      .getMarkedChunksDocs()
      .subscribe(obj => {
        this.markedChunksDocs = Object.keys(obj.chunks).map(documentid => ({
          documentid,
          chunks: obj.chunks[documentid]
        }));
      });

    this.getIsLoadingChunksSub = this.documentRenderService
      .getIsLoadingChunks()
      .subscribe(obj => {
        this.isRefreshingChunks = obj.state;
      });

    this.getIsSelectingChunkSub = this.documentRenderService
      .getIsSelectingChunk()
      .subscribe(obj => {
        this.isSelectingChunk = obj.state;
      });

    this.getIsSearchingChunksSub = this.documentRenderService
      .getIsSearchingChunks()
      .subscribe(obj => {
        this.isSearching = obj.state;
      });

    this.getIsLoadingImagesSub = this.documentRenderService
      .getIsLoadingImages()
      .subscribe(obj => {
        this.isLoadingImages = obj.state;
      });

    this.getIsTryingToLoadImagesSub = this.documentRenderService
      .getIsTryingToLoadImages()
      .subscribe(obj => {
        this.isTryingToLoadImages = obj.state;
      });

    this.getActiveChunks = this.documentRenderService
      .getActiveChunks()
      .subscribe(obj => {
        this.activeChunks =
          obj.chunks.length > 0 && this.chunks[this.currentPage]
            ? this.chunks[this.currentPage].filter(
                chunk => obj.chunks.indexOf(chunk.chunkid) > -1
              )
            : [];
      });
  }

  ngOnInit() {
    // Trigger document-render data if is already loaded
    // Case use: you are navigating between different tabs of the same document
    this.documentRenderService.triggerAll();
  }

  ngOnDestroy() {
    // Remove subscriptions
    if (this.getIsTryingToLoadImagesSub) {
      this.getIsTryingToLoadImagesSub.unsubscribe();
    }
    if (this.getCurrentPageSub) {
      this.getCurrentPageSub.unsubscribe();
    }
    if (this.getScrollSub) {
      this.getScrollSub.unsubscribe();
    }
    if (this.getThumbnailsProviderSub) {
      this.getThumbnailsProviderSub.unsubscribe();
    }
    if (this.getThumbnailsSub) {
      this.getThumbnailsSub.unsubscribe();
    }
    if (this.getImagesSub) {
      this.getImagesSub.unsubscribe();
    }
    if (this.getPagesSub) {
      this.getPagesSub.unsubscribe();
    }
    if (this.chunksWatcherSub) {
      this.chunksWatcherSub.unsubscribe();
    }
    if (this.getMarkedChunksSub) {
      this.getMarkedChunksSub.unsubscribe();
    }
    if (this.getMarkedChunksDocsSub) {
      this.getMarkedChunksDocsSub.unsubscribe();
    }
    if (this.getIsLoadingChunksSub) {
      this.getIsLoadingChunksSub.unsubscribe();
    }
    if (this.getIsSelectingChunkSub) {
      this.getIsSelectingChunkSub.unsubscribe();
    }
    if (this.getIsSearchingChunksSub) {
      this.getIsSearchingChunksSub.unsubscribe();
    }
    if (this.getActiveChunks) {
      this.getActiveChunks.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.getImageContainerSize();
  }

  public reloadExcel(images) {
    this.images = images;
    this._loadWorkbook();
  }
  private _loadWorkbook() {
    let file = this.images[this.currentPage];
    let workbook = new wjcXlsx.Workbook();
    workbook.loadAsync(
      <string>file,
      (result: wjcXlsx.Workbook) => {
        this.workbook = result;
        this._drawSheet(this.workbook.activeWorksheet || 0);
        this.isLoadingExcel = false;
      },
      function (reason) {}
    );
  }

  private _drawSheet(sheetIndex: number) {
    let drawRoot = document.getElementById('tableHost');
    drawRoot.textContent = '';
    this.sheetIndex = sheetIndex;
    this._drawWorksheet(this.workbook, sheetIndex, drawRoot, 1000000, 1000000);
  }

  private _drawWorksheet(
    workbook: wjcXlsx.IWorkbook,
    sheetIndex: number,
    rootElement: HTMLElement,
    maxRows: number,
    maxColumns: number
  ) {
    if (
      !workbook ||
      !workbook.sheets ||
      sheetIndex < 0 ||
      workbook.sheets.length == 0
    ) {
      return;
    }
    sheetIndex = Math.min(sheetIndex, workbook.sheets.length - 1);
    if (maxRows == null) {
      maxRows = 1000000;
    }
    if (maxColumns == null) {
      maxColumns = 100;
    }
    let sheet = workbook.sheets[sheetIndex],
      defaultRowHeight = 20,
      defaultColumnWidth = 60,
      tableEl = document.createElement('table');

    tableEl.border = '1';
    tableEl.style.borderCollapse = 'collapse';
    tableEl.style.background = 'white';

    let maxRowCells = 0;
    for (let r = 0; sheet.rows && r < sheet.rows.length; r++) {
      if (sheet.rows[r] && sheet.rows[r].cells) {
        maxRowCells = Math.max(maxRowCells, sheet.rows[r].cells.length);
      }
    }

    let columns = sheet.columns || [],
      invisColCnt = columns.filter(col => col.visible === false).length;

    if (sheet.columns) {
      maxRowCells = Math.min(Math.max(maxRowCells, columns.length), maxColumns);

      for (let c = 0; c < maxRowCells; c++) {
        let col = columns[c];

        if (col && !col.visible) {
          continue;
        }

        let colEl = document.createElement('col');
        tableEl.appendChild(colEl);
        let colWidth = defaultColumnWidth + 'px';
        if (col) {
          this._importStyle(colEl.style, col.style);
          if (col.autoWidth) {
            colWidth = '';
          } else if (col.width != null) {
            colWidth = col.width + 'px';
          }
        }
        colEl.style.width = colWidth;
      }
    }

    let rowCount = Math.min(maxRows, sheet.rows.length);
    for (let r = 0; sheet.rows && r < rowCount; r++) {
      let row = sheet.rows[r],
        cellsCnt = 0;

      if (row && !row.visible) {
        continue;
      }

      let rowEl = document.createElement('tr');
      tableEl.appendChild(rowEl);

      if (row) {
        this._importStyle(rowEl.style, row.style);
        rowEl.style.color = '#2e2e38';
        if (row.height != null) {
          rowEl.style.height = row.height + 'px';
        }

        for (let c = 0; row.cells && c < row.cells.length; c++) {
          let cell = row.cells[c],
            cellEl = document.createElement('td'),
            col = columns[c];

          if (col && !col.visible) {
            continue;
          }

          cellsCnt++;

          rowEl.appendChild(cellEl);
          if (cell) {
            this._importStyle(cellEl.style, cell.style);
            let value = cell.value;
            if (!(value == null || value !== value)) {
              if (wjcCore.isString(value) && value.charAt(0) == "'") {
                value = value.substr(1);
              }
              let netFormat = '';
              if (cell.style && cell.style.format) {
                netFormat = wjcXlsx.Workbook.fromXlsxFormat(
                  cell.style.format
                )[0];
              }
              let fmtValue = netFormat
                ? wjcCore.Globalize.format(value, netFormat)
                : value;
              cellEl.innerHTML = wjcCore.escapeHtml(fmtValue);
            }

            if (cell.colSpan && cell.colSpan > 1) {
              cellEl.colSpan = this._getVisColSpan(columns, c, cell.colSpan);
              cellsCnt += cellEl.colSpan - 1;
              c += cell.colSpan - 1;
            }

            if (cell.note) {
              wjcCore.addClass(cellEl, 'cell-note');
              cellEl.title = cell.note.text;
            }
          }
        }
      }

      let padCellsCount = maxRowCells - cellsCnt - invisColCnt;
      for (let i = 0; i < padCellsCount; i++) {
        rowEl.appendChild(document.createElement('td'));
      }

      if (!rowEl.style.height) {
        rowEl.style.height = defaultRowHeight + 'px';
      }
    }

    rootElement.appendChild(tableEl);
    this.isLoadingImages = false;
  }

  private _getVisColSpan(
    columns: wjcXlsx.IWorkbookColumn[],
    startFrom: number,
    colSpan: number
  ) {
    let res = colSpan;

    for (
      let i = startFrom;
      i < columns.length && i < startFrom + colSpan;
      i++
    ) {
      let col = columns[i];
      if (col && !col.visible) {
        res--;
      }
    }

    return res;
  }

  private _importStyle(
    cssStyle: CSSStyleDeclaration,
    xlsxStyle: wjcXlsx.IWorkbookStyle
  ) {
    if (!xlsxStyle) {
      return;
    }
    if (xlsxStyle.fill) {
      if (xlsxStyle.fill.color) {
        cssStyle.backgroundColor = xlsxStyle.fill.color;
      }
    }
    if (xlsxStyle.hAlign && xlsxStyle.hAlign != wjcXlsx.HAlign.Fill) {
      cssStyle.textAlign = wjcXlsx.HAlign[xlsxStyle.hAlign].toLowerCase();
    }
    let font = xlsxStyle.font;
    if (font) {
      if (font.family) {
        cssStyle.fontFamily = font.family;
      }
      if (font.bold) {
        xlsxStyle.font.color = '#ffe600';
        cssStyle.backgroundColor = '#2e2e38';
        cssStyle.borderColor = 'white';
      }
      if (font.italic) {
        cssStyle.fontStyle = 'italic';
      }
      if (font.size != null) {
        cssStyle.fontSize = font.size + 'px';
      }
      if (font.underline) {
        cssStyle.textDecoration = 'underline';
      }
      if (font.color) {
        cssStyle.color = font.color;
      }
    }
  }
  tabClicked(e: MouseEvent, index: number) {
    e.preventDefault();
    this._drawSheet(index);
  }

  /**
   * Disable thumbnails if any one has error set.
   */
  private checkThumbnailErrors() {
    this.disableThumbnails = false;

    const hasThumbnailError = this.thumbnails.some(
      (thumbnail: Thumbnail) => thumbnail.error
    );

    if (hasThumbnailError) {
      this.disableThumbnails = true;
      this.thumbnailsError.emit();
    }
  }

  public emptyInput() {
    this.searchString = '';
    this.documentRenderService.emptyActiveChunks();
    this.documentRenderService.clearMarkedChunks();
  }

  public removeMarkedChunks() {
    this.documentRenderService.emptyMarkedChunks();
  }

  /**
   * Get container image size
   */
  public getImageContainerSize() {
    const width = this.imageContainer.nativeElement.offsetWidth;
    this.documentRenderService.setContainerWidth(width);
  }

  /**
   * Scroll image viewer to desired position
   * @param height position (from top) to scroll
   */
  public scrollImagesContainer(height: number) {
    this.scrollFromTop = height;
    this.imageContainer.nativeElement.scrollTop = height
  }

  /**
   * Handle search input
   * @param text text to search
   */
  public search(text: string) {
    if (text === '') {
      return;
    }

    if (this.enableOpenAI) {
      this.showOpenaiAnswerBox = true;
    } else {
      this.showOpenaiAnswerBox = false;
    }

    this.resetOpenaiValues();
    this.documentRenderService.emitSearch();
    this.documentRenderService.searchChunks(text, this.enableOpenAI);
  }

  /**
   *Toggle the OpenAI search mode
   */
  public toggleOpenAI() {
    this.enableOpenAI = !this.enableOpenAI;
  }

  public toggleChatBot() {
    this.isChatBotVisible = !this.isChatBotVisible;
  }

  /**
   * Handle chunk navigation
   * @param asc next (true) or previus (false) chunk to show
   */
  public navigateChunk(asc: boolean = true) {
    this.documentRenderService.navigateMarkedChunks(asc);
  }

  /**
   * Close chunk navigation
   */
  public closeChunksNavigation() {
    this.documentRenderService.emptyMarkedChunks();
    this.searchString = '';
  }

  /**
   * Handle page navigation
   * @param pageToNavigate page number to go
   */
  public navigatePage(pageToNavigate) {
    if (pageToNavigate >= 1 && pageToNavigate <= this.pages) {
      this.documentRenderService.goToPage(pageToNavigate);
    }
  }

  /**
   * Check if chunk is active
   * @param chunk chunk to check
   */
  public isChunkActive(chunk) {
    return this.documentRenderService.isChunkActive(chunk);
  }

  /**
   * Select chunk
   * @param chunk chunk to select
   */
  public selectChunk(chunk) {
    if (this.isSelectingChunk) {
      this.documentRenderService.setSelectedChunk(chunk);
    }
  }

  /**
   * Toggle full screen mode
   */
  public toggleFullScreenMode() {
    this.isFullScreenActive = !this.isFullScreenActive;
    this.documentRenderService.setFullScreen(this.isFullScreenActive);
  }

  public getMarkedIndex() {
    if (this.activeChunks.length === 0 || !this.activeChunks[0].chunkid) {
      return -1;
    }
    const activeChunkId = this.activeChunks[0].chunkid;
    return this.markedChunks.map(chunk => chunk.chunkid).indexOf(activeChunkId);
  }

  public getMultipleDocsIndex() {
    return this.markedChunksDocs
      .map(obj => +obj.documentid)
      .indexOf(+this.documentRenderService.getDocumentId());
  }

  public nextDocument() {
    const currentDocIdx = this.getMultipleDocsIndex();
    const currentDocument =
      currentDocIdx < this.markedChunksDocs.length - 1
        ? this.markedChunksDocs[currentDocIdx + 1]
        : this.markedChunksDocs[0];
    const pagenum = currentDocument.chunks
      .map((c: any) => c.pagenumber)
      .sort((a, b) => a - b)[0];
    this.documentRenderService.setDocumentId(+currentDocument.documentid);
    this.documentRenderService.setPages(pagenum);
    this.documentRenderService.updateStartDocumentThumbnailsProvider();
    this.documentRenderService.getDocumentImagesFromDB(pagenum).then(() => {
      this.documentRenderService.updateDocumentChunks(
        this.chunks[this.currentPage].map(c => ({ ...c, fromExtraction: true }))
      );
      this.documentRenderService.emptyActiveChunks();
      this.documentRenderService.updateDocumentChunks(currentDocument.chunks);
      this.documentRenderService.markChunksFromMultipleDocuments(
        this.markedChunksDocs.reduce((acc, o) => {
          acc[o.documentid] = o.chunks;
          return acc;
        }, {})
      );
      this.documentRenderService.goToFirstChunksPage(currentDocument.chunks);
    });
  }

  public isLoadingClass() {
    return (
      this.isLoadingChunks ||
      this.isRefreshingChunks ||
      this.isSearching ||
      this.isLoadingImages
    );
  }

  public isDisabledClass() {
    return this.isLoadingClass() || this.isSelectingChunk;
  }

  /**Check if the document is electronic or if it is an electronic child. in that case dont load the image
   */
  public checkIsElectronic(): boolean {
    if (
      this.documentDetails.isparentdocument &&
      this.documentDetails.datamodel.iselectronic
    ) {
      return true;
    }

    if (this.documentDetails.parentdocumentdatamodel !== undefined) {
      if (
        !this.documentDetails.isparentdocument &&
        this.documentDetails.parentdocumentdatamodel['datamodel'].iselectronic
      ) {
        return true;
      }
    }

    return false;
  }

  public checkOpenaiWarnings(): void {
    if (this.openaiWarnings[2] > 0.55) {
      this.isAmbiguous = true;
    }
  }

  public resetOpenaiValues() {
    this.isAmbiguous = false;
    this.openaiAnswer = '';
  }

  public searchMessage(text) {
    this.enableOpenAI = true;
    this.search(text)
  }
}
