import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    SimpleChanges,
    ViewChild,
    ElementRef,
    HostListener
} from '@angular/core';

@Component({
    selector: 'app-chat-bot',
    templateUrl: './chat-bot.component.html',
    styleUrls: ['./chat-bot.component.scss'],
})
export class ChatBotComponent implements OnInit {
    @Input() isVisible: boolean = false;
    @Input() openaiAnswer: string = undefined;
    @Output() closeChat = new EventEmitter < void > ();
    @Output() searchMessage = new EventEmitter < String > ();
    messages: {
        text: string;sender: string,
        icon: string
    } [] = [];
    newMessage: string = '';
    private isNewBotMessage: boolean = false;

    public isDragging = false;
    public initialX = 0;
    public offsetX = 0;
    public maxWidth = 0;

    @ViewChild('draggableContainer') draggableContainer!: ElementRef;

    @ViewChild('messagesContainer') private messagesContainer!: ElementRef;

    @ViewChild('dragHandle') dragHandle!: ElementRef;

    ngOnInit(): void {
        this.messages.push({
            text: 'Hi! Ask me something about the document and i will try to help.',
            sender: 'bot',
            icon: 'assets/img/favicon-s.ico'
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['openaiAnswer'] && Boolean(this.openaiAnswer)) {
            this.messages.push({
                text: this.openaiAnswer,
                sender: 'bot',
                icon: 'assets/img/favicon-s.ico'
            });
            this.isNewBotMessage = true;
        }
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    ngAfterViewInit() {
        this.maxWidth = window.innerWidth;
        const chatbotWidth = this.draggableContainer.nativeElement.offsetWidth;
        this.offsetX = -20;  // Initial offset to the right side with 20px margin
    }


    sendMessage() {
        if (this.newMessage.trim()) {
            this.messages.push({
                text: this.newMessage,
                sender: 'user',
                icon: 'person'
            });
            this.searchMessage.emit(this.newMessage)
            this.newMessage = '';
        }
    }

    closeChatHandler() {
        this.closeChat.emit();
    }

    // Método para desplazarse hasta el final del contenedor
    private scrollToBottom(): void {
        if (this.isNewBotMessage) {
            // Activa la clase de transición
            this.messagesContainer.nativeElement.classList.add('smooth-scroll');
            // Desplaza hasta el final
            this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
            // Resetea la bandera después de desplazarse
            this.isNewBotMessage = false;

            // Elimina la clase de transición después de la animación
            setTimeout(() => {
                this.messagesContainer.nativeElement.classList.remove('smooth-scroll');
            }, 300); // Duración de la transición en milisegundos (ajustable)
        }
    }

    public startDrag(event: MouseEvent) {
        this.isDragging = true;
        this.initialX = event.clientX - this.offsetX;
        this.dragHandle.nativeElement.classList.add('grabbing'); // Remove grabbing class
        event.preventDefault(); // Prevents text selection while dragging
    }

    @HostListener('document:mouseup')
    stopDrag() {
        this.isDragging = false;
        this.dragHandle.nativeElement.classList.remove('grabbing'); // Remove grabbing class
    }

    @HostListener('document:mousemove', ['$event'])
    onDrag(event: MouseEvent) {
        if (!this.isDragging) return;

        let x = event.clientX - this.initialX;
        const chatbotWidth = this.draggableContainer.nativeElement.offsetWidth;
        const maxDragX = this.maxWidth - chatbotWidth - 1250; // 1250 from the right edge
        const minDragX = -this.maxWidth + chatbotWidth + 250; // 250 from the left edge
    
        // Constrain movement within the boundaries
        if (x > maxDragX) x = maxDragX;
        if (x < minDragX) x = minDragX;
    
        this.offsetX = x;
    }

}