import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { AnalysisModel } from '../../../models/analysis.model';
import { DeleteModalComponent } from '../../../components/modals/delete/delete.component';
import { ApiService } from '../../../services/api.service';
import { PaginationModel } from '../../../models/pagination';
import { AuthenticationService } from 'src/app/security/authentication.service';
import { LinkService } from 'src/app/services/link.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { Observable, Subject, Subscription, concat } from 'rxjs';
import { takeLast } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
//
import { ValidationSearchService } from '../../../components/validations-filter/validation-search.service';
import { DocumentModel } from 'src/app/models/document.model';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-project-analysis-view',
  templateUrl: './project-analysis-view.component.html',
  styleUrls: ['./project-analysis-view.component.scss']
})
export class ProjectAnalysisViewComponent implements OnInit {
  public user: any;
  public messages: any;
  public loading: boolean;
  public backgroundLoading: boolean;
  private pagination: PaginationModel;
  private newAnalysisSub: any;
  public columns: any[];
  public settingTable: any;
  public rows: {};

  @Input() paginationType: string;
  @Input() params: {} = [];

  public analysisId: number;
  public totalAnalysis: number = 0;
  public showValidations: boolean = false;

  //Filter variables
  public filters: any = [];
  public labelFilters: any[];
  public showFilters: boolean = false;
  public statusList = [];
  public datamodelList;

  //Document to consolidate variables
  public itemsSelected = [];
  public countItemsSelected: number;
  public responseTableData = {};
  public projectid: number;

  //validation variable
  public subscriptionReviewers: Subscription;

  //Property to emit filter to delete in the validations component
  public filterEvent: Subject<string> = new Subject<string>();

  // Const
  public _userPermissions: any;

  @Input() projectId: number | string;
  @Input() addItemEvent: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private paginationService: PaginationService,
    private global: GlobalService,
    public link: LinkService,
    private translate: TranslatePipe,
    //
    public validationSearchService: ValidationSearchService
  ) {
    this.user = this.authService.getLoggedInUser();
    this._userPermissions = this.global.getUserPermissionsConst();
    this.getColumns();
    this.getSettingTable();
  }

  ngOnInit() {
    this.pagination = this.paginationService.getDefaultPagination('analysis');
    this.getAnalysis();
    this.newAnalysisSub = this.addItemEvent.subscribe((analysis: any) => {
      this.loading = true;
      this.getAnalysisFromDB();
    });

    this.statusList = this.global.getGlobalStatus();
    this.datamodelList = Array.from(this.global.getDatamodels().values());

    if (!this.statusList && this.datamodelList.length === 0) {
      this.subscriptionReviewers = concat(
        this.global.watchDataModels(),
        this.global.watchUtils()
      ).subscribe(() => {
        this.datamodelList = Array.from(this.global.getDatamodels().values());
        this.statusList = this.global.getGlobalStatus();
      });
    } else if (this.datamodelList.length === 0) {
      this.subscriptionReviewers = this.global
        .watchDataModels()
        .subscribe(() => {
          this.datamodelList = Array.from(this.global.getDatamodels().values());
        });
    } else if (!this.statusList) {
      this.subscriptionReviewers = this.global.watchUtils().subscribe(() => {
        this.statusList = this.global.getGlobalStatus();
      });
    }
  }

  /**
   * Get analysis from storage and database
   */
  private getAnalysis() {
    const analysisPage = this.paginationService.getPage('analysis');
    if (
      analysisPage &&
      analysisPage.data.length &&
      analysisPage.data[0].project.projectid == this.projectId &&
      analysisPage.page == 1 &&
      analysisPage.order_by == 'analysisid' &&
      analysisPage.desc === 1
    ) {
      // If analysis are loaded, show them and hide spinner
      this.rows = analysisPage;
      this.loading = false;
      this.backgroundLoading = true;
    } else {
      // If analysis aren't loaded, show spinner
      this.loading = true;
    }
    // Always request analysis from db
    this.getAnalysisFromDB();
  }

  /**
   * Function that returns the inner logic text value
   */
  public filterTextValue(logic: string) {
    switch (logic) {
      case 'or':
        return this.translate.transform('documentFilter.or');
      case 'and':
        return this.translate.transform('documentFilter.and');
      case 'phrase':
        return this.translate.transform('documentFilter.phrase');
      case 'starts_with':
        return this.translate.transform('documentFilter.startsWith');
      case 'ends_with':
        return this.translate.transform('documentFilter.endsWith');
      default:
        return logic;
    }
  }

  /**
   * Get analysis from database
   */
  private getAnalysisFromDB() {
    let params = { ...this.pagination, ...this.filters };
    params['projectid'] = this.projectId;
    this.apiService
      .get('analysis/', params, '').pipe(
      takeLast(1))
      .subscribe(
        (data: {
          total_elements: number;
          page: number;
          page_size: number;
          num_pages: number;
          order_by: string;
          desc: number;
          data: AnalysisModel[];
        }) => {
          this.rows = data;
          this.totalAnalysis = data.total_elements;
          this.paginationService.setPage('analysis', data);
          this.pagination = this.paginationService.setPagination('analysis', {
            total_elements: data.total_elements,
            page: data.page,
            page_size: data.page_size,
            num_pages: data.num_pages,
            order_by: data.order_by,
            desc: data.desc,
            query: this.pagination.query
          });
          this.loading = false;
          this.backgroundLoading = false;
        },
        () => {
          this.loading = false;
          this.backgroundLoading = false;
        }
      );
  }

  /**
   * Go to the analysis with the information pass by params
   */

  /**
   * Go to the document with the document information pass by params
   */
  public goToAnalysis(document, flag: boolean = false) {
    this.link.goToAnalysis(document, flag);
  }

  /**
   * Go to the document with the document information pass by params
   * and open link in new tab
   */
  public goToAnalysisNewTab(document) {
    this.link.goToAnalysis(document, true);
  }

  /**
   * Return loaded columns with the information of each column of the table we want to show
   */
  public getColumns() {
    this.columns = [
      {
        header: 'projectAnalysis.analysisName',
        name: 'analysisname',
        type: 'link',
        clickLink: this.goToAnalysis.bind(this),
        orderBy: 'analysisname'
      },
      {
        header: 'projectAnalysis.username',
        name: 'createdbyuser',
        second: 'username'
      },
      {
        header: 'projectAnalysis.date',
        name: 'createddate',
        type: 'date',
        format: 'lll',
        orderBy: 'createddate'
      },
      {
        header: 'projectAnalysis.docs',
        name: 'numberofdocuments',
        class: 'sm-column'
      },
      {
        header: 'responsiveTable.newTab',
        name: 'numberofdocuments',
        type: 'link-fa-icon',
        link: 'external',
        title: 'responsiveTable.newTab',
        class: 'sm-column',
        clickLink: this.goToAnalysisNewTab.bind(this),
        namefunc: rowData => {
          return '<i class="fa fa-external-link"></i>';
        }
      }
    ];
  }

  /**
   * Return loaded SettingTable with the general settings for the component respontable
   */
  public getSettingTable() {
    this.settingTable = {
      dataId: 'analysisid',
      hasSelect: 'true',
      hasEditPermissions: this.hasEditPermissions(),
      getDataFromDB: this.getAnalysisFromDB.bind(this),
      clickLink: this.goToAnalysis.bind(this),
      actionsOnSelected: [
        {
          click: this.deleteSelectedAnalysis.bind(this),
          class: 'fa fa-trash',
          title: 'projectAnalysis.deleteSelected'
        }
      ],
      actionsOnResponsive: [
        {
          click: this.deleteAnalysis.bind(this),
          class: 'fa fa-trash'
        }
      ],
      responsiveTitle: {
        label: 'analysisname'
      }
    };
  }

  /**
   * Load modal to confirm analysis(s) removal
   */
  private deleteAnalysisModal(analysisids: number[]) {
    const modalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'sm'
    };
    const options = {
      type: 'analysis',
      notAllowed: analysisids.length < 1
    };
    const modalWindowRef = this.modalService.open(
      DeleteModalComponent,
      modalOptions
    );
    modalWindowRef.componentInstance.options = options;
    modalWindowRef.result.then(
      result => {
        if (result === 1) {
          this.loading = true;
          const requestbody = { ids: analysisids };
          this.sendDeleteRequest(requestbody);
        }
      },
      reason => {}
    );
  }

  /**
   * Delete single analysis
   */
  public deleteAnalysis(analysis) {
    this.deleteAnalysisModal([analysis.analysisid]);
  }

  /**
   * Delete multiple analysis
   */
  public deleteSelectedAnalysis(selectedItems) {
    this.deleteAnalysisModal(selectedItems);
  }

  /**
   * Send delete request to API endpoint with payload
   */
  private sendDeleteRequest(requestbody: any) {
    this.apiService
      .post('analysis/delete', requestbody, 'projectAnalysis.delete')
      .subscribe(
        () => {
          // Remove analysis from view while request from db
          this.rows['data'] = this.rows['data'].filter(
            el => requestbody.ids.indexOf(el.analysisid) < 0
          );
          this.rows['total_elements'] =
            this.rows['total_elements'] - requestbody.ids.length;
          this.loading = false;
          this.pagination = this.paginationService.changePagination(
            'analysis',
            'page',
            1
          );
          this.getAnalysisFromDB();
        },
        error => {
          this.loading = false;
        }
      );
  }

  /**
   * Check if user has edit permissions
   */
  public hasEditPermissions() {
    return (
      this.user &&
      (this.authService.hasPermission(this._userPermissions.DELETE_ANALYSIS) ||
        this.user.isadmin)
    );
  }

  /**
   * Destroy event handler
   */
  ngOnDestroy() {
    // Unsubscribe from new analysis added event
    this.newAnalysisSub.unsubscribe();
  }

  /**
   * Handle loading data status from the child
   */
  public onLoadingChange(bool) {
    this.loading = bool;
  }

  /**
   * Variable to show the filter panel or not
   */
  public toggleShowFilters() {
    this.showFilters = !this.showFilters;
  }

  public changeSelectedItems(index, data) {
    let newAddition = false;
    if (data) {
      const index_page = data.data.indexOf(index);
      if (!this.responseTableData[data.page]) {
        this.responseTableData[data.page] = {};
      }
      if (this.responseTableData[data.page][index_page]) {
        delete this.responseTableData[data.page][index_page];
      } else {
        if (index_page >= 0) {
          newAddition = true;
          this.responseTableData[data.page][index_page] = data.data[index_page];
        }
      }
    }
    const items = [];

    for (const number_page of Object.keys(this.responseTableData)) {
      for (const number_item of Object.keys(
        this.responseTableData[number_page]
      )) {
        if (
          JSON.stringify(this.responseTableData[number_page][number_item]) ==
            JSON.stringify(index) &&
          !newAddition
        ) {
          delete this.responseTableData[number_page][number_item];
        } else {
          items.push(this.responseTableData[number_page][number_item]);
        }
      }
    }
    this.countItemsSelected = items.length;
    this.itemsSelected = items;
    return items;
  }

  /**
   * Handle background loading data status
   */
  public onBackgroundLoadingChange(bool) {
    this.backgroundLoading = bool;
  }

  public cleanAllSelectedItems() {
    this.itemsSelected.forEach(item => {
      this.changeSelectedItems(item, this.rows);
    });
    this.itemsSelected = [];
  }

  public onSearch(filters) {
    this.onBackgroundLoadingChange(true);
    this.cleanAllSelectedItems();

    this.pagination = this.paginationService.getDefaultPagination('analysis');
    if (this.params['assigned']) {
      this.validationSearchService.setPreviousSearch(
        filters,
        this.paginationType
      );
    }
    if (!this.params['assigned']) {
      this.validationSearchService.setPreviousSearch(
        filters,
        this.paginationType
      );
    }

    this.filters = {
      analysisname:
        filters['analysisname'] !== undefined ? filters['analysisname'] : '',
      createdbyname:
        filters['createdByName'] !== undefined ? filters['createdByName'] : '',
      analysisdisplayname_logic:
        filters['analysisdisplayname_logic'] !== undefined
          ? filters['analysisdisplayname_logic']
          : ''
    };

    this.labelFilters = Object.keys(this.filters)
      .filter(k => this.filters[k] !== '')
      .map(f => {
        switch (f) {
          case 'analysisname':
            return {
              name: 'analysisname',
              value: filters['analysisname']
            };
          case 'createdbyname':
            return {
              name: 'createdbyname',
              value: filters['createdByName']
            };
          default:
            return { name: f, value: this.filters[f] };
        }
      });

    this.getAnalysisFromDB();
  }

  public cleanIndividualFilter(filter: string){
    this.filterEvent.next(filter);
    this.filters[filter] = ''; 

    if(filter === 'analysisname'){
      this.filters['analysisdisplayname_logic'] = '';
      this.labelFilters = this.labelFilters.filter(f => f.name !== 'analysisdisplayname_logic');
    }

    this.labelFilters = this.labelFilters.filter(f => f.name !== filter);
    this.onBackgroundLoadingChange(true);
    this.getAnalysisFromDB();
  }
}
