import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, AuthenticationType } from 'src/app/security/authentication.service';
import * as userPermissionsConst from '../../constants/userPermissions';

@Component({
  selector: 'app-not-allowed',
  templateUrl: './not-allowed.component.html',
  styleUrls: ['./not-allowed.component.scss']
})

export class NotAllowedComponent  {
  public message: string = 'You do not have permission to access this resource. Please contact a Smart Retrieval administrator for assistance.';
  public loading: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthenticationService
  ) {
  }

  onLogout() {
    this.loading = true;
    this.authService.logout();
  }
}
