import { Component, OnInit, Input } from '@angular/core';
import { FormArray } from '@angular/forms';
import { TrainableService } from '../../trainable.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-trainable-individual',
  templateUrl: './trainable-individual.component.html',
  styleUrls: ['./trainable-individual.component.scss']
})
export class TrainableIndividualComponent implements OnInit {
  @Input() public individual: FormArray;
  @Input('formSubmitted') public formSubmitted: boolean;
  @Input('genericDatamodel') public genericDatamodel: boolean;
  @Input('isCreating') public isCreating: boolean;
  @Input('datamodelProcessType') public datamodelProcessType: String;
  @Input('datamodelLang') public datamodelLang: String;

  constructor(
    private translate: TranslatePipe,
    public trainableService: TrainableService
  ) {}

  ngOnInit() {
  }

  public addField() {
    this.trainableService.addField(this.individual, this.trainableService.GOFTYPESIDS.INDIVIDUAL);
  }

  public addKey(form, validator?) {
    this.trainableService.addNewInputNameControl(form, validator);
  }

  public removeKey(form, index) {
    this.trainableService.removeInputControl(form, index);
  }
}
