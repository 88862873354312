import {
    Component,
    OnDestroy,
    OnInit,
    Input
} from '@angular/core';
import {
    FormGroup,
    FormControl,
    FormArray,
    Validators
} from '@angular/forms';

import {
    GlobalService
} from 'src/app/services/global.service';
import {
    ValidationService
} from '../common-components/validation.service';

import {
    BusinessService
} from '../business-components/business.service';
import {
    TranslatePipe
} from 'src/app/pipes/translate.pipe';
import {
    CatalogService
} from '../catalog-components/catalog.service';

@Component({
    selector: 'app-case-root',
    templateUrl: './case-root.component.html',
    styleUrls: ['./case-root.component.scss']
})
export class CaseRootComponent implements OnInit, OnDestroy {

    @Input('formTest') formTest: FormControl;
    @Input('caseName') caseName: String;
    @Input('caseErrors') caseErrors: Boolean;
    @Input('casesIndex') casesIndex: number;
    @Input('casesCtrl') casesCtrl: FormControl;

    public isCollapsed: boolean = true;
    public datamodels: any[] = [];

    constructor(
        public global: GlobalService,
        public validationService: ValidationService,
        public businessService: BusinessService,
        private translate: TranslatePipe,
        public catalogService: CatalogService,
    ) {}

    ngOnInit() {
      this.datamodels = []; 
    }

    ngOnDestroy(): void {

    }

    public removeCasePreConditions(caseIndex: number) {
        const cases = this.formTest.get('cases') as FormArray;
        const conditions = cases.controls[caseIndex] as FormGroup;
        const case_conditions = conditions.controls.preconditions as FormArray;
        case_conditions.clear();
    }


    /**
     * Description: Adds new Pre Condition to Case i
     * @param caseIndex index of Case
     * @returns void
     */
    public addCasePreCondition(caseIndex: number) {
        const cases = this.formTest.get('cases') as FormArray;
        const case_to_add = cases.controls[caseIndex] as FormGroup;
        const case_conditions = case_to_add.controls.preconditions as FormArray;
        const precondition = this.getPreCondition();
        case_conditions.push(precondition);
    }

    public getPreCondition() {
        if (this.validationService.getValidationTypeName() === 'Business Rules') {
            return this.businessService.getPreCondition();
        } else {
            return this.catalogService.getPreCondition();
        }
    }

    public changePreConditionCheckbox(casesIndex: number, event: Event) {
        let el = event.target as HTMLInputElement;
        let flag = el.checked;
        flag
            ?
            this.addCasePreCondition(casesIndex) :
            this.removeCasePreConditions(casesIndex);
    }

    public toggleCollapse() {
        this.isCollapsed = !this.isCollapsed;
    }

    
  /**
   * Description: Adds new Condition List to Case i
   * @param i index of Case
   * @returns void
   */
  public addConditionCase(i: number) {
    const cases = this.formTest.get('cases') as FormArray;
    console.log('i', i);
    
    console.log('cases', cases.controls[i]);
    
    const case_to_add = cases.controls[i] as FormGroup;
    const case_conditions = case_to_add.controls.condition_list as FormArray;
    let condition_list_condition_type = '';
    let simple_multiple = false;
    let disabledSelect = false;
    let condition = new FormGroup({});
    if (!this.validationService.isType(['Preliminary'])) {
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        precondition: new FormControl(false),
        preconditions: new FormArray([]),
        conditions: new FormArray([], Validators.required)
      });
    } else {
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        conditions: new FormArray([], Validators.required)
      });
    }
    if (this.validationService.isType(['Preliminary'])) {
      condition_list_condition_type = 'should';
      simple_multiple = false;
      disabledSelect = true;
      condition = new FormGroup({
        condition_type: new FormControl(
          { value: condition_list_condition_type, disabled: disabledSelect },
          Validators.required
        ),
        simple_multiple: new FormControl(
          { value: simple_multiple, disabled: disabledSelect },
          Validators.required
        ),
        description: new FormControl(''),
        conditions: new FormArray([], Validators.required),
        datamodel_order_document: new FormControl(''),
        field_order_document: new FormControl(''),
        order_document: new FormControl('')
      });
    }
    case_conditions.push(condition);
  }

}