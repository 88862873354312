import { Component, EventEmitter, Output, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../../services/api.service';
import { AlertService } from 'src/app/services/alert.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-import-datamodels',
  templateUrl: './import-datamodels.component.html',
  styleUrls: ['./import-datamodels.component.scss']
})
export class ImportDatamodelModalComponent {
  files: File[] = [];
  importStatus: string[] = [];
  importingIndex: number | null = null; // Track the index of the file currently being imported
  importStarted = false; // Track if the import has started
  
  @Output() filesImported = new EventEmitter<File[]>();
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef<HTMLInputElement>;

  constructor(
    public activeModal: NgbActiveModal, 
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    public alertService: AlertService,
    public translate: TranslatePipe
  ) {}

  close() {
    this.activeModal.dismiss('cancel');
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        if (file.type === 'application/json') {
          this.files.push(file);
          this.importStatus.push(''); // Initialize status for the new file
        } else {
          this.alertService.error(
            this.translate.transform('importDatamodel.invalidDocumentType'),
            false
          );
          console.error('Invalid file type:', file.name);
        }
      }
    }
    this.cdRef.detectChanges();
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        if (file.type === 'application/json') {
          this.files.push(file);
          this.importStatus.push(''); // Initialize status for the new file
        } else {
          this.alertService.error(
            this.translate.transform('importDatamodel.invalidDocumentType'),
            false
          );
          console.error('Invalid file type:', file.name);
        }
      }
    }
    this.cdRef.detectChanges();
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.importStatus.splice(index, 1); // Remove status for the removed file
    this.fileInput.nativeElement.value = '';
    this.cdRef.detectChanges();
  }

  importFiles() {
    this.importStarted = true; // Set importStarted to true when import begins

    const importPromises = this.files.map((file, index) => {
      return new Promise<void>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          try {
            const fileContent = e.target?.result;
            if (typeof fileContent === 'string') {
              const jsonContent = JSON.parse(fileContent);
              this.importStatus[index] = 'Importing...'; // Set status to 'importing...'
              this.importingIndex = index; // Update importingIndex to current index
              this.cdRef.detectChanges();
              this.apiService.post('datamodels/import', jsonContent, `Datamodel: '${jsonContent.datamodel.datamodelDisplayName}' successfully imported`).subscribe(
                response => {
                  this.importStatus[index] = 'Imported'; // Set status to 'imported' on success
                  this.importingIndex = null; // Reset importingIndex
                  this.cdRef.detectChanges();
                  resolve();
                },
                error => {                    
                  this.importStatus[index] = 'Failed'; // Set status to 'failed' on error
                  this.importingIndex = null; // Reset importingIndex
                  this.cdRef.detectChanges();
                  reject(error);
                }
              );
            }
          } catch (error) {
            if (!Boolean(error.status)) {
              this.alertService.error(
                this.translate.transform('importDatamodel.jsonMalformed'),
                false
              );
            } 
            console.error('Error parsing JSON:', error);
            this.importStatus[index] = 'Failed'; // Set status to 'failed' on error
            this.importingIndex = null; // Reset importingIndex
            this.cdRef.detectChanges();
            reject(error);
          }
        };
        reader.readAsText(file);
      });
    });

    Promise.all(importPromises).then(() => {
      this.filesImported.emit(this.files);
      this.activeModal.close(this.files);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }).catch(error => {
      console.error('Error importing files:', error);
    });
  }

  calculateProgress(): number {
    const totalFiles = this.files.length;
    const completedFiles = this.importStatus.filter(status => status === 'Imported' || status === 'Failed').length;
    return (completedFiles / totalFiles) * 100;
  }
}
