import {
  Component,
  ViewEncapsulation,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '../../security/authentication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.pages.html',
  styleUrls: ['./login.pages.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() showInput: boolean;
  public loginForm: FormGroup;
  public showLogin: boolean = true;
  public loading: boolean = false;
  public submitted: boolean = false;
  public subscription: Subscription;
  public user: any = null;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService
  ) {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.router.events.subscribe(res => {
      if (res instanceof NavigationStart && (res.url === '/server-unavailable' || res.url === '/access-denied')) {
        this.showLogin = false;
      }      
    });
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() {
    return this.loginForm.controls;
  }

  ngOnInit() {
    if (!this.authService.getLoggedInUser()) {
      this.subscription = this.authService.userLoggedIn.subscribe(user => {
        this.user = user;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.valid) {
      const user = {
        name: 'Wavespace Madrid',
        language: {
          languageId: '1033',
          code: 'en'
        },
        expiration: new Date('01/01/2020')
      };
      this.authService.setLoggedInUser(user);
      this.router.navigate(['/']);
    }
  }
}
